import React from "react";
import commaIcon from "./Vector.png";
import userIcon from "./Image.png";
import videoThumbnail from './Image (1).png'
import { IoPlayCircleOutline } from "react-icons/io5";


const Testimony = () => {
  return (
    <div className="bg-[#EAF8FF] py-40 flex flex-col gap-y-10 items-center justify-center">
      <h1 className="text-[4rem] font-bold">What our Clients Says</h1>
      <div className="flex items-center gap-x-10 mt-3">
        <Client />
        <Client />
        <Client />
      </div>
      <VideoSection/>
    </div>
  );
};

export default Testimony;

const Client = () => {
  return (
    <div className="flex flex-col items-center justify-center ">
      <div className="bg-white flex flex-col justify-center items-center p-5 rounded-xl gap-y-8">
        <img src={commaIcon} alt="" />
        <p className="text-[2rem] text-[#015CA1] w-[50rem] text-center">
          Lorem ipsum dolor sit adipisicing elit. Officiis minima adipisci
          veniam reprehenderit autem optio natus, iusto molestiae sint sequi
          tempore cumque neque nisi quam debitis amet sit beatae! Rem!
        </p>
      </div>
      <img src={userIcon} alt="" className="mt-4" />
      <h1 className="text-[#015CA1] mt-4 text-[2rem]">Jenny Wilson</h1>
      <p className="text-[#015CA1]  text-[1.5rem]">Customer</p>
    </div>
  );
};

const VideoSection = () => {
    return (
        <div className="relative mt-20">
            <img src={videoThumbnail} alt="" className="" />
            <div className=" absolute z-10  flex flex-col items-center justify-center top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
                <p className="text-gray-200 uppercase italic text-[1.5rem]">Video</p>
                <h1 className="text-white font-bold text-[4rem] w-[50rem] text-center">We're the Best Organic Farm in the World</h1>
                <IoPlayCircleOutline className="fill-white stroke-white" size={55}/>
            </div>
        </div>
    )
}