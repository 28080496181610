import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import coverImg from "../../utils/cover-img.jpg";
import "./contact.css";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { LuHome } from "react-icons/lu";
import { MdChevronRight } from "react-icons/md";
import { MdMailOutline } from "react-icons/md";
import { FiPhoneCall } from "react-icons/fi";
import { SlLocationPin } from "react-icons/sl";
import mapSvg from './Map Image.svg'
import NewsSubscription from "../common/NewsSubscription";

const Contact = () => {
  const setting = useSelector((state) => state.setting);
  const { t } = useTranslation();
  const placeHolderImage = (e) => {
    e.target.src = setting.setting?.web_logo;
  };

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  return (
    <section className="">
      <div className="relative pb-40">
        <img
          onError={placeHolderImage}
          src={coverImg}
          className="h-40 absolute z-0 object-cover w-full"
          alt="cover"
        ></img>
        <div className="absolute z-[1] flex items-center ml-[12vw] mt-12 gap-x-3">
          <Link to={"/"}>
            <LuHome className="stroke-gray-400 cursor-pointer" size={25} />
          </Link>
          <MdChevronRight className="fill-gray-400" size={20} />
          <span className="text-[#0199E4] text-[2rem]">Contact</span>
        </div>
      </div>
      <div className="flex items-center justify-center w-[100vw] gap-x-14  h-[70vh]">
        <div
          style={{
            boxShadow: " -1px 2px 16px -7px rgba(0,0,0,0.75)",
          }}
          className="rounded-lg px-10 py-14 flex flex-col gap-y-10"
        >
          <div
            style={{ borderBottom: "1px solid #E6E6E6" }}
            className="flex items-center justify-center flex-col gap-y-6"
          >
            <SlLocationPin size={35} className="fill-[#015CA1] " />
            <p className="text-black text-[2.5rem] text-center w-[40rem]">
              2715 Ash Dr. San Jose, South Dakota 83475
            </p>
          </div>
          <div
            style={{ borderBottom: "1px solid #E6E6E6" }}
            className="flex items-center justify-center pb-5 flex-col gap-y-6 "
          >
            <MdMailOutline size={35} className="fill-[#015CA1] " />
            <div>
              <p className="text-black text-[2.5rem] text-center p-0 m-0 -mb-8 w-[40rem]">
                Proxy@gmail.com
              </p>
              s
              <p className="text-black text-[2.5rem] text-center p-0 m-0 !-mt-9 w-[40rem]">
                Help.proxy@gmail.com
              </p>
            </div>
          </div>
          <div className="flex items-center justify-center flex-col gap-y-6">
            <FiPhoneCall size={35} className="stroke-[#015CA1] " />
            <p className="text-black text-[2.5rem] text-center w-[40rem]">
              2715 Ash Dr. San Jose, South Dakota 83475
            </p>
          </div>
        </div>
        <div
          style={{
            boxShadow: " -1px 2px 16px -7px rgba(0,0,0,0.75)",
          }}
          className="px-20 py-14 "
        >
          <h1 className="text-[4rem] font-bold">Just Say Hello!</h1>
          <p className="text-[2rem] w-[60rem] text-gray-600 mt-3">
            Do you fancy saying hi to me or you want to get started with your
            project and you need my help? Feel free to contact me.
          </p>
          <div>
            <div
              className="flex gap-5
                mt-5"
            >
              <input
                type="text"
                placeholder="Template Cookie"
                className="text-[2.5rem] placeholder:-translate-x-60 py-3 px-5  w-[55rem] rounded-md border border-gray-500 "
              />
              <input
                type="text"
                placeholder="zakirsoft@gmail.com"
                className="text-[2.5rem] placeholder:-translate-x-52 py-3 w-[55rem] px-5 rounded-md border border-gray-500 "
              />
            </div>
            <input
                type="text"
                placeholder="placeholder"
                className="text-[2.5rem] placeholder:-translate-x-[47rem] mt-5 py-3 w-full px-5 rounded-md border border-gray-500 "
              />
                 <textarea
                type="text"
                placeholder="Subjects"
                className="text-[2.5rem] mt-5 py-3 w-full px-5 rounded-md border border-gray-500 "
              />
              <button className="bg-[#0199E3] py-4 px-10 text-white text-[2rem] font-bold rounded-full mt-5">Send Message</button>
          </div>
        </div>
      </div>
      <img src={mapSvg} className="w-full object-cover my-40 " />
      <div className="my-20">
      <NewsSubscription/>

      </div>
    </section>
  );
};

export default Contact;
