import React, { useEffect, useState } from "react";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateCalendar } from "@mui/x-date-pickers/DateCalendar";
import classNames from "classnames";
import { setServiceSechedule } from "../../model/reducer/newAppointmentReducer";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";

const DateTime = () => {
  const [date, setDate] = useState(null);
  const [time, setTime] = useState(null);
  const dispatch = useDispatch();
  const { schedule } = useSelector((state) => state.newAppointment);
  const [savedDate, setSaveDate] = useState(dayjs(new Date()));




  useEffect(() => {
    if (schedule) {
      const [datee,timee] = schedule.split(" ");
     if(datee !== "null") setSaveDate(dayjs(new Date(datee)));
     if(datee !== "null") setDate(datee)
     if(timee) setTime(timee.substring(0,5))
    }
  }, [schedule]);

  const dateTransformer = (value) => {
    function formatDateToYYMMDD(dateString) {
      const date = new Date(dateString);
      const year = date.getFullYear().toString(); // Get the full year
      const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Months are 0-indexed
      const day = date.getDate().toString().padStart(2, '0');

      return `${year}/${month}/${day}`;
    }
    return formatDateToYYMMDD(value);
  };

  useEffect(()=>{
   if(!schedule) dispatch(setServiceSechedule(`${dateTransformer(new Date())}`))
  },[])

  useEffect(() => {
    if (date !== null || time !== null) {
      dispatch(setServiceSechedule(`${date} ${time}:00`));
    }
  }, [date, time, dispatch]);

  return (
    <div className="w-full flex h-fit">
      <div style={{ borderRight: "1px solid #dbdbdb" }} className="flex-1 my-10">
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DateCalendar
            value={savedDate}
            onChange={(value) => {
              setDate(dateTransformer(value.format('YYYY-MM-DD')));
              console.log(value, 'value');
            }}
            sx={{
              "& .css-1u23akw-MuiButtonBase-root-MuiPickersDay-root": {
                fontSize: "1.4rem",
                borderRadius: "2px",
              },
              "& .css-jgls56-MuiButtonBase-root-MuiPickersDay-root": {
                fontSize: "1.4rem",
                borderRadius: "2px",
              },
              "& .css-rhmlg1-MuiTypography-root-MuiDayCalendar-weekDayLabel": {
                fontSize: "1.4rem",
              },
              "& .css-1nkg345-MuiButtonBase-root-MuiIconButton-root-MuiPickersArrowSwitcher-button": {
                fontSize: "2rem",
              },
              "& .css-1vooibu-MuiSvgIcon-root": { fontSize: "2rem" },
              "& .css-dplwbx-MuiPickersCalendarHeader-label": {
                fontSize: "1.4rem",
                fontWeight: "bold",
              },
              "& .css-5jq8yz-MuiDateCalendar-root": {
                width: "1000px",
                height: "1000px",
              },
              "& .css-innj4t-MuiPickersYear-yearButton": { fontSize: "1.4rem" },
            }}
          />
        </LocalizationProvider>
      </div>
      <div className="flex-1">
        <TimeSlot setTime={setTime} />
      </div>
    </div>
  );
};
export default DateTime;

const TimeSlot = ({ setTime }) => {
  const [selectedTime, setSelectedTime] = useState(null);

  function timeTo24HourFormat(timeString) {
    const [time, period] = timeString.split(" ");
    const [hour, minute] = time.split(":");
    let formattedHour = parseInt(hour);
    let formattedMinute = parseInt(minute);

    if (period.toLowerCase() === "pm" && formattedHour !== 12) {
      formattedHour += 12;
    } else if (period.toLowerCase() === "am" && formattedHour === 12) {
      formattedHour = 0;
    }

    // Add leading zeros
    const formattedHourStr = formattedHour.toString().padStart(2, "0");
    const formattedMinuteStr = formattedMinute.toString().padStart(2, "0");

    return `${formattedHourStr}:${formattedMinuteStr}`;
  }

  useEffect(() => {
    selectedTime && setTime(timeTo24HourFormat(selectedTime.start));
  }, [selectedTime]);

  return (
    <div className="m-10">
      <h1 className="text-[1.4rem] mt-20 font-bold">Time slot</h1>
      <div>
        <p className="text-[1.3rem] text-gray-500 mt-10">Morning</p>
        <div className="flex flex-wrap  justify-start gap-10 mt-10">
          {morningTimeSlots.map((time) => (
            <TimeBox
              key={time.start}
              time={time}
              selected={selectedTime === time}
              setSelectedTime={setSelectedTime}
            />
          ))}
        </div>
        <p className="text-[1.3rem] text-gray-500 mt-10">Afternoon</p>
        <div className="flex flex-wrap justify-start gap-10 mt-10">
          {afternoonTimeSlots.map((time) => (
            <TimeBox
              key={time.start}
              time={time}
              selected={selectedTime === time}
              setSelectedTime={setSelectedTime}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

const TimeBox = ({ time, selected, setSelectedTime }) => {
  const [savedTime, setSavedTime] = useState(null);
  function convertTo12HourFormat(time24) {
    let [hours, minutes] = time24.split(":").map(Number);
    let suffix = "AM";

    if (hours >= 12) {
      suffix = "PM";
      if (hours > 12) {
        hours -= 12;
      }
    }

    return `${hours}:${minutes.toString().padStart(2, "0")} ${suffix}`;
  }
  const { schedule } = useSelector((state) => state.newAppointment);
  const timeslot = schedule && schedule.split(" ")[1];

  const handleClick = () => {
    setSelectedTime(selected ? null : time);
  };

  useEffect(() => {
    if (timeslot) {
      setSavedTime(convertTo12HourFormat(timeslot));
    }
  }, [schedule]);

  return (
    <div
      onClick={handleClick}
      style={{ border: "1px solid #0199E4" }}
      className={classNames(
        "w-[16rem] py-4 rounded-lg cursor-pointer font-bold px-2 flex items-center justify-center text-[1.4rem]",
        selected ? "text-white bg-[#0199E4]" : "",savedTime === time.start && "text-white bg-[#0199E4]"
      )}
    >
      {time.start} - {time.end}
    </div>
  );
};

const morningTimeSlots = [
  {
    start: "9:00 AM",
    end: "9:30 AM",
  },
  {
    start: "9:30 AM",
    end: "10:00 AM",
  },
  {
    start: "10:00 AM",
    end: "10:30 AM",
  },
  {
    start: "10:30 AM",
    end: "11:00 AM",
  },
  {
    start: "11:00 AM",
    end: "11:30 AM",
  },
  {
    start: "11:30 AM",
    end: "12:00 PM",
  },
];

const afternoonTimeSlots = [
  {
    start: "12:00 PM",
    end: "12:30 PM",
  },
  {
    start: "12:30 PM",
    end: "1:00 PM",
  },
  {
    start: "1:00 PM",
    end: "1:30 PM",
  },
  {
    start: "1:30 PM",
    end: "2:00 PM",
  },
  {
    start: "2:00 PM",
    end: "2:30 PM",
  },
  {
    start: "2:30 PM",
    end: "3:00 PM",
  },
];
