import React, { useEffect, useState } from "react";
import headphoneIcon from "../HomePage/headphones 1.svg";
import bagIcon from "../HomePage/shopping-bag.svg";
import Box from "./Box";
import api from "../../api/api";
import { useDispatch, useSelector } from "react-redux";
import Cookies from "universal-cookie";
import { setServiceId } from "../../model/reducer/newAppointmentReducer";
import { CircularProgress } from "@mui/material";

const Speciality = ({ setCurrentStage, cid }) => {
  const [selected, setSelected] = useState("Talk to An Expert");
  const cookies = new Cookies();
  const token = cookies.get("jwt_token");
  const { consult_type } = useSelector((state) => state.newAppointment);
  const [data, setData] = useState([]);
  const dispatch = useDispatch()
  const getServicesByCategory = async () => {
    let response = await api.getServicebyCategory(cid, consult_type, token);
    let result = await response.json();

    if (result.status === 1) {
      setData(result.data);
    }
  };

  useEffect(() => {
    if (token) {
      getServicesByCategory();
    }
  }, []);

  return (
    <div className="w-full h-[50vh] flex items-center gap-x-5 justify-center">
      { data.length === 0 ? <CircularProgress/> : data.map((item, i) => (
        <div
          onClick={() => {
            setCurrentStage(3);
            dispatch(setServiceId(item.id))
          }}
          key={i}
        >
          <Box
            selected={selected}
            setSelected={setSelected}
            type={item.name}
            desc={item.description}
            label={item.name}
            icon={bagIcon}
          />
        </div>
      ))}
    </div>
  );
};

export default Speciality;

// const data = [
//   {
//     icon: headphoneIcon,
//     label: "Talk to An Expert",
//     desc: "",
//     type:""
//   },
//   {
//     icon: bagIcon,
//     desc: "Get a Free consultation with a professional",
//     label: "Dietician",
//   },
//   {
//     icon: bagIcon,
//     desc: "Get a Free consultation with a professional",
//     label: "Fitness Coach",
//   },
//   {
//     icon: bagIcon,
//     desc: "Get a Free consultation with a professional",
//     label: "Any",
//   },
// ];
