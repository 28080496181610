import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    status: "loading", //fulfill
    service: null,
    provider:null,
    servicecategory:null,
    stage:"Free Vs Expert"
};

export const serviceReducer = createSlice({
    name: "service",
    initialState,
    reducers: {
        setService: (state, action) => {
            state.status = "fulfill";
            state.service = action.payload.data;
        },
        setServiceCategory: (state, action) => {
            state.status = "fulfill";
            state.servicecategory = action.payload.data;
        },
        setServiceProvider: (state, action) => {
            state.status = "fulfill";
            state.provider = action.payload.data;
        }
        ,
        setServiceStage: (state,action) => {
            state.stage = action.payload.data
        }
    }
    //     switch(type) {
    //         case ActionTypes.SET_CATEGORY:
    //     return {
    //         status: "fulfill",
    //         category: payload,
    //     }

    //         default:
    //             return state;
    // }
});

export const { setService,setServiceCategory,setServiceProvider ,setServiceStage} = serviceReducer.actions;
export default serviceReducer.reducer;