import classNames from "classnames";
import { useState } from "react";

const Box = ({ icon, label, desc, selected, setSelected, type }) => {
    const [hover, setHover] = useState(false);
    return (
      <div
        onClick={()=>setSelected(type)}
        onMouseOver={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
        className={classNames("w-[27.563rem] cursor-pointer flex flex-col justify-center hover:bg-[#0199E4] bg-[#FAFAFA] items-center  gap-3 h-[17.125rem] rounded-[15px] border border-[#C8C1C1]",type == selected && '!bg-[#0199E4]')}
      >
        <div className="p-3 border border-gray-600 bg-white rounded-full">
          <img src={icon} alt="" width={28} />
        </div>
        <div className="text-center">
          <h2
            className={classNames(
              "text-[2rem] font-bold hover:text-white",
              hover && "text-white",type == selected && 'text-white'
            )}
          >
            {label}
          </h2>
          <p
            dangerouslySetInnerHTML={{__html:desc}}
            className={classNames(
              "text-gray-600 hover:text-white text-[1.5rem]",
              hover && "text-white",type == selected && 'text-white'
            )}
          >
            {/* {desc} */}
          </p>
        </div>
      </div>
    );
  };

  export default Box