import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import api from "../../api/api";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./product.css";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import Cookies from "universal-cookie";


import { useTranslation } from "react-i18next";
import { Product } from "../HomePage/Product";
import { setProductSizes } from "../../model/reducer/productSizesReducer";
import { setCart } from "../../model/reducer/cartReducer";
import { setFavourite } from "../../model/reducer/favouriteReducer";

const ProductContainer = () => {
  //initialize cookies
  const cookies = new Cookies();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const city = useSelector((state) => state.city);
  const shop = useSelector((state) => state.shop);
  const setting = useSelector((state) => state.setting);
  const cart = useSelector((state) => state.cart);
  const sizes = useSelector((state) => state.productSizes);
  const favorite = useSelector((state) => state.favourite);
  const [selectedVariant, setSelectedVariant] = useState({});
  const [productbrand, setproductbrand] = useState({});

  useEffect(() => {
    if (sizes.sizes === null || sizes.status === "loading") {
      if (city.city !== null) {
        api
          .getProductbyFilter(
            city.city.id,
            city.city.latitude,
            city.city.longitude
          )
          .then((response) => response.json())
          .then((result) => {
            if (result.status === 1) {
              setproductSizes(result.sizes);
              dispatch(setProductSizes({ data: result.sizes }));
              // dispatch({
              //   type: ActionTypes.SET_PRODUCT_SIZES,
              //   payload: result.sizes,
              // });
            }
          });
      }
    } else {
      setproductSizes(sizes.sizes);
    }
  }, [city, sizes]);

  const [selectedProduct, setselectedProduct] = useState({});
  const [productSizes, setproductSizes] = useState(null);
  const [offerConatiner, setOfferContainer] = useState(0);
  const [variant_index, setVariantIndex] = useState(null);
  const [isLoader, setisLoader] = useState(false);

  //for product variants dropdown in product card


  
  const addtoCart = async (product_id, product_variant_id, qty) => {
    setisLoader(true);
    await api
      .addToCart(cookies.get("jwt_token"), product_id, product_variant_id, qty)
      .then((response) => response.json())
      .then(async (result) => {
        if (result.status === 1) {
          toast.success(result.message);
          await api
            .getCart(
              cookies.get("jwt_token"),
              city.city.latitude,
              city.city.longitude
            )
            .then((resp) => resp.json())
            .then((res) => {
              if (res.status === 1) dispatch(setCart({ data: res }));
              // dispatch({ type: ActionTypes.SET_CART, payload: res });
            });
        } else {
          toast.error(result.message);
        }
        setisLoader(false);
      });
  };

  //Add to favorite
  const addToFavorite = async (product_id) => {
    await api
      .addToFavotite(cookies.get("jwt_token"), product_id)
      .then((response) => response.json())
      .then(async (result) => {
        if (result.status === 1) {
          toast.success(result.message);
          await api
            .getFavorite(
              cookies.get("jwt_token"),
              city.city.latitude,
              city.city.longitude
            )
            .then((resp) => resp.json())
            .then((res) => {
              if (res.status === 1) dispatch(setFavourite({ data: res }));
              // dispatch({ type: ActionTypes.SET_FAVORITE, payload: res });
            });
        } else {
          toast.error(result.message);
        }
      });
  };
  const removefromCart = async (product_id, product_variant_id) => {
    setisLoader(true);
    await api
      .removeFromCart(cookies.get("jwt_token"), product_id, product_variant_id)
      .then((response) => response.json())
      .then(async (result) => {
        if (result.status === 1) {
          toast.success(result.message);
          await api
            .getCart(
              cookies.get("jwt_token"),
              city.city.latitude,
              city.city.longitude
            )
            .then((resp) => resp.json())
            .then((res) => {
              if (res.status === 1) dispatch(setCart({ data: res }));
              // dispatch({ type: ActionTypes.SET_CART, payload: res });
              else dispatch(setCart({ data: null }));
              //  dispatch({ type: ActionTypes.SET_CART, payload: null });
            });
        } else {
          toast.error(result.message);
        }
        setisLoader(false);
      });
  };
  // remove from favorite
  const removefromFavorite = async (product_id) => {
    await api
      .removeFromFavorite(cookies.get("jwt_token"), product_id)
      .then((response) => response.json())
      .then(async (result) => {
        if (result.status === 1) {
          toast.success(result.message);
          await api
            .getFavorite(
              cookies.get("jwt_token"),
              city.city.latitude,
              city.city.longitude
            )
            .then((resp) => resp.json())
            .then((res) => {
              if (res.status === 1) dispatch(setFavourite({ data: res }));
              // dispatch({ type: ActionTypes.SET_FAVORITE, payload: res });
              else dispatch(setFavourite({ data: null }));
              // dispatch({ type: ActionTypes.SET_FAVORITE, payload: null });
            });
        } else {
          toast.error(result.message);
        }
      });
  };

  const settings = {
    infinite: false,
    slidesToShow: 5.5,
    slidesPerRow: 1,
    initialSlide: 0,
    // centerMode: true,
    centerMargin: "10px",
    margin: "20px", // set the time interval between slides
    // Add custom navigation buttons using Font Awesome icons
    prevArrow: (
      <button type="button" className="slick-prev">
        <FaChevronLeft size={30} className="prev-arrow" />
      </button>
    ),
    nextArrow: (
      <button type="button" className="slick-next">
        <FaChevronRight color="#f7f7f7" size={30} className="next-arrow" />
      </button>
    ),
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 425,
        settings: {
          slidesToShow: 1.2,
        },
      },
    ],
  };

  const placeHolderImage = (e) => {
    e.target.src = setting.setting?.web_logo;
  };


  return (
    <section
      id="products"
      className=" py-32 relative bg-[#EAF8FF] flex flex-col justify-center items-center"
    >
      <h1 className="text-[4rem] font-bold ">Products</h1>
      <div className="mt-40">
     
        {shop.shop&& shop.shop.sections?.map((section, i) => {
            return (
              <>
            
              <div>
                {
                  section.title
                }
              </div>
                
                <div className="grid grid-cols-4" key={i}>
                  {section.products.slice(0, 8).map((product, j) => {
                    return (
                      <div key={j}>
                        <Product
                          product={product}
                          addtoCart={addtoCart}
                          removefromCart={removefromCart}
                          removefromFavorite={removefromFavorite}
                          addToFavorite={addToFavorite}
                          favorite={favorite}
                          productbrand={productbrand}
                          setting={setting}
                        />
                      </div>
                    );
                  })}
                </div>
              </>
            );
        })}
      </div>
      <Link to={"/products"}>
        <h2 className="text[1rem] cursor-pointer font-bold text-gray-700 absolute right-0 mr-20 mb-5 bottom-0">
          View all
        </h2>
      </Link>
    </section>
  );
};

export default ProductContainer;
