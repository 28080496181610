// import { ActionTypes } from "../action-type";
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    status: "loading", //fulfill
    appointment: null,
};

export const appointmentReducer = createSlice({
    name: "appointment",
    initialState,
    reducers: {
        // list of users appointments
        setAppointment: (state, action) => {
            state.status = "fulfill";
            state.appointment = action.payload.data;
        }
    }
    //     switch(type) {
    //         case ActionTypes.SET_CATEGORY:
    //     return {
    //         status: "fulfill",
    //         category: payload,
    //     }

    //         default:
    //             return state;
    // }
});

export const { setAppointment } = appointmentReducer.actions;
export default appointmentReducer.reducer;
