import React from "react";
import firstImage from "./Image (2).png";
import secondImage from './Image (3).png'
import thirdImage from './Image (4).png'
import { BiSolidRightArrowAlt } from "react-icons/bi";


const LatestNews = () => {
  return (
    <div className="py-40 flex flex-col items-center justify-center">
      <h1 className="text-[4rem] font-bold">Latest News</h1>
      <div className="flex items-center gap-x-10 mt-10">
        <News image={firstImage} />
        <News image={secondImage} />
        <News image={thirdImage} />
      </div>
    </div>
  );
};

export default LatestNews;

const News = ({ image }) => {
  return (
    <div className="hover:shadow-2xl p-5">
      <div className="relative">
        <img width={376} src={image} alt="" />
        <div className="bg-white absolute bottom-5 w-[6rem] h-[6rem] flex flex-col p-3 items-center justify-center rounded-lg left-5">
        <h1 className="text-[2rem] mt-2">23</h1>
        <h1 className="text-[1.5rem] text-gray-500">JAN</h1>

        </div>
      </div>
      <h1 className="text-[#015CA1] w-[45rem] font-semibold mt-4">Curabitur porttitor orci eget neque accumsan venenatis.</h1>
      <h2 className="text-[#015CA1] w-[45rem] mt-2">Nulla libero lorem, euismod venenatis nibh sed, sodales dictum ex. Etiam nisi augue, malesuada et pulvinar at, posuere eu neque.</h2>
      <button className="flex items-center gap-x-5 text-[2rem] font-bold text-[#015CA1] mt-4 ">Read more <BiSolidRightArrowAlt className="fill-[#015CA1]" size={25}/></button>
    </div>
  );
};
