import React from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import "../product/product.css";
import api from "../../api/api";
import Cookies from "universal-cookie";
import { toast } from "react-toastify";
import { setCart } from "../../model/reducer/cartReducer";

function Card(props) {
  const cookies = new Cookies();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const product = props.product;
  const index = props.index;
  const index0 = props.index0;
  const cart = useSelector((state) => state.cart);
  const city = useSelector((state) => state.city);
  const { t } = useTranslation();

  const setting = useSelector((state) => state.setting);
  const placeHolderImage = (e) => {
    e.target.src = setting.setting?.web_logo;
  };

  //Add to Cart
  const addtoCart = async (product_id, product_variant_id, qty) => {
    await api
      .addToCart(cookies.get("jwt_token"), product_id, product_variant_id, qty)
      .then((response) => response.json())
      .then(async (result) => {
        if (result.status === 1) {
          toast.success(result.message);
          await api
            .getCart(
              cookies.get("jwt_token"),
              city.city.latitude,
              city.city.longitude
            )
            .then((resp) => resp.json())
            .then((res) => {
              if (res.status === 1)
                // dispatch({ type: ActionTypes.SET_CART, payload: res });
                dispatch(setCart({ data: res }));
            });
        } else {
          toast.error(result.message);
        }
      });
  };

  //remove from Cart
  const removefromCart = async (product_id, product_variant_id) => {
    await api
      .removeFromCart(cookies.get("jwt_token"), product_id, product_variant_id)
      .then((response) => response.json())
      .then(async (result) => {
        if (result.status === 1) {
          toast.success(result.message);
          await api
            .getCart(
              cookies.get("jwt_token"),
              city.city.latitude,
              city.city.longitude
            )
            .then((resp) => resp.json())
            .then((res) => {
              if (res.status === 1) dispatch(setCart({ data: res }));
              // dispatch({ type: ActionTypes.SET_CART, payload: res });
              else dispatch(setCart({ data: null }));

              //  dispatch({ type: ActionTypes.SET_CART, payload: null });
            });
        } else {
          toast.error(result.message);
        }
      });
  };

  return (
    <div className="d-flex justify-content-center align-item-center">
      <div className="product-card">
        <Link to={`/product/${product.slug}`}>
          <div className="image-container">
            <img
              onError={placeHolderImage}
              src={product.image_url}
              alt={product.slug}
              className={` card-img-top `}
              onClick={() => {}}
            />
            {!Number(product.is_unlimited_stock) &&
              product.variants[0].stock <= 0 && (
                <div className="out_of_stockOverlay">
                  <p className="out_of_stockText">{t("out_of_stock")}</p>
                </div>
              )}
          </div>
        </Link>

        <div className="card-body product-card-body p-3 d-flex justify-content-center align-item-center ">
          <Link to={`/product/${product.slug}`}>
            <h3
              style={{
                color: "var(--secondary-color)",
              }}
              className="productname"
            >
              {product.name}
            </h3>
            <div className="price">
              <span
                id={`price${index}${index0}-section`}
                className="d-flex align-items-center justify-content-center"
              >
                <p
                  id="fa-rupee"
                  className="m-0 "
                  style={{
                    color: "var(--secondary-color)",
                  }}
                >
                  {setting.setting && setting.setting.currency}
                </p>{" "}
                {product.variants[0].discounted_price === 0
                  ? product.variants[0].price.toFixed(
                      setting.setting && setting.setting.decimal_point
                    )
                  : product.variants[0].discounted_price.toFixed(
                      setting.setting && setting.setting.decimal_point
                    )}
              </span>
            </div>
          </Link>
        </div>
        <div className="px-5 addtocart py-2">
          <button
            type="button"
            id={`Add-to-cart-section${index}${index0}`}
            className="w-100 add-to-cart active p-10 mb-3"
            // onClick={() => {
            //   if (cookies.get("jwt_token") !== undefined) {
            //     if (
            //       cart.cart &&
            //       cart.cart.data.cart.some(
            //         (element) => element.product_id === product.id
            //       ) &&
            //       cart.cart.data.cart.some(
            //         (element) =>
            //           element.product_variant_id ===
            //           product.variants[
            //             variant_index.pid === product.id
            //               ? variant_index.index
            //               : 0
            //           ].id
            //       )
            //     ) {
            //       toast.info("Product already in Cart");
            //     } else {
            //       if (product.variants[0].status) {
            //         addtoCart(product.id, product.variants[0].id, 1);
            //       } else {
            //         toast.error(t("out_of_stock_message"));
            //       }
            //     }
            //   } else {
            //     toast.error(t("required_login_message_for_cartRedirect"));
            //   }
            // }}
            disabled={
              !Number(product.is_unlimited_stock) &&
              product.variants[0].stock <= 0
            }
          >
            {t("add_to_cart")}
          </button>
        </div>
      </div>
    </div>
  );
}

export default Card;
