import React, { useEffect, useState } from "react";
import pencilIcon from "./service-assets/mdi_pencil.svg";
import { useDispatch, useSelector } from "react-redux";
import {
  SetAge,
  SetEmail,
  SetGender,
  SetName,
  SetNotes,
  SetPhone,
  setCurrentStageReducer,
} from "../../model/reducer/newAppointmentReducer";
import api from "../../api/api";
import useToken from "../../hooks/useToken";
import { toast } from "react-toastify";
import { setAppointment } from "../../model/reducer/appointmentReducer";
const Details = ({ buttonLabel }) => {
  const [formData, setFormData] = useState({
    firstName: { value: "", error: "" },
    lastName: { value: "", error: "" },
    age: { value: "", error: "" },
    gender: { value: "", error: "" },
    email: { value: "", error: "" },
    phoneNumber: { value: "", error: "" },
    notes: { value: "" }, // No error field for notes
  });

  const token = useToken();

  const handleInputChange = (name, value) => {
    setFormData({
      ...formData,
      [name]: { ...formData[name], value },
    });
  };

  const validateForm = () => {
    const newFormData = { ...formData };
    let isValid = true;

    Object.keys(newFormData).forEach((key) => {
      if (key !== "notes" && !newFormData[key].value) {
        newFormData[key].error = `${key} is required`;
        isValid = false;
      } else if (key !== "notes") {
        newFormData[key].error = "";
      }

      // Additional validations
      if (key === "email" && newFormData[key].value) {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailRegex.test(newFormData[key].value)) {
          newFormData[key].error = "Invalid email format";
          isValid = false;
        }
      }

      if (key === "age" && newFormData[key].value) {
        if (isNaN(newFormData[key].value) || newFormData[key].value <= 0) {
          newFormData[key].error = "Age must be a positive number";
          isValid = false;
        }
      }

      if (key === "phoneNumber" && newFormData[key].value) {
        if (isNaN(newFormData[key].value)) {
          newFormData[key].error = "Phone number must be a number";
          isValid = false;
        }
      }
    });

    setFormData(newFormData);
    return isValid;
  };

  const {
    notes,
    service_id,
    schedule: scheduled_for,
    Name: name,
    gender,
    age,
    email,
    phone,
  } = useSelector((state) => state.newAppointment);
  const { user } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const bookAppointment = async () => {
    const payload = {
      token,
      service_id,
      transaction_id: 12121,
      customer_id: user.id,
      scheduled_for:scheduled_for.replace(/\//g, "-"),
      name,
      gender,
      age,
      problem: "nothing",
      additional_info: JSON.stringify({ ad: "ds" }),
      reason: notes,
    };

    let response = await api.appointmentSummary(payload);
    let { status, data } = await response.json();
    if (status === 1) {
      dispatch(setAppointment({ data }));
      dispatch(setCurrentStageReducer({data:7}));
      toast.success("Appointment Booked");
    }
  };

  const handleSubmit = () => {
    if (validateForm()) {
      // Submit form data
      bookAppointment();
    } else {
      toast.error("Form data is invalid");
    }
  };

  useEffect(() => {
    if (name && age && gender && email && phone && notes) {
      setFormData({
        age: { value: age },
        email: { value: email },
        firstName: { value: name.split(" ")[0] },
        gender: { value: gender },
        lastName: { value: name.split(" ")[1] },
        notes: { value: notes },
        phoneNumber: { value: phone },
      });
    }
  }, []);

  return (
    <div className="p-20">
      <BasicDetailsForm
        setFormData={setFormData}
        formData={formData}
        handleInputChange={handleInputChange}
      />
      <button
        className="text-[3rem] font-bold bg-[#0199E3] text-white w-[55rem] py-6 rounded-xl absolute bottom-10 left-0 right-0 mx-auto z-[4000]"
        onClick={handleSubmit}
      >
        {buttonLabel}
      </button>
    </div>
  );
};

const BasicDetailsForm = ({ formData, handleInputChange, setFormData }) => {
  const [textareaHeight, setTextareaHeight] = useState("auto");
  const dispatch = useDispatch();
  const { Name, age, gender, email, phone, notes } = useSelector(
    (state) => state.newAppointment
  );

  const autoResize = (event) => {
    const textAreaLineHeight = 24; // Adjust this value according to your textarea's line-height
    const minTextareaHeight = textAreaLineHeight * 3; // Minimum height to display 3 lines of text

    const previousScrollHeight = event.target.scrollHeight;
    event.target.style.height = "auto";
    const currentScrollHeight = event.target.scrollHeight;

    setTextareaHeight(`${Math.max(currentScrollHeight, minTextareaHeight)}px`);
    event.target.scrollTop += currentScrollHeight - previousScrollHeight;

    handleInputChange("notes", event.target.value);
  };

  return (
    <form>
      <div className="grid grid-cols-2 gap-10">
        <Input
          label="First Name"
          name="firstName"
          value={Name ? Name.split(" ")[0] : formData.firstName.value}
          error={formData.firstName.error}
          onChange={(name, value) => {
            handleInputChange(name, value);
            dispatch(SetName({ firstname: value }));
          }}
        />
        <Input
          label="Last Name"
          name="lastName"
          value={Name ? Name.split(" ")[1] : formData.lastName.value}
          error={formData.lastName.error}
          onChange={(name, value) => {
            handleInputChange(name, value);
            dispatch(SetName({ lastname: value }));
          }}
        />
        <Input
          label="Age"
          name="age"
          value={age ? age : formData.age.value}
          error={formData.age.error}
          onChange={(name, value) => {
            handleInputChange(name, value);
            dispatch(SetAge(value));
          }}
        />
        <Input
          label="Gender"
          name="gender"
          value={gender ? gender : formData.gender.value}
          error={formData.gender.error}
          onChange={(name, value) => {
            handleInputChange(name, value);
            dispatch(SetGender(value));
          }}
        />
        <Input
          label="Email"
          name="email"
          value={email ? email : formData.email.value}
          error={formData.email.error}
          onChange={(name, value) => {
            handleInputChange(name, value);
            dispatch(SetEmail(value));
          }}
        />
        <Input
          label="Phone Number"
          name="phoneNumber"
          value={phone ? phone : formData.phoneNumber.value}
          error={formData.phoneNumber.error}
          onChange={(name, value) => {
            handleInputChange(name, value);
            dispatch(SetPhone(value));
          }}
        />
      </div>
      <div className="w-full border p-4 border-gray-500 mt-20">
        <div className="flex items-center gap-x-4">
          <img src={pencilIcon} alt="" />
          <h3 className="text-gray-500 mt-2">Notes</h3>
        </div>
        <textarea
          onChange={(e) => {
            setFormData({ ...formData, notes: { value: e.target.value } });
            dispatch(SetNotes(e.target.value));
          }}
          className="h-full w-full text-[2rem]"
          style={{ height: textareaHeight }}
          onInput={autoResize}
          value={notes ? notes : formData.notes.value}
        ></textarea>
      </div>
    </form>
  );
};

const Input = ({ label, name, value, error, onChange }) => {
  return (
    <div>
      <h1 className="text-[2rem] text-gray-600">{label}</h1>
      <input
        type="text"
        className={`border px-10 w-full text-[2rem] h-20 rounded-xl ${
          error ? "!border-red-500" : "border-gray-500"
        }`}
        value={value}
        onChange={(e) => onChange(name, e.target.value)}
      />
      {error && <p className="text-red-500">{error}</p>}
    </div>
  );
};

export default Details;
