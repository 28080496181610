import React, { useEffect, useState } from "react";
import "./cart.css";
import { useSelector, useDispatch } from "react-redux";
import { BsPlus } from "react-icons/bs";
import { BiMinus } from "react-icons/bi";
import api from "../../api/api";
import { toast } from "react-toastify";
import Cookies from "universal-cookie";
import { ActionTypes } from "../../model/action-type";
import EmptyCart from "../../utils/zero-state-screens/Empty_Cart.svg";
import { useNavigate, Link } from "react-router-dom";
import coverImg from "../../utils/cover-img.jpg";
import { RiCoupon2Fill, RiDeleteBinLine } from "react-icons/ri";
import Loader from "../loader/Loader";
import Promo from "./Promo";
import { useTranslation } from "react-i18next";
import { GoHome } from "react-icons/go";
import { MdKeyboardArrowRight } from "react-icons/md";
import NewsSubscription from "../common/NewsSubscription";
import { setProductSizes } from "../../model/reducer/productSizesReducer";
import { setCart, setCartCheckout } from "../../model/reducer/cartReducer";

const ViewCart = () => {
  const dispatch = useDispatch();
  const cookies = new Cookies();
  const navigate = useNavigate();

  const cart = useSelector((state) => state.cart);
  const city = useSelector((state) => state.city);
  const sizes = useSelector((state) => state.productSizes);
  const setting = useSelector((state) => state.setting);

  const [productSizes, setproductSizes] = useState(null);
  const [iscartEmpty, setiscartEmpty] = useState(false);
  const [isLoader, setisLoader] = useState(false);
  const [showPromoOffcanvas, setShowPromoOffcanvas] = useState(false);

  useEffect(() => {
    if (sizes.sizes === null || sizes.status === "loading") {
      if (city.city !== null && cart.cart !== null) {
        api
          .getProductbyFilter(
            city.city.id,
            city.city.latitude,
            city.city.longitude
          )
          .then((response) => response.json())
          .then((result) => {
            if (result.status === 1) {
              setproductSizes(result.sizes);
              dispatch(setProductSizes({data:result.sizes}))
              // dispatch({
              //   type: ActionTypes.SET_PRODUCT_SIZES,
              //   payload: result.sizes,
              // });
            }
          });
      }
    } else {
      setproductSizes(sizes.sizes);
    }

    if (cart.cart === null && cart.status === "fulfill") {
      setiscartEmpty(true);
    } else {
      setiscartEmpty(false);
    }
  }, [cart]);

  useEffect(() => {
    api
      .getCart(
        cookies.get("jwt_token"),
        city.city.latitude,
        city.city.longitude,
        1
      )
      .then((response) => response.json())
      .then((result) => {
        if (result.status === 1) {
          dispatch(setCartCheckout({data:result.data}))
          // dispatch({
          //   type: ActionTypes.SET_CART_CHECKOUT,
          //   payload: result.data,
          // });
        }
      })
      .catch((error) => console.log(error));
  }, [cart.cart]);

  //Add to Cart
  const addtoCart = async (product_id, product_variant_id, qty) => {
    console.log(qty, "hello");
    setisLoader(true);

    await api
      .addToCart(cookies.get("jwt_token"), product_id, product_variant_id, qty)
      .then((response) => response.json())
      .then(async (result) => {
        if (result.status === 1) {
          toast.success(result.message);
          await api
            .getCart(
              cookies.get("jwt_token"),
              city.city.latitude,
              city.city.longitude
            )
            .then((resp) => resp.json())
            .then((res) => {
              setisLoader(false);

              if (res.status === 1) {
                dispatch(setCart({data:res}))
                // dispatch({ type: ActionTypes.SET_CART, payload: res });
              }
            });
        } else {
          setisLoader(false);
          toast.error(result.message);
        }
      });
  };

  //remove from Cart
  const removefromCart = async (product_id, product_variant_id) => {
    setisLoader(true);
    await api
      .removeFromCart(cookies.get("jwt_token"), product_id, product_variant_id)
      .then((response) => response.json())
      .then(async (result) => {
        if (result.status === 1) {
          toast.success(result.message);
          await api
            .getCart(
              cookies.get("jwt_token"),
              city.city.latitude,
              city.city.longitude
            )
            .then((resp) => resp.json())
            .then((res) => {
              setisLoader(false);
              if (res.status === 1) {
                dispatch(setCart({data:res}))
                // dispatch({ type: ActionTypes.SET_CART, payload: res });
              } else {
                dispatch(setCart({data:null}))
                // dispatch({ type: ActionTypes.SET_CART, payload: null });
              }
            })
            .catch((error) => console.log(error));
        } else {
          setisLoader(false);
          toast.error(result.message);
        }
      })
      .catch((error) => console.log(error));
  };
  const { t } = useTranslation();
  const placeHolderImage = (e) => {
    e.target.src = setting.setting?.web_logo;
  };

  const stockValidation = () => {
    cart.cart?.data?.cart.forEach((element) => {
      if (!element.status) {
        return () => {
          toast.error(t("some_items_are_out_of_stock"));
        };
      }
      navigate("/checkout");
    });
  };
  return (
    <section id="viewcart" className="viewcart bg-white">
      <div className="cover ">
        <img
          src={coverImg}
          onError={placeHolderImage}
          className="img-fluid"
          alt="cover"
        ></img>
        <div className="shopping-cart flex items-center">
          <span>
            <Link to="/" className="text-light text-decoration-none">
              <GoHome className="home-icon" size={25} />
            </Link>
          </span>
          <span>
            <MdKeyboardArrowRight className="home-icon" size={20} />
          </span>
          <span className="shopping-cart-active">{t("Shopping cart")}</span>
        </div>
      </div>
      <div className="-mt-32 w-full flex justify-center">
        <h1 className="text-[4rem] font-bold">My Shopping Cart</h1>
      </div>

      <div className="view-cart-container">
        <div className="container">
          {iscartEmpty ? (
            <div className="empty-cart co-12">
              <img
                src={EmptyCart}
                onError={placeHolderImage}
                alt="empty-cart"
              ></img>
              <p>{t("empty_cart_list_message")}</p>
              <span>{t("empty_cart_list_description")}</span>
              <button
                type="button"
                onClick={() => {
                  navigate("/products");
                }}
              >
                {t("empty_cart_list_button_name")}
              </button>
            </div>
          ) : (
            <>
              {cart.cart === null || productSizes === null ? (
                <Loader screen="full" />
              ) : (
                <>
                  {isLoader ? <Loader screen="full" background="none" /> : null}
                  <div className="row">
                    <div className="viewcart-product-wrapper col-8 !overflow-hidden">
                      <div className="product-heading"></div>

                      <table className="w-full">
                        <thead>
                          <tr className="border-b border-gray-200">
                            <th className="text-gray-400 text-[2rem] pb-3 pl-5">
                              PRODUCT
                            </th>
                            <th className="text-gray-400 text-[2rem] pb-3 pl-10">
                              PRICE
                            </th>
                            <th className="text-gray-400 text-[2rem] pb-3 pl-10">
                              QUANTITY
                            </th>
                            <th className="text-gray-400 text-[2rem] pb-3 pl-10">
                              SUBTOTAL
                            </th>
                            <th className="text-gray-400 text-[2rem] pb-3"></th>
                          </tr>
                        </thead>

                        <tbody>
                          {cart?.cart?.data?.cart?.map((product, index) => (
                            <tr
                              key={index}
                              className={
                                `${!product.status ? "danger" : ""}` +
                                " border-b border-gray-200 table-row"
                              }
                            >
                              <td className=" flex items-center  space-x-10 py-10">
                                <div className="!h-40 !w-40">
                                  <img
                                    className="h-40 w-40 p-2 "
                                    onError={placeHolderImage}
                                    src={
                                      product.variantImages[0] ||
                                      product.image_url
                                    }
                                    alt="product"
                                  ></img>
                                </div>

                                <div className="max-w-[25vw]">
                                  <p className="text-gray-600 text-[2rem] font-bold">
                                    {product.measurement} {product.unit} |{" "}
                                    {product.variant_name || product.name}
                                  </p>
                                </div>
                              </td>

                              <td className=" font-bold max-w-min pl-10">
                                <span className="text-[2rem] text-gray-600">
                                  {setting.setting && setting.setting.currency}.
                                </span>
                                <span className="text-[2rem] text-gray-600">
                                  {product.discounted_price === 0
                                    ? product.price.toFixed(
                                        setting.setting &&
                                          setting.setting.decimal_point
                                      )
                                    : product.discounted_price.toFixed(
                                        setting.setting &&
                                          setting.setting.decimal_point
                                      )}
                                </span>
                              </td>

                              <td className="pl-10">
                                <div className="border border-gray-500 p-2 flex w-[16rem] items-center justify-between rounded-full gap-x-3">
                                  <button
                                    className="rounded-full bg-gray-200 p-4"
                                    type="button"
                                    onClick={() => {
                                      if (Number(product.qty) > 1) {
                                        addtoCart(
                                          product.product_id,
                                          product.product_variant_id,
                                          Number(product.qty) - 1
                                        );
                                      }
                                    }}
                                  >
                                    <BiMinus
                                      className="fill-gray-700"
                                      fontSize={"2rem"}
                                    />
                                  </button>
                                  <span className="text-gray-600 text-[1.8rem]">
                                    {product.qty}
                                  </span>
                                  <button
                                    className="rounded-full bg-gray-200 p-4"
                                    type="button"
                                    onClick={() => {
                                      if (
                                        Number(product.is_unlimited_stock) === 1
                                      ) {
                                        if (
                                          Number(product.qty) <
                                          Number(
                                            setting.setting.max_cart_items_count
                                          )
                                        ) {
                                          addtoCart(
                                            product.product_id,
                                            product.product_variant_id,
                                            Number(product.qty) + 1
                                          );
                                        } else {
                                          toast.error(
                                            "Apologies, maximum product quantity limit reached!"
                                          );
                                        }
                                      } else {
                                        if (
                                          Number(product.qty) >=
                                          Number(product.stock)
                                        ) {
                                          toast.error(
                                            t("out_of_stock_message")
                                          );
                                        } else if (
                                          Number(product.qty) >=
                                          Number(
                                            setting.setting.max_cart_items_count
                                          )
                                        ) {
                                          toast.error(
                                            "Apologies, maximum product quantity limit reached!"
                                          );
                                        } else {
                                          addtoCart(
                                            product.product_id,
                                            product.product_variant_id,
                                            Number(product.qty) + 1
                                          );
                                        }
                                      }
                                    }}
                                  >
                                    <BsPlus
                                      className="fill-gray-700"
                                      fontSize={"2rem"}
                                    />
                                  </button>
                                </div>
                              </td>

                              <td className="pl-10 text-[2rem] text-gray-600 font-bold">
                                {setting.setting && setting.setting.currency}.
                                {(
                                  (product.discounted_price === 0
                                    ? product.price.toFixed(
                                        setting.setting?.decimal_point
                                      )
                                    : product.discounted_price.toFixed(
                                        setting.setting &&
                                          setting.setting.decimal_point
                                      )) * product.qty
                                ).toFixed(
                                  setting.setting &&
                                    setting.setting.decimal_point
                                )}
                              </td>

                              <td className="remove last-column pl-10">
                                <button
                                  className=" text-gray-600 border text-[2rem] border-gray-500 rounded-full w-16 h-16 text-center"
                                  whileTap={{ scale: 0.8 }}
                                  type="button"
                                  onClick={() =>
                                    removefromCart(
                                      product.product_id,
                                      product.product_variant_id
                                    )
                                  }
                                >
                                  x
                                </button>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                      <div className="w-full flex justify-between items-center mt-5 px-3">
                      <Link to="/" className="text-light text-decoration-none">
                      <button  className="bg-gray-200 text-[1.8rem] text-gray-700 font-bold rounded-full py-3 px-10">
                          Return to shop
                        </button>
                      </Link>
                        <button className="bg-gray-200 text-[1.8rem] rounded-full py-3 px-10 text-gray-700 font-bold">
                          Update Cart
                        </button>
                      </div>
                      <div className="flex items-center w-full py-12 rounded-lg border px-5 border-gray-500 mt-20 gap-x-14">
                        <h2 className="text-[2.8rem] font-bold text-gray-600 min-w-max ">
                          Coupon Code
                        </h2>
                        <div className="w-full flex items-center justify-between border border-gray-500 rounded-full h-[7rem]">
                          <input
                            type="text"
                            placeholder="Enter code"
                            className="text-[2rem] text-left placeholder:-translate-x-28 ml-20"
                          />
                          <button className="text-[2.5rem] text-white bg-gray-800 h-full px-14 rounded-full">
                            Apply Coupon
                          </button>
                        </div>
                      </div>
                    </div>

                    <div className="billing col-3">
                      <div className="promo-section">
                        <div className="promo-wrapper">
                          <div className="promo-container">
                            <div className="promo-button "></div>
                            {cart.cart && cart.promo_code ? (
                              <>
                                <div className="promo-code">
                                  <div className="">
                                    <span>
                                      <RiCoupon2Fill
                                        size={26}
                                        fill="var(--secondary-color)"
                                      />
                                    </span>
                                  </div>
                                  <div className="d-flex flex-column">
                                    <span className="promo-name">
                                      {cart.promo_code.promo_code}
                                    </span>
                                    <span className="promo-discount-amount">
                                      {cart.promo_code.message}
                                    </span>
                                  </div>
                                  <div className="d-flex flex-column">
                                    <span>
                                      {setting.setting &&
                                        setting.setting.currency}{" "}
                                      {cart.promo_code.discount}
                                    </span>
                                    <span
                                      className="promo-remove"
                                      onClick={() => {
                                        dispatch({
                                          type: ActionTypes.SET_CART_PROMO,
                                          payload: null,
                                        });
                                        toast.info("Coupon Removed");
                                      }}
                                    >
                                      {" "}
                                      {t("remove")}
                                    </span>
                                  </div>
                                </div>
                              </>
                            ) : (
                              <></>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="cart-summary-wrapper">
                        <div className="pl-12 text-[2.5rem] font-bold">
                          <span>
                            {t("cart")} {t("total")}
                          </span>
                        </div>
                        {cart.checkout === null ? (
                          <div className="d-flex justify-content-center">
                            <div className="spinner-border" role="status">
                              <span className="visually-hidden">
                                Loading...
                              </span>
                            </div>
                          </div>
                        ) : (
                          <div className="summary">
                            <div className="flex items-center justify-between border-b border-gray-400 ">
                              <span className="text-[2rem] text-gray-500 font-normal">
                                {t("sub_total")}:
                              </span>
                              <div className="d-flex align-items-center text-[2rem] font-bold text-black">
                                {setting.setting && setting.setting.currency}.
                                <span>
                                  {cart.checkout.sub_total.toFixed(
                                    setting.setting &&
                                      setting.setting.decimal_point
                                  )}
                                </span>
                              </div>
                            </div>

                            <div className="flex items-center justify-between">
                              <span className="text-[2rem] text-gray-500 font-normal">
                                Shipping:
                              </span>
                              <div className="d-flex text-[2rem] font-bold text-black align-items-center">
                                {setting.setting && setting.setting.currency}.
                                <span className="text-[2rem] font-bold text-black">
                                  {cart.checkout.delivery_charge.total_delivery_charge.toFixed(
                                    setting.setting &&
                                      setting.setting.decimal_point
                                  )}
                                </span>
                              </div>
                            </div>
                            {cart.promo_code && (
                              <>
                                <div className="d-flex justify-content-between">
                                  <span className="text-[2rem] text-gray-500 font-normal">
                                    {t("discount")}
                                  </span>
                                  <div className="d-flex align-items-center">
                                    <span>
                                      -{" "}
                                      {setting.setting &&
                                        setting.setting.currency}
                                      {cart.promo_code.discount.toFixed(
                                        setting.setting &&
                                          setting.setting.decimal_point
                                      )}
                                    </span>
                                  </div>
                                </div>
                              </>
                            )}

                            <div className="d-flex justify-content-between total">
                              <span className="text-[2rem] text-gray-500 font-normal">
                                {t("total")}:
                              </span>
                              <div className="d-flex align-items-center total-amount text-[2rem] font-bold text-black">
                                {setting.setting && setting.setting.currency}.
                                {cart.promo_code ? (
                                  <span className="text-[2rem] font-bold text-black">
                                    {cart.promo_code.discounted_amount +
                                      cart.checkout.delivery_charge
                                        .total_delivery_charge}
                                  </span>
                                ) : (
                                  <>
                                    <span className="text-[2rem] font-bold text-black">
                                      {cart.checkout.total_amount.toFixed(
                                        setting.setting &&
                                          setting.setting.decimal_point
                                      )}
                                    </span>
                                  </>
                                )}
                              </div>
                            </div>

                            <button
                              onClick={stockValidation}
                              className=" py-7 rounded-full text-white text-center bg-[#0199e3] mt-5 w-full"
                            >
                              <span
                                style={{ cursor: "pointer" }}
                                className="checkout text-[2rem] text-white "
                              >
                                {t("proceed_to_checkout")}
                              </span>
                            </button>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </>
              )}
            </>
          )}
        </div>
      </div>
      <NewsSubscription />
      <Promo show={showPromoOffcanvas} setShow={setShowPromoOffcanvas} />
    </section>
  );
};

export default ViewCart;
