import React, { useEffect, useRef, useState } from 'react';
import coverImg from '../../utils/cover-img.jpg';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import './order.css';
import api from '../../api/api';
import Cookies from 'universal-cookie';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import axios from 'axios';
import { Modal } from 'react-bootstrap';
import { AiOutlineCloseCircle } from 'react-icons/ai';




const OrderDetails = React.memo(() => {
    const { t } = useTranslation();

    const setting = useSelector(state => state.setting);
    const user = useSelector(state => state?.user?.user);
    // console.log(user);

    const [orderData, setOrderData] = useState(null);
    const [element, setElement] = useState();
    const [showTracker, setShowTracker] = useState(false)
    const [showReturnModal, setShowReturnModal] = useState(false)
    const [orderStatus, setOrderStatus] = useState(t("recieved"));
    const [currentStep, setCurrentStep] = useState(1);


    const urlParams = useParams()
    const stepsConfig = [
        "order received", "shipped", "out for delivery", "delivered"
    ]
    useEffect(() => {

        if (orderData?.active_status === "6") {
            setOrderStatus(t("delivered"));
        } else if (orderData?.active_status === "5") {
            setOrderStatus(t("out_for_delivery"));
        } else if (orderData?.active_status === "4") {
            setOrderStatus(t("shipped"));
        } else if (orderData?.active_status === "3") {
            setOrderStatus(t("processed"));
        }
        else if (orderData?.active_status === "7") {
            setOrderStatus(t("cancelled"));
        }
        else if (orderData?.active_status === "8") {
            setOrderStatus(t("returned"));
        }
    }, [orderData]);

    const cookies = new Cookies();

    const placeHolderImage = (e) => {

        e.target.src = setting.setting?.web_logo;
    };

    const fetchOrderDetails = async () => {
        api.getOrders(cookies.get('jwt_token'), null, null, null, urlParams?.id).then(result => result.json()).then((response) => {

            if (response.status) {
                setOrderData(response.data[0]);
            } else {
                toast.error(response.message);
            }
        });

    };


    useEffect(() => {
        fetchOrderDetails()

    }, [])

    const returnRef = useRef(null);

    const getInvoice = async (Oid) => {
        let postData = new FormData();
        postData.append('order_id', Oid);
        axios({
            url: `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_SUBURL}/invoice_download`,
            method: 'post',
            responseType: 'blob',
            /*responseType: 'application/pdf',*/
            data: postData,
            headers: {
                Authorization: `Bearer ${cookies.get('jwt_token')}`
            }
        }).then(response => {


            var fileURL = window.URL.createObjectURL(new Blob([response.data]));
            var fileLink = document.createElement('a');
            fileLink.href = fileURL;
            fileLink.setAttribute('download', 'Invoice-No:' + Oid + '.pdf');
            document.body.appendChild(fileLink);
            fileLink.click();


        }).catch(error => {
            if (error.request.statusText) {
                toast.error(error.request.statusText);
            } else if (error.message) {
                toast.error(error.message);
            } else {
                toast.error("Something went wrong!");
            }
        });
    };
    const navigate = useNavigate();

    const handleUpdateStatus = async (item_id, status, return_reason) => {
        await api.updateOrderStatus(cookies.get('jwt_token'), orderData?.id, item_id, status, return_reason)
            .then((result) => result.json())
            .then((response) => {
                if (response.status) {
                    fetchOrderDetails();
                    // response.data && setOrderData(response.data);
                    // console.log(response.data, "update_order_status");
                    toast.success(response.message);
                    setShowReturnModal(false);
                } else if (response.message == "This Order Item is already Returned!") {
                    fetchOrderDetails();
                    toast.error(response.message);
                } else {
                    toast.info(response.message);
                }
                setShowReturnModal(false);
            }).catch((error) => {
                console.error(error)
            })
    }
    const [isComplete, setIsComplete] = useState(false);
    const [margins, setMargins] = useState({
        marginLeft: 0,
        marginRight: 0,
    });
    const stepRef = useRef([]);


    useEffect(() => {
        setMargins({
            marginLeft: stepRef.current[0].offsetWidth / 2,
            marginRight: stepRef.current[stepsConfig.length - 1].offsetWidth / 2,
        });
    }, [stepRef, stepsConfig.length]);


    const handleNext = () => {
        setCurrentStep((prevStep) => {
            if (prevStep === stepsConfig.length) {
                setIsComplete(true);
                return prevStep;
            } else {
                return prevStep + 1;
            }
        });
    };

    const calCurrentStep = (active_status) => {
        if (["6", "7", "8"].includes(active_status)) {
            return 4;
        } else if (active_status === "5") {
            return 3;
        } else if (["3", "4"].includes(active_status)) {
            return 2;
        } else if (["1", "2"].includes(active_status)) {
            return 1;
        }
        return 1; // Default step if no valid status is given
    };
    
    const calculateProgressBarWidth = () => {
        const step = calCurrentStep(orderData?.active_status);
        return ((step - 1) / (stepsConfig.length - 1)) * 100;
    };

    // const profileNav = () => {
    //     const placeHolderImage = (e) => {
    //         e.target.src = setting.setting?.web_logo;
    //     };
    //     return (
    //         <>
    //             {isupdating ? (
    //                 <div className="d-flex justify-content-center">
    //                     <div className="spinner-border" role="status">
    //                         <span className="visually-hidden">Loading...</span>
    //                     </div>
    //                 </div>
    //             ) : (
    //                 <div className="basicInfo-container">
    //                     <div className="image-container">
    //                         <img
    //                             onError={placeHolderImage}
    //                             src={user.user && user.user.profile}
    //                             alt="logo"
    //                         ></img>
    //                         <div className="button-container-badge">
    //                             <label htmlFor="file">
    //                                 <span className="badge-img">
    //                                     <FaEdit size={25} fill="var(--secondary-color)" />
    //                                 </span>
    //                             </label>
    //                         </div>
    //                     </div>
    //                     <p>{user.user.name.split(" ")[0]}</p>
    //                     <span>{user.user.email}</span>
    //                 </div>
    //             )}

    //             <div className="navigation-container">
    //                 <button
    //                     type="button"
    //                     className="navigation-container-button "
    //                     onClick={() => {
    //                         setprofileClick(true);
    //                         navigate("/profile");
    //                         setaddressClick(false);
    //                         setorderClick(false);
    //                         settransactionClick(false);
    //                         closeCanvas.current.click();
    //                     }}
    //                 >
    //                     <span>
    //                         <FaUserCircle
    //                             size={35}
    //                             className="mx-3"
    //                             fill={"var(--secondary-color)"}
    //                         />
    //                         {t("profile")}
    //                     </span>
    //                     <IoIosArrowForward className="profile-navigate-arrow" />
    //                 </button>
    //                 <button
    //                     type="button"
    //                     className="navigation-container-button "
    //                     onClick={() => {
    //                         setprofileClick(false);
    //                         setaddressClick(false);
    //                         setorderClick(true);
    //                         settransactionClick(false);
    //                         setisupdating(false);
    //                         navigate("/profile/orders");
    //                         closeCanvas.current.click();
    //                     }}
    //                 >
    //                     <span>
    //                         <FaListAlt
    //                             size={35}
    //                             className="mx-3"
    //                             fill={"var(--secondary-color)"}
    //                         />
    //                         {t("all_orders")}
    //                     </span>
    //                     <IoIosArrowForward className="profile-navigate-arrow" />
    //                 </button>
    //                 <button
    //                     type="button"
    //                     className="navigation-container-button "
    //                     onClick={() => {
    //                         setprofileClick(false);
    //                         navigate("/profile/address");
    //                         setaddressClick(true);
    //                         setorderClick(false);
    //                         settransactionClick(false);
    //                         setisupdating(false);
    //                         closeCanvas.current.click();
    //                     }}
    //                 >
    //                     <span>
    //                         <FaHome
    //                             size={35}
    //                             className="mx-3"
    //                             fill={"var(--secondary-color)"}
    //                         />
    //                         {t("manage_address")}
    //                     </span>
    //                     <IoIosArrowForward className="profile-navigate-arrow" />
    //                 </button>
    //                 <button
    //                     type="button"
    //                     className="navigation-container-button "
    //                     onClick={() => {
    //                         navigate("/profile/transactions");
    //                         setprofileClick(false);
    //                         setaddressClick(false);
    //                         setorderClick(false);
    //                         settransactionClick(true);
    //                         setisupdating(false);
    //                         closeCanvas.current.click();
    //                         // navigate('/profile/address')

    //                         if (window.innerWidth < 768)
    //                             document
    //                                 .getElementsByClassName("sidebar")[0]
    //                                 .classList.toggle("active");
    //                     }}
    //                 >
    //                     <span>
    //                         <GoChecklist
    //                             size={35}
    //                             className="mx-3"
    //                             fill={"var(--secondary-color)"}
    //                         />
    //                         {t("transaction_history")}
    //                     </span>
    //                     <IoIosArrowForward className="profile-navigate-arrow" />
    //                 </button>
    //                 <button
    //                     type="button"
    //                     className="navigation-container-button no-hover"
    //                     onClick={handleLogout}
    //                 >
    //                     <span>
    //                         <IoMdLogOut
    //                             size={35}
    //                             className="mx-3"
    //                             fill={"var(--secondary-color)"}
    //                         />
    //                         {t("logout")}
    //                     </span>
    //                     <IoIosArrowForward className="profile-navigate-arrow" />
    //                 </button>
    //                 <button
    //                     type="button"
    //                     className="navigation-container-button "
    //                     onClick={handleDeleteAcount}
    //                 >
    //                     <span>
    //                         <AiFillDelete
    //                             size={35}
    //                             className="mx-3"
    //                             fill={"var(--secondary-color)"}
    //                         />
    //                         {t("delete_account")}
    //                     </span>
    //                     <IoIosArrowForward className="profile-navigate-arrow" />
    //                 </button>
    //             </div>
    //         </>
    //     );
    // };

    return (
        <>
            <section className="order-details-page">
                <div className='cover'>
                    <img src={coverImg} onError={placeHolderImage} className='img-fluid' alt="cover"></img>
                    <div className='page-heading'>
                        <h3>{t("order_details")}</h3>
                        <p><strong onClick={() => navigate('/')}>{t("home")}</strong> / <span> <span onClick={() => navigate('/profile/orders')}>{t("order")}</span> / {orderData?.id}</span></p>
                    </div>
                </div>

                <div className="container">
                    <div className="row flex justify-center items-center">
                        <div className="col-12 col-xl-9 ">
                            <div className="order-container">
                                <div className="list-container">
                                    <div className='main-heading'>
                                        <div className='flex justify-center items-center'><span>{t("order_details")}</span><div className='text-gray-400 text-[12px]'>{` • ${orderData?.status?.length > 0 && new Date(orderData?.status.reverse()[0].reverse()[0]).toLocaleDateString()} • ${orderData?.items?.length}Products`}</div></div>
                                        <span onClick={() => navigate('/profile/orders')} className='cursor-pointer font-semibold text-sky-400 text-[13px]'> Back to List</span>
                                    </div>
                                    <div className='grid grid-cols-3 p-5'>
                                        {/* billing department */}
                                        <div className='billing p-3 space-y-10'>
                                            <h3>BILLING ADDRESS</h3>
                                            <div className='space-y-2'>
                                                <div className='text-[14px] font-bold'>{orderData?.user_name}</div>
                                                <div className='text-[10px]'><span>{
                                                    orderData?.order_address
                                                }</span></div>
                                            </div>
                                            <div></div>
                                            <div className='space-y-2'>
                                                <div className='text-[10px]'><span>EMAIL</span></div>
                                                <div className='text-[10px]'><span>PHONE</span><p>{` ${orderData?.order_mobile}`}</p></div>
                                            </div>
                                        </div>
                                        {/* shipping  */}
                                        <div className='billing p-3 space-y-10'>
                                            <h3>SHIPPING ADDRESS</h3>
                                            <div className='space-y-2'>
                                                <div className='text-[14px] font-bold'>{orderData?.user_name}</div>
                                                <div className='text-[10px]'><span>{
                                                    orderData?.order_address
                                                }</span></div>
                                            </div>
                                            <div></div>
                                            <div className='space-y-2'>
                                                <div className='text-[10px]'><span>EMAIL</span></div>
                                                <div className='text-[10px]'><span>PHONE</span><p>{` ${orderData?.order_mobile}`}</p></div>
                                            </div>
                                        </div>
                                        {/* order */}
                                        <div className='border-solid border-gray-300 rounded-lg border-1 px-2'>
                                            <div className='p-3 flex justify-between text-2xl border-solid border-b border-gray-300 border-x-0 border-t-0'>
                                                <div>{`ORDER ID: `} <p className='font-semibold'>#{orderData?.order_id
                                                }</p></div>
                                                <div className='border-l border-solid border-gray-300 border-y-0 border-r-0'>PAYMENT METHOD:<p className='font-semibold'>{orderData?.payment_method}</p> </div>
                                            </div>
                                            <div className='border-solid border-x-0 border-t-0 border-b border-gray-300 flex justify-between text-2xl p-3'>
                                                <div>Subtotal</div>
                                                <div></div>
                                            </div>
                                            <div className='border-solid border-x-0 border-t-0 border-b  border-gray-300 flex justify-between text-2xl p-3'>
                                                <div>Discount</div>
                                                <div className='font-bold'>{orderData?.discount
                                                }</div>
                                            </div>
                                            <div className='border-solid border-x-0 border-t-0 border-b  border-gray-300 flex justify-between text-2xl p-3'>
                                                <div>Shipping</div>
                                                <div className='font-bold'>{orderData?.delivery_charge
                                                }</div>
                                            </div>
                                            <div className=' flex justify-between text-2xl p-3 font-bold'>
                                                <div>Total</div>
                                                <div className='text-green-600 font-extrabold'>{`Rs${orderData?.final_total}`}</div>
                                            </div>

                                        </div>
                                    </div>


                                    {/* tracker */}
                                    <div>
                                        <div className="stepper">
                                            {stepsConfig.map((step, index) => {
                                                return (
                                                    <div
                                                        key={step}
                                                        ref={(el) => (stepRef.current[index] = el)}
                                                        className={`step ${currentStep > index + 1 || isComplete ? "complete" : ""
                                                            } ${currentStep === index + 1 ? "active" : ""} `}
                                                    >
                                                        <div className="step-number">
                                                            {currentStep > index + 1 || isComplete ? (
                                                                <span>&#10003;</span>
                                                            ) : (
                                                                index + 1
                                                            )}
                                                        </div>
                                                        <div className="step-name">{step}</div>
                                                    </div>
                                                );
                                            })}

                                            <div
                                                className="progress-bar"
                                                style={{
                                                    width: `calc(100% - ${margins.marginLeft + margins.marginRight}px)`,
                                                    marginLeft: margins.marginLeft,
                                                    marginRight: margins.marginRight,
                                                }}
                                            >
                                                <div
                                                    className="progress"
                                                    style={{ width: `${calculateProgressBarWidth()}%` }}
                                                ></div>
                                            </div>
                                        </div>
                                    </div>



                                    {/* table */}
                                    <div className="container-body">
                                        <div className="table-container px-2">
                                            <table className="table">
                                                <thead>
                                                    <th>{t('product')}</th>
                                                    <th>{t('price')}</th>
                                                    <th>{t('Quantity')}</th>
                                                    <th>{t('subtotal')}</th>
                                                    {/* <th>{t('action')}</th> */}
                                                </thead>
                                                <tbody>
                                                    {orderData?.items?.map((item, index) => {
                                                        return (
                                                            <>
                                                                <tr key={index} className={Number(item?.active_status) > 6 ? 'disabled' : ''} >
                                                                    <td>
                                                                        <div className="product">
                                                                            <div className="image-container">
                                                                                {/* <img src={item.variant_data.variant_images.image_url || item.image_url} alt="" /> */}
                                                                            </div>
                                                                            <div className="item-container">
                                                                                <span className='item-name'>{item.variant_data.variant_name || item.name}</span>
                                                                            </div>
                                                                        </div>
                                                                    </td>
                                                                    <td>
                                                                        <div className="price-container">
                                                                            <span className="discounted-price">
                                                                                {`${setting.setting?.currency} ${item.price}`}
                                                                            </span>
                                                                            {/* {item.discounted_price !== 0 ?
                                                                                <span className="original-price">
                                                                                    {`${setting.setting?.currency} ${item.price}`}
                                                                                </span>
                                                                                : ""} */}
                                                                        </div>
                                                                        {/* <div className="actions-container">

                                                                            {!Number(item?.active_status) >= 6 && item?.return_status == 1 ?
                                                                                <span className="return">
                                                                                    <button onClick={() => handleUpdateStatus(item?.id, 8)}>{t('return')}</button>
                                                                                </span>
                                                                                : <></>
                                                                            }

                                                                            {!Number(item?.active_status) <= 6 && !Number(item?.active_status) <= item?.till_status && item?.cancelable_status == 1 ?
                                                                                <span className="cancel">
                                                                                    <button onClick={() => handleUpdateStatus(item?.id, 7)}>{t('cancel')}</button>
                                                                                </span>
                                                                                : <></>

                                                                            }
                                                                            {!Number(item?.active_status === 7 && item?.active_status === '7') ?
                                                                                <span className="cancel">
                                                                                    <button onClick={() => handleUpdateStatus(item?.id, 7)}>cancelled</button>
                                                                                </span>
                                                                                : <></>
                                                                            }
                                                                            {!Number(item?.active_status) == 8 ?
                                                                                <span className="return">
                                                                                    <button onClick={() => handleUpdateStatus(item?.id, 8)}>{t('returned')}</button>
                                                                                </span>
                                                                                : <></>
                                                                            }

                                                                        </div> */}
                                                                        <div className="actions-container">
                                                                            {Number(item?.active_status) == 6 && item?.return_status == 1 ?
                                                                                <span className="return">
                                                                                    <button onClick={() => setShowReturnModal(prevState => {
                                                                                        const newState = [...prevState];
                                                                                        newState[index] = true;
                                                                                        return newState;
                                                                                    })}>{t('return')}</button>
                                                                                </span>
                                                                                : null
                                                                            }

                                                                            {(Number(item?.active_status) <= 6) && (Number(item?.active_status) <= item?.till_status) && (item?.cancelable_status == 1) ?
                                                                                <span className="cancel">
                                                                                    <button onClick={() => handleUpdateStatus(item?.id, 7)}>{t('cancel')}</button>
                                                                                </span>
                                                                                : null
                                                                            }
                                                                            {Number(item?.active_status) == 7 ?
                                                                                <span className="cancelled">
                                                                                    <button>{t('cancelled')}</button>
                                                                                </span>
                                                                                : null
                                                                            }

                                                                            {Number(item?.active_status) == 8 ?
                                                                                <span className="returned">
                                                                                    <button >{t('returned')}</button>
                                                                                </span>
                                                                                : null
                                                                            }
                                                                        </div>

                                                                    </td>
                                                                    <td ><span className='item-quantity'> X {item.quantity}</span></td>
                                                                    <td ><div className="price-container"><span className='discounted-price'> {`${setting.setting?.currency} ${item.sub_total}`}</span></div></td>
                                                                </tr>
                                                                {
                                                                    showReturnModal[index] ?
                                                                        <Modal
                                                                            size='md'
                                                                            show={showReturnModal[index]}
                                                                            centered
                                                                            // onHide={() => setShowReturnModal(false)}
                                                                            onHide={() => setShowReturnModal(prevState => {
                                                                                const newState = [...prevState];
                                                                                newState[index] = false;
                                                                                return newState;
                                                                            })}
                                                                            backdrop="static"
                                                                        >
                                                                            <Modal.Header className='d-flex justify-content-between returnProductModalHeader'>
                                                                                <h5 className='title'>{t("return_order_item")}</h5>
                                                                                <AiOutlineCloseCircle className='cursorPointer' size={28} fill='black' onClick={() => setShowReturnModal(prevState => {
                                                                                    const newState = [...prevState];
                                                                                    newState[index] = false;
                                                                                    return newState;
                                                                                })} />
                                                                            </Modal.Header>
                                                                            <Modal.Body className='returnProductModalBody'>
                                                                                <form onSubmit={(e) => {
                                                                                    e.preventDefault();
                                                                                    // if (!returnRef.current.value.trim()) {
                                                                                    //     toast.error(t('please_type_return_reason'));
                                                                                    //     return; // Don't proceed further if the textarea is empty
                                                                                    // }
                                                                                    handleUpdateStatus(item?.id, 8, returnRef.current.value);
                                                                                }}>
                                                                                    <div className='d-flex flex-column justify-content-center'>
                                                                                        <label htmlFor='reasonTextArea' className='my-3 reasonLabel'>
                                                                                            {t("return_reason")}
                                                                                        </label>
                                                                                        <textarea
                                                                                            ref={returnRef}
                                                                                            id="reasonTextArea"
                                                                                            rows={8}
                                                                                            name='reasonTextArea'
                                                                                            placeholder={t("write_return_reason")}
                                                                                            className='reasonTextArea my-4'
                                                                                            required
                                                                                        />
                                                                                    </div>
                                                                                    <div className='d-flex justify-content-end mt-4'>
                                                                                        <button type='submit' className='returnSubmitBtn'>
                                                                                            {t("request_a_return")}
                                                                                        </button>
                                                                                    </div>
                                                                                </form>
                                                                            </Modal.Body>
                                                                        </Modal>
                                                                        : null}
                                                            </>

                                                        );

                                                    })}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>

                                    {/* <hr /> */}

                                    {/* <div className="container-footer">
                                        <div className="cancelReturnBtnWrapper">
                                            {
                                                orderData?.items[0]?.cancelable_status === 1 ?
                                                    "Cancel" : 'no cancel'
                                            }
                                        </div>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                        {/* <div className="col-12 col-xl-3">
                            <div className="order-info">
                                <div className="order-status-container order-info-container">
                                    <div className="container-heading">
                                        <span>
                                            {t('order')}
                                        </span>
                                        <span className="order-id">
                                            #{orderData?.id}
                                        </span>
                                    </div>
                                    <div className="status-body">
                                        {/* <div className="checkmark">
                                            <input type="checkbox" defaultChecked disabled />
                                            <ImCheckboxChecked fill='#55AE7B' />
                                        </div> */}
                                        <div className="order-status-details">
                                            <div className="order-status">
                                                {`${t('order')} ${orderStatus}`}
                                            </div>
                                            <div className="order-success">
                                                {`${t('your_order_has_been')} ${orderStatus} ${t('successfully')}`}
                                            </div>
                                            <div className="status-date">
                                                {orderData?.status?.length > 0 && new Date(orderData?.status.reverse()[0].reverse()[0]).toLocaleDateString()}
                                            </div>
                                        </div>
                                        <div className="payment-status">
                                            {/* <span className={`${orderData?.bank_transfer_status ? 'done' : ''}`}>
                                                {orderData?.bank_transfer_status ? t('payment_pending') : t('payment_done')}
                                            </span> 
                                        </div>
                                    </div>
                                </div>
                                <div className="order-info-container order-delivery-info">
                                    <div className="container-heading">
                                        <span>
                                            {t('delivery_information')}
                                        </span>
                                    </div>
                                    <div className="container-body">
                                        <div className="address-container">
                                            <span className='address-heading'>
                                                {t('delivery_to')}
                                            </span>
                                            <span className='address-info'>
                                                {orderData?.order_address}
                                            </span>
                                        </div>

                                        <div className="contact-container">
                                            <span>
                                                {`${orderData?.country} - ${orderData?.mobile}`}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div className="order-info-container order-billing-container">
                                    <div className="container-heading">
                                        <span>
                                            {t('billing_details')}
                                        </span>
                                    </div>
                                    <div className="container-body">
                                        <div className="payment-info">
                                            <div>
                                                <span>
                                                    {t('payment_method')}
                                                </span>
                                                <span>
                                                    {orderData?.payment_method}
                                                </span>
                                            </div>
                                            <div>
                                                <span>
                                                    {t('transaction_id')}
                                                </span>
                                                <span>
                                                    {orderData?.transaction_id}
                                                </span>
                                            </div>
                                            <div>
                                                <span>
                                                    {t('delivery_charge')}
                                                </span>
                                                <span>
                                                    {setting.setting?.currency}{orderData?.delivery_charge}
                                                </span>
                                            </div>
                                            <div>
                                                <span>
                                                    {t('sub_total')}
                                                </span>
                                                <span>
                                                    {setting.setting?.currency}{orderData?.total}
                                                </span>
                                            </div>
                                            {orderData?.promo_discount ? <div>
                                                <span>
                                                    {t('promo_code_discount')}
                                                </span>
                                                <span>
                                                    - {setting.setting?.currency}{orderData?.promo_discount}
                                                </span>
                                            </div> : null}
                                            {orderData?.wallet_balance ? <div>
                                                <span>
                                                    {t('wallet_balance_used')}
                                                </span>
                                                <span>
                                                    - {setting.setting?.currency}{orderData?.wallet_balance}
                                                </span>
                                            </div> : null}
                                            {orderData?.discount ?
                                                <div>
                                                    <span>
                                                        {t('discount')}
                                                    </span>
                                                    <span>
                                                        {setting.setting?.currency}{orderData?.discount}
                                                    </span>
                                                </div>
                                                : <></>}
                                        </div>
                                        <div className="order-total">

                                            <div>
                                                <span>
                                                    {t('total')}
                                                </span>
                                                <span>
                                                    ${orderData?.final_total}
                                                </span>
                                            </div>
                                        </div>
                                        {orderData?.active_status === "6" ?
                                            <div className="button-container">
                                                <button className="btn" onClick={() => {
                                                    getInvoice(orderData?.id)
                                                }}>
                                                    {t('get_invoice')}
                                                </button>
                                            </div>
                                            : <></>}
                                    </div>
                                </div>
                            </div>
                        </div> */}
                    </div>
                </div>
                </div>

            </section>
        </>
    )
})

export default OrderDetails

{/* <div className="container">
                    <div className="row">
                        <div className="col-12 col-xl-9">
                            <div className="order-container">
                                <div className="list-container">

                                    <div className="container-heading">
                                        <span>
                                            {t('items')}
                                        </span>
                                    </div>
                                    <div className="container-body">
                                        <div className="table-container">
                                            <table className="table">

                                                <thead>
                                                    <th>{t('product')}</th>
                                                    <th>{t('price')}</th>
                                                    {/* <th>{t('action')}</th> 
                                                </thead>
                                                <tbody>
                                                    {orderData?.items?.map((item, index) => {
                                                        return (
                                                            <>
                                                                <tr key={index} className={Number(item?.active_status) > 6 ? 'disabled' : ''}>
                                                                    <td>
                                                                        <div className="product">

                                                                            <div className="image-container">
                                                                                {/* <img src={item.variant_data.variant_images.image_url || item.image_url} alt="" /> 
                                                                            </div>
                                                                            <div className="item-container">
                                                                                <span className='item-name'>{item.variant_data.variant_name || item.name}</span>
                                                                                <span className='item-quantity'> X {item.quantity}</span>
                                                                                <span className='item-variant'>{` ${item.variant_data.attribute_name} : ${item.variant_data.variant_attribute_value}`}</span>
                                                                            </div>
                                                                        </div>
                                                                    </td>
                                                                    <td>
                                                                        <div className="price-container">
                                                                            <span className="discounted-price">
                                                                                {`${setting.setting?.currency} ${item.price}`}
                                                                            </span>
                                                                            {/* {item.discounted_price !== 0 ?
                                                                                <span className="original-price">
                                                                                    {`${setting.setting?.currency} ${item.price}`}
                                                                                </span>
                                                                                : ""} 
                                                                        </div>
                                                                        <div className="actions-container">

                                                                            {!Number(item?.active_status) > 6 && !item?.cancelable_status && item?.return_status ?
                                                                                <span className="return">
                                                                                    <button onClick={() => handleUpdateStatus(item?.id, 8)}>{t('return')}</button>
                                                                                </span>
                                                                                : <></>
                                                                            }

                                                                            {!Number(item?.active_status) > 6 && item?.cancelable_status ?
                                                                                <span className="cancel">
                                                                                    <button onClick={() => handleUpdateStatus(item?.id, 7)}>{t('cancel')}</button>
                                                                                </span>
                                                                                : <></>
                                                                            }

                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                            </>
                                                        )
                                                    })}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-xl-3">
                            <div className="order-info">
                                <div className="order-status-container order-info-container">
                                    <div className="container-heading">
                                        <span>
                                            {t('order')}
                                        </span>
                                        <span className="order-id">
                                            #{orderData?.id}

                                        </span>
                                    </div>
                                    <div className="status-body">
                                        <div className="checkmark">
                                            <input type="checkbox" defaultChecked />
                                        </div>
                                        <div className="order-status-details">
                                            <div className="order-status">
                                                {`${t('order')} ${orderStatus}`}
                                            </div>
                                            <div className="order-success">
                                                {`${t('your_order_has_been')} ${orderStatus} ${t('successfully')}`}
                                            </div>
                                            <div className="status-date">
                                                {orderData?.status?.length > 0 && new Date(orderData?.status.reverse()[0].reverse()[0]).toLocaleDateString()}
                                            </div>
                                        </div>
                                        <div className="payment-status">
                                            {/* <span className={`${orderData?.bank_transfer_status ? 'done' : ''}`}>
                                                {orderData?.bank_transfer_status ? t('payment_pending') : t('payment_done')}
                                            </span> 
                                        </div>
                                    </div>
                                </div>
                                <div className="order-info-container order-delivery-info">
                                    <div className="container-heading">
                                        <span>
                                            {t('delivery_information')}
                                        </span>
                                    </div>
                                    <div className="container-body">
                                        <div className="address-container">
                                            <span className='address-heading'>
                                                {t('delivery_to')}
                                            </span>
                                            <span className='address-info'>
                                                {orderData?.order_address}
                                            </span>
                                        </div>

                                        <div className="contact-container">
                                            <span>
                                                {`${orderData?.country} - ${orderData?.mobile}`}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div className="order-info-container order-billing-container">
                                    <div className="container-heading">
                                        <span>
                                            {t('billing_details')}
                                        </span>
                                    </div>
                                    <div className="container-body">
                                        <div className="payment-info">
                                            <div>
                                                <span>
                                                    {t('payment_method')}
                                                </span>
                                                <span>
                                                    {orderData?.payment_method}
                                                </span>
                                            </div>
                                            <div>
                                                <span>
                                                    {t('transaction_id')}
                                                </span>
                                                <span>
                                                    {orderData?.transaction_id}
                                                </span>
                                            </div>
                                            <div>
                                                <span>
                                                    {t('delivery_charge')}
                                                </span>
                                                <span>
                                                    {setting.setting?.currency}{orderData?.delivery_charge}
                                                </span>
                                            </div>
                                            <div>
                                                <span>
                                                    {t('sub_total')}
                                                </span>
                                                <span>
                                                    {setting.setting?.currency}{orderData?.total}
                                                </span>
                                            </div>
                                            {orderData?.discount ?
                                                <div>
                                                    <span>
                                                        {t('discount')}
                                                    </span>
                                                    <span>
                                                        {setting.setting?.currency}{orderData?.discount}
                                                    </span>
                                                </div>
                                                : <></>}
                                        </div>
                                        <div className="order-total">

                                            <div>
                                                <span>
                                                    {t('total')}
                                                </span>
                                                <span>
                                                    ${orderData?.final_total}
                                                </span>
                                            </div>
                                        </div>
                                        {orderData?.active_status === "6" ?
                                            <div className="button-container">
                                                <button className="btn" onClick={() => {
                                                    getInvoice(orderData?.id);
                                                }}>
                                                    {t('get_invoice')}
                                                </button>
                                            </div>
                                            : <></>}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}
