import { Rating, Slider } from "@mui/material";
import classNames from "classnames";
import React, { useState } from "react";
import { IoIosArrowUp } from "react-icons/io";
import GradeIcon from "@mui/icons-material/Grade";
import StarBorderIcon from "@mui/icons-material/StarBorder";

const ProductsAccordion = (props) => {
  const {
    productsCategory,
    filterbyCategory,
    minmaxTotalPrice,
    onPriceRangeChange,
  } = props;
  const accordionItems = ["Products", "Price", "Rating", "Popular Tag"];
  const [open, setOpen] = useState({
    products: true,
    price: true,
    rating: true,
    popularTag: true,
  });
  return (
    <div className="max-w-[13vw]">
      <div>
        <div className="flex items-center  mb-10 justify-between ">
          <h1 className="font-bold">{accordionItems[0]}</h1>
          <IoIosArrowUp
            onClick={() =>
              setOpen((prev) => ({ ...prev, products: !open.products }))
            }
            className={classNames(
              " cursor-pointer",
              !open.products && "rotate-180"
            )}
            size={20}
          />
        </div>
        {open.products && (
          <Products
            filterbyCategory={filterbyCategory}
            productsCategory={productsCategory}
          />
        )}
      </div>
      <div
        style={{ borderTop: "1px solid #e0dede" }}
        className=" py-10 !border-t border-gray-500"
      >
        <div className="flex items-center justify-between">
          <h1 className="font-bold">{accordionItems[1]}</h1>
          <IoIosArrowUp
            onClick={() => setOpen((prev) => ({ ...prev, price: !open.price }))}
            className={classNames(
              " cursor-pointer",
              !open.price && "rotate-180"
            )}
            size={20}
          />
        </div>
        {open.price && (
          <Price
            onPriceRangeChange={onPriceRangeChange}
            minmaxTotalPrice={minmaxTotalPrice}
          />
        )}
      </div>
      <div>
        <div
          style={{ borderTop: "1px solid #e0dede" }}
          className="flex pt-10 items-center justify-between"
        >
          <h1 className="font-bold">{accordionItems[2]}</h1>
          <IoIosArrowUp
            onClick={() =>
              setOpen((prev) => ({ ...prev, rating: !open.rating }))
            }
            className={classNames(
              " cursor-pointer",
              !open.rating && "rotate-180"
            )}
            size={20}
          />
        </div>
        {open.rating && <RatingComp />}
      </div>
      <di className="">
        <div
          style={{ borderTop: "1px solid #e0dede" }}
          className="flex pt-10 items-center justify-between mt-20"
        >
          <h1 className="font-bold">{accordionItems[3]}</h1>
          <IoIosArrowUp
            onClick={() =>
              setOpen((prev) => ({ ...prev, popularTag: !open.popularTag }))
            }
            className={classNames(
              " cursor-pointer",
              !open.popularTag && "rotate-180"
            )}
            size={20}
          />
        </div>
        {open.popularTag && <PopularTag />}
      </di>
    </div>
  );
};

export default ProductsAccordion;
const PopularTag = () => {
  const [tagSelected, setTagSelected] = useState([]);

  const tags = [
    "shampoo",
    "Popular",
    "hair",
    "Women",
    "Keratin",
    "Dandruff free",
    "Less hair removal",
    "Men",
  ];

  const toggleTag = (tag) => {
    if (tagSelected.includes(tag)) {
      setTagSelected(tagSelected.filter((item) => item !== tag));
    } else {
      setTagSelected([...tagSelected, tag]);
    }
  };

  return (
    <div className="flex mt-5 flex-wrap items-center gap-5">
      {tags.map((tag, i) => (
        <div
          key={i}
          onClick={() => toggleTag(tag)}
          className={classNames("py-2 cursor-pointer px-4 rounded-full", {
            "bg-gray-200": !tagSelected.includes(tag),
            "bg-[#0199e3] text-white": tagSelected.includes(tag),
          })}
        >
          {tag}
        </div>
      ))}
    </div>
  );
};
const RatingComp = () => {
  return (
    <div className="flex flex-col mt-5 gap-y-5">
      <div className="flex items-center gap-x-3">
        <input className="p-1" type="checkbox" name="" id="" />
        <Rating
          icon={<GradeIcon fontSize="large" className="!fill-yellow-400 " />}
          className="text-[4rem] w-10"
          size="large"
          name="read-only"
          value={5}
          emptyIcon={<StarBorderIcon fontSize="large" />}
          readOnly
        />
        <div>5.0</div>
      </div>

      <div className="flex items-center gap-x-3">
        <input type="checkbox" name="" id="" />
        <Rating
          name="read-only"
          size="large"
          value={4}
          icon={<GradeIcon fontSize="large" className="!fill-yellow-400 " />}
          emptyIcon={<StarBorderIcon fontSize="large" />}
          readOnly
        />
        <div>4.0</div>
      </div>

      <div className="flex items-center gap-x-3">
        <input type="checkbox" name="" id="" />
        <Rating
          name="read-only"
          size="large"
          value={3}
          emptyIcon={<StarBorderIcon fontSize="large" />}
          icon={<GradeIcon fontSize="large" className="!fill-yellow-400 " />}
          readOnly
        />
        <div>3.0</div>
      </div>

      <div className="flex items-center gap-x-3">
        <input type="checkbox" name="" id="" />
        <Rating
          name="read-only"
          size="large"
          icon={<GradeIcon fontSize="large" className="!fill-yellow-400 " />}
          emptyIcon={<StarBorderIcon fontSize="large" />}
          value={2}
          readOnly
        />
        <div>2.0</div>
      </div>

      <div className="flex items-center gap-x-3">
        <input type="checkbox" name="" id="" />
        <Rating
          name="read-only"
          emptyIcon={<StarBorderIcon fontSize="large" />}
          icon={<GradeIcon fontSize="large" className="!fill-yellow-400 " />}
          size="large"
          value={1}
          readOnly
        />
        <div>1.0</div>
      </div>
    </div>
  );
};

const Products = ({ productsCategory, filterbyCategory }) => {
  const [openChild, setOpenChild] = useState(false);
  console.log(productsCategory, "category");
  return (
    <>
    <div className="mb-40">
      {productsCategory.map((item, index) => {
        return (
          <div className="my-4" key={index}>
            <div className="flex justify-between font-medium items-center">
              <div className="flex items-center">
                <input
                  onClick={() => filterbyCategory(item)}
                  type="radio"
                  id={item.id}
                  name="product"
                  value={item.name}
                  className="!h-10 !w-10 border border-gray-800 align-middle rounded-full mr-2 cursor-pointer"
                />
                <label className="ml-5 text-[2rem]" htmlFor={item.id}>
                  {item.name}
                </label>
              </div>
              {item.has_child && (
                <button
                  onClick={() => setOpenChild(!openChild)}
                  className="text-[2.5rem] text-gray-500 font-bold"
                >
                  {openChild ? "-" : "+"}
                </button>
              )}
            </div>
            <div className="ml-10 my-2">
              {item.has_child &&
                openChild &&
                item.cat_active_childs.map((child) => (
                  <div>
                    <input
                      onClick={() => filterbyCategory(child)}
                      type="radio"
                      id={child.id}
                      name="product"
                      value={child.name}
                      className="!h-10 !w-10 border border-gray-800 align-middle rounded-full mr-2 cursor-pointer"
                    />
                    <label
                      className="ml-5 -mt-10  text-[2rem]"
                      htmlFor={child.id}
                    >
                      {child.name}
                    </label>
                  </div>
                ))}
            </div>
          </div>
        );
      })}
    </div>
    </>
  );
};

const Price = ({ minmaxTotalPrice, onPriceRangeChange }) => {
  const [value, setValue] = React.useState([20, 37]);

  const handleChange = (event, newValue) => {
    onPriceRangeChange(newValue);
    setValue(newValue);
  };
  return (
    <div className="mt-5">
      <Slider
        style={{ color: "blue" }}
        sx={{
          "& .css-1as9v07-MuiSlider-thumb": {
            border: "2px solid #0199E3",
            color: "white",
          },
          "&  .css-ttgsjq-MuiSlider-track": { color: "#0199E3 !important" },
          "& .MuiSlider-valueLabelLabel": { bgcolor: "white" },
          "& .css-14gyywz-MuiSlider-valueLabel": { bgcolor: "white" },
        }}
        color="primary"
        min={0}
        max={Number(minmaxTotalPrice.total_max_price)}
        className="text-blue-600 mt-2"
        value={value}
        onChange={handleChange}
        valueLabelDisplay="auto"
      />
      <div className="flex items-center gap-x-4 mt-2">
        <p className="text-gray-500">Price:</p>
        <p>
          {" "}
          {value[0]} - {value[1]}
        </p>
      </div>
    </div>
  );
};
