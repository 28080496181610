import React from 'react'

const NewsSubscription = () => {
  return (
    <div className='w-full flex justify-center flex-wrap  gap-x-32 items-center py-24 border border-t border-gray-500'>
      <h1 className='text-[#015ca1] text-[5rem] font-semibold '>Healthian</h1>
      <div>
        <h2 className='text-[#015ca1] text-[3rem] font-semibold'>Subscribe to our Newsletter</h2>
        <p className='text-[#015ca1] text-[1.8rem]'>Polentesque eu nigb eget mauris congue matlis matti</p>
      </div>
      <div className='w-[25vw]  flex items-center justify-between h-24 rounded-full border border-gray-50 overflow-hidden'>
        <input type="text" placeholder='Your email address' className='text-[2rem] placeholder:text-[#015ca1] placeholder:-translate-x-16 ml-16' />
        <button className='bg-[#0199e3] font-semibold text-[2.5rem] text-white px-16 h-full rounded-full'>Subscribe</button>
      </div>
    </div>
  )
}

export default NewsSubscription
