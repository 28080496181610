import React from "react";
import selectedDot from "./service-assets/Vector.svg";
import emptyDot from './service-assets/radix-icons_radiobutton.svg'
import blueDot from "./service-assets/Progress Bullet.svg";
import classNames from "classnames";
import { useSelector } from "react-redux";
import serviceModalNextfn from "../../utils/modalNext";
import { toast } from "react-toastify";
import useToken from "../../hooks/useToken";

const ModalLabels = ({ children, label, currentStage, setCurrentStage }) => {

  const {service_id,provider_id,schedule,Name,age,email,gender,phone} = useSelector((state)=>state.newAppointment)

  const {notes:additional_info,schedule:scheduled_for,Name:name} = useSelector((state) => state.newAppointment);
  const {appointment} = useSelector(state => state.appointment)
  const token = useToken()
  const [date,timeslot] = schedule ? schedule.split(" ") : [null,null]
  const {  consult_type: selected } = useSelector(
    (state) => state.newAppointment
  );
  const onToBack = () => {
    if(selected === 'expert'){
      setCurrentStage(currentStage > 1 ? currentStage - 1 : 1);
    }else{
      setCurrentStage(1)
    }
  };


const next = () => {
  serviceModalNextfn(setCurrentStage,currentStage)
}


 const onToNext = () => {
   
  if(selected === 'expert'){
    
    switch (currentStage) {
      case 1:
        next();
        break;
      case 2:
        service_id ? next() : toast.error('Please select one speciality')
        break;
      case 3:
        provider_id ? next() : toast.error('Please select an expert')
        break;
      case 4:
  
       ( date && !timeslot.includes('null')) ? next() : toast.error('Please select the Date and time slot')
        break;
      case 5 :
        next()
        break;
      case 6 :
        if(Name && age && email && gender && phone){
            if(appointment){
              next()
            }else{
              toast.error("Book an Appointment first")
            }
        }else{
          toast.error('Details are not filled')
        }
        break;
    }

  }
 
  };

  return (
    <div className="w-full flex items-center  justify-center relative">
      <div className="w-fit pb-60 rounded-[15px]  bg-white">
        <div className="flex  flex-col items-center w-full justify-center mt-20">
          <div style={{borderBottom:'1px solid #EDEDED  '}} className="flex px-24 pb-10 items-center gap-x-20">
            <button
              onClick={() => onToBack()}
              // style={{ border: "1px solid #0199E3" }}
              className="text-gray-600 font-bold text-[1.5rem] rounded-[8.18px] border border-gray-600 px-[1.5rem] py-[0.5rem]"
            >
              Back
            </button>
            <div  className="flex items-center gap-x-4">
              {serviceItems &&
                serviceItems.map((item, i) => (
                  <div className="flex items-center  gap-x-5">
                    {item.dot &&
                      (currentStage - 1 == i  ? (
                        <img src={selectedDot} alt="" />
                      ) : i < currentStage ? (<img src={emptyDot}/>) : (
                        <img src={blueDot} />
                      ))}
                    <h1
                      className={classNames(
                        "text-[2rem] mt-2.5 font-bold text-gray-500 ",
                        currentStage - 1 == i && "!text-[#015CA1]"
                      )}
                    >
                      {item.label}
                    </h1>
                    {i !== serviceItems.length - 1 && (
                      <div className="w-20 h-[1px] !bg-gray-300" />
                    )}
                  </div>
                ))}
            </div>
            <button
              onClick={() => onToNext(serviceItems)}
              className="bg-[#0199E3] text-white font-bold text-[1.5rem] px-[1.5rem] py-[0.5rem] rounded-[8.18px]"
            >
              Next
            </button>
          </div>
           <div className="px-24 w-full">
           {children}
           </div>
        </div>
      </div>
    </div>
  );
};

export default ModalLabels;

const serviceItems = [
  {
    dot: true,
    label: "Free Vs Expert",
  },
  {
    dot: true,
    label: "Speciality",
  },
  {
    dot: true,
    label: "Person",
  },
  {
    dot: true,
    label: "Date & Time",
  },
  { dot: true, label: "Mode of communication" },
  {
    dot: true,
    label: "Details",
  },
  {
    dot: true,
    label: "Summary",
  },
];
