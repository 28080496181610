import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import api from "../../api/api";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./product.css";
import { AiOutlineEye } from "react-icons/ai";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import { BsHeart, BsShare, BsPlus, BsHeartFill } from "react-icons/bs";
import { BiMinus, BiLink } from "react-icons/bi";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import Cookies from "universal-cookie";
import { ActionTypes } from "../../model/action-type";

import QuickViewModal from "./QuickViewModal";
import Offers from "../offer/Offers";
import {
  FacebookIcon,
  FacebookShareButton,
  TelegramIcon,
  TelegramShareButton,
  WhatsappIcon,
  WhatsappShareButton,
} from "react-share";
import { IoIosArrowDown } from "react-icons/io";
import { useTranslation } from "react-i18next";
import Card from "../card/Card";
import { Product } from "../HomePage/Product";
import { setProductSizes } from "../../model/reducer/productSizesReducer";
import { setCart } from "../../model/reducer/cartReducer";
import { setFavourite } from "../../model/reducer/favouriteReducer";

const FeaturedProducts = () => {
  //initialize cookies
  const cookies = new Cookies();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const city = useSelector((state) => state.city);
  const shop = useSelector((state) => state.shop);
  const setting = useSelector((state) => state.setting);
  const cart = useSelector((state) => state.cart);
  const sizes = useSelector((state) => state.productSizes);
  const favorite = useSelector((state) => state.favourite);
  const [selectedVariant, setSelectedVariant] = useState({});
  const [productbrand, setproductbrand] = useState({});

  // const shop = useSelector(state=>state.shop);

  useEffect(() => {
    if (sizes.sizes === null || sizes.status === "loading") {
      if (city.city !== null) {
        api
          .getProductbyFilter(
            city.city.id,
            city.city.latitude,
            city.city.longitude
          )
          .then((response) => response.json())
          .then((result) => {
            if (result.status === 1) {
              setproductSizes(result.sizes);
              dispatch(setProductSizes({ data: result.sizes }));
              // dispatch({
              //   type: ActionTypes.SET_PRODUCT_SIZES,
              //   payload: result.sizes,
              // });
            }
          });
      }
    } else {
      setproductSizes(sizes.sizes);
    }
  }, [city, sizes]);

  const [selectedProduct, setselectedProduct] = useState({});
  const [productSizes, setproductSizes] = useState(null);
  const [offerConatiner, setOfferContainer] = useState(0);
  const [variant_index, setVariantIndex] = useState(null);
  const [isLoader, setisLoader] = useState(false);

  //for product variants dropdown in product card

  const addtoCart = async (product_id, product_variant_id, qty) => {
    setisLoader(true);
    await api
      .addToCart(cookies.get("jwt_token"), product_id, product_variant_id, qty)
      .then((response) => response.json())
      .then(async (result) => {
        if (result.status === 1) {
          toast.success(result.message);
          await api
            .getCart(
              cookies.get("jwt_token"),
              city.city.latitude,
              city.city.longitude
            )
            .then((resp) => resp.json())
            .then((res) => {
              if (res.status === 1) dispatch(setCart({ data: res }));
              // dispatch({ type: ActionTypes.SET_CART, payload: res });
            });
        } else {
          toast.error(result.message);
        }
        setisLoader(false);
      });
  };

  //Add to favorite
  const addToFavorite = async (product_id) => {
    await api
      .addToFavotite(cookies.get("jwt_token"), product_id)
      .then((response) => response.json())
      .then(async (result) => {
        if (result.status === 1) {
          toast.success(result.message);
          await api
            .getFavorite(
              cookies.get("jwt_token"),
              city.city.latitude,
              city.city.longitude
            )
            .then((resp) => resp.json())
            .then((res) => {
              if (res.status === 1) dispatch(setFavourite({ data: res }));
              // dispatch({ type: ActionTypes.SET_FAVORITE, payload: res });
            });
        } else {
          toast.error(result.message);
        }
      });
  };
  const removefromCart = async (product_id, product_variant_id) => {
    setisLoader(true);
    await api
      .removeFromCart(cookies.get("jwt_token"), product_id, product_variant_id)
      .then((response) => response.json())
      .then(async (result) => {
        if (result.status === 1) {
          toast.success(result.message);
          await api
            .getCart(
              cookies.get("jwt_token"),
              city.city.latitude,
              city.city.longitude
            )
            .then((resp) => resp.json())
            .then((res) => {
              if (res.status === 1) dispatch(setCart({ data: res }));
              // dispatch({ type: ActionTypes.SET_CART, payload: res });
              else dispatch(setCart({ data: null }));
              //  dispatch({ type: ActionTypes.SET_CART, payload: null });
            });
        } else {
          toast.error(result.message);
        }
        setisLoader(false);
      });
  };
  // remove from favorite
  const removefromFavorite = async (product_id) => {
    await api
      .removeFromFavorite(cookies.get("jwt_token"), product_id)
      .then((response) => response.json())
      .then(async (result) => {
        if (result.status === 1) {
          toast.success(result.message);
          await api
            .getFavorite(
              cookies.get("jwt_token"),
              city.city.latitude,
              city.city.longitude
            )
            .then((resp) => resp.json())
            .then((res) => {
              if (res.status === 1) dispatch(setFavourite({ data: res }));
              // dispatch({ type: ActionTypes.SET_FAVORITE, payload: res });
              else dispatch(setFavourite({ data: null }));
              //  dispatch({ type: ActionTypes.SET_FAVORITE, payload: null });
            });
        } else {
          toast.error(result.message);
        }
      });
  };

  const settings = {
    infinite: false,
    slidesToShow: 5.5,
    slidesPerRow: 1,
    initialSlide: 0,
    // centerMode: true,
    centerMargin: "10px",
    margin: "20px", // set the time interval between slides
    // Add custom navigation buttons using Font Awesome icons
    prevArrow: (
      <button type="button" className="slick-prev">
        <FaChevronLeft size={30} className="prev-arrow" />
      </button>
    ),
    nextArrow: (
      <button type="button" className="slick-next">
        <FaChevronRight color="#f7f7f7" size={30} className="next-arrow" />
      </button>
    ),
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 425,
        settings: {
          slidesToShow: 1.2,
        },
      },
    ],
  };

  const placeHolderImage = (e) => {
    e.target.src = setting.setting?.web_logo;
  };

  return (
    <section
      id="featuredProducts"
      className=" py-32 relative bg-white flex flex-col justify-center items-center"
    >
      <h1 className="text-[4rem] font-bold ">Featured Products</h1>
      <div className="mt-40">
        {shop.shop&& shop.shop.sections?.map((section, i) => {
          if (section.products.length > 0) {
            return (
              <div className="flex" key={i}>
                {section.products.slice(0, 5).map((product, j) => {
                  console.log(product, "product");
                  return (
                    <div key={j}>
                      <Product
                        product={product}
                        addtoCart={addtoCart}
                        removefromCart={removefromCart}
                        removefromFavorite={removefromFavorite}
                        addToFavorite={addToFavorite}
                        favorite={favorite}
                        productbrand={productbrand}
                        setting={setting}
                      />
                    </div>
                  );
                })}
              </div>
            );
          }
        })}
      </div>
    </section>
  );
};

export default FeaturedProducts;
