import { useDispatch } from "react-redux";
import ProductsAccordion from "./ProductsAccordion";
import Loader from "../loader/Loader";
import { setFilterMinMaxPrice } from "../../model/reducer/productFilterReducer";

const Filter = ({filterbyCategory,setcurrPage,setoffset,minmaxTotalPrice,category,brands,filterbyBrands,filter,placeHolderImage}) => {
    const dispatch = useDispatch()
    return (
      <>
        <div>
          <div>
            <ProductsAccordion
                onPriceRangeChange={([min, max]) => {
                setcurrPage(1);
                setoffset(0);
                dispatch(setFilterMinMaxPrice({data:{ min_price: min, max_price: max }}))
                // dispatch({
                //   type: ActionTypes.SET_FILTER_MIN_MAX_PRICE,
                //   payload: { min_price: min, max_price: max },
                // });
              }}
              filterbyCategory={filterbyCategory}
              minmaxTotalPrice={minmaxTotalPrice}
              productsCategory={category.category}
            />
          </div>
          <div></div>
        </div>
      
        <div className="max-w-[15vw] mt-20">
          <h1 className="font-bold py-10" style={{ borderTop: "1px solid #e0dede" }}>Brands</h1>
          {brands === null ? (
            <Loader />
          ) : (
            <>
           <div className="flex flex-wrap gap-10 ">
           {brands.map((brand, index) => (
                <div
                  whileTap={{ scale: 0.8 }}
                  onClick={() => {
                    filterbyBrands(brand);
                  }}
                  className={` cursor-pointer  ${
                    filter.brand_ids?.length != 0
                      ? filter.brand_ids.includes(brand.id)
                        ? "active"
                        : null
                      : null
                  }`}
                  key={index}
                >
                    <div className="flex items-center c border p-3 gap-10 w-fit border-gray-500 rounded-lg">
                  <div >
                      <img
                        onError={placeHolderImage}
                        src={brand.image_url}
                        alt="category"
                        className="w-40"
                      ></img>
                    </div>
                    <p className="font-bold px-4">{brand.name}</p>
                  </div>
                  {/* <div className='d-flex align-items-baseline justify-content-center brand-count'>
                                            <p className='m-auto'>{brandproductcountmap.get(`brand${brand.id}`) !== undefined
                                                ? brandproductcountmap.get(`brand${brand.id}`)
                                                : 0}
                                            </p>
                                        </div> */}
                </div>
              ))}
           </div>
            </>
          )}
        </div>
      </>
    );
  };

  export default Filter