import React from 'react'
import Cookies from 'universal-cookie'

const useToken = () => {
    const cookies = new Cookies()
    const token = cookies.get('jwt_token')
    
    return token ? token : null
}

export default useToken
