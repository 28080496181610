import React, { useEffect, useState } from "react";
import personImg from "./service-assets/Photo by mahdi chaghari.png";
import groupIcon from "./service-assets/Group.svg";
import trendIcon from "./service-assets/Group (copy).svg";
import messageIcon from "./service-assets/Chat.svg";
import starIcon from "./service-assets/Star.svg";
import threeDot from './service-assets/More Circle.svg'
import { FaRegHeart } from "react-icons/fa6";
import api from "../../api/api";
import useToken from "../../hooks/useToken";
import { useDispatch } from "react-redux";
import { setProviderId } from "../../model/reducer/newAppointmentReducer";


const ExpertDetails = ({ modal, setModal,setCurrentStage,expertId }) => {

  const dispatch = useDispatch()
  
  const token = useToken()

  const [expert,setExpert] = useState(null)
  

  const getExpert = async () => {
    let response = await api.getServiceProviderbyId(expertId,token)
    let result = await response.json()
    
    if(result.status === 1){
      const data = result.data.find(element=>element.service_provider_id === expertId)
      setExpert(data)
    }
  }

  const bookAppointmentHandler = (id) => {
      dispatch(setProviderId(id))
      setCurrentStage(4)
  }



  useEffect(() => {

    if(token && modal) getExpert()

    const handleClickOutside = (event) => {
      if (modal && event.target.closest(".expert-modal") === null) {
        setModal(false);
        setExpert(null)
      }
    };

    if (modal) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [modal, setModal]);
  if(expert) return (
    modal && (
      <div className="!w-screen !h-full fixed bg-black/80 top-0 right-0 left-0 bottom-0 flex items-center z-[2000] justify-center">
        <div className="!bg-gray-100 px-[29rem] flex flex-col items-center w-[90%] h-[90%] overflow-y-auto overflow-x-hidden p-20 relative expert-modal z-[2001]">
          <h1 className="font-bold text-[3rem] capitalize">{expert?.sp_name}</h1>
          <div className="flex items-center gap-x-8 bg-white shadow-lg p-4 rounded-2xl w-[60vw] mt-20">
            <img src={expert?.image_url} alt="" className="w-60 h-60" />
            <div className="w-full">
              <h1 className="font-bold w-full capitalize ">{expert?.sp_name}</h1>
              <div className="w-full h-[1px] bg-gray-300 my-6" />
              <p className="text-[1.8rem]">{expert?.name}</p>
              <p className="text-[1.8rem]">Christ Hospital in London, UK</p>
            </div>
          </div>
          <div className="flex my-20 items-center gap-x-40">
            <div className="flex flex-col justify-center items-center gap-y-4">
              <div className="w-32 h-32 bg-[#246BFD]/10 rounded-full flex items-center justify-center">
                <img src={groupIcon} alt="" className="w-12 h-12" />
              </div>
              <h1 className="text-[2rem] font-bold text-[#246BFD] mt-4">
                5,000+
              </h1>
              <p className="text-[1.5rem] text-[#424242] font-semibold">
                patients
              </p>
            </div>
            <div className="flex flex-col justify-center items-center gap-y-4">
              <div className="w-32 h-32 bg-[#246BFD]/10 rounded-full flex items-center justify-center">
                <img src={trendIcon} alt="" className="w-12 h-12" />
              </div>
              <h1 className="text-[2rem] font-bold text-[#246BFD] mt-4">{expert?.years_of_experience}+</h1>
              <p className="text-[1.5rem] text-[#424242] font-semibold">
                years exper...
              </p>
            </div>
            <div className="flex flex-col justify-center items-center gap-y-4">
              <div className="w-32 h-32 bg-[#246BFD]/10 rounded-full flex items-center justify-center">
                <img src={starIcon} alt="" className="w-12 h-12" />
              </div>
              <h1 className="text-[2rem] font-bold text-[#246BFD] mt-4">4.8</h1>
              <p className="text-[1.5rem] text-[#424242] font-semibold">
                rating
              </p>
            </div>
            <div className="flex flex-col justify-center items-center gap-y-4">
              <div className="w-32 h-32 bg-[#246BFD]/10 rounded-full flex items-center justify-center">
                <img src={messageIcon} alt="" className="w-12 h-12" />
              </div>
              <h1 className="text-[2rem] font-bold text-[#246BFD] mt-4">
                4,942
              </h1>
              <p className="text-[1.5rem] text-[#424242] font-semibold">
                reviews
              </p>
            </div>
          </div>
          <div className="flex flex-col w-[60vw]">
            <h1 className="font-bold text-left">About me</h1>
            <p className="text-[1.6rem]">
              Dr. Jenny Watson is the top most Immunologists specialist in
              Christ Hospital at London. She achived several awards for her
              wonderful contribution in medical field. She is available for
              private consultation.{" "}
              <span className="text-[#246BFD]">view more</span>
            </p>
          </div>
          <div className="flex flex-col w-[60vw] my-10">
            <h1 className="font-bold text-left">Working Time</h1>
            <p className="text-[1.6rem]">
              Monday - Friday, 08:00 AM - 20:00 PM
            </p>
          </div>
          <div className="flex items-center justify-between w-[60vw] my-10">
            <h1 className="font-bold">Reviews</h1>
            <h1 className="font-bold text-[#246BFD]">See All</h1>
          </div>
          <div>

          <div className="w-[60vw] my-10 flex items-center justify-between">
            <div className="flex items-center gap-x-5">
              <img src={personImg} alt="" className="w-20 h-20 rounded-full" />
              <h2 className="font-bold mt-3">Charolette Hanlin</h2>
            </div>
            <div className="flex items-center gap-x-5">
              <div className="rounded-full flex items-center  px-5 gap-x-6 text-[#246BFD] border border-[#246BFD] ">
                <img src={starIcon} alt="" className="w-6" />
                <p className="text-[1.5rem] mt-3">5</p>
              </div>
             <img src={threeDot} />
            </div> 
          </div>
            <p className="text-[1.8rem]">Dr. Jenny is very professional in her work and responsive. I have consulted and my problem is solved.</p>
            <div className="mt-5 flex items-center gap-x-5">
              <FaRegHeart size={20} className=" fill-blue-500"/>
              <h3 className="mt-2.5">938</h3>
              <p className="text-[1.5rem] mt-3 ml-5 text-gray-500">6 days ago</p>
            </div>
          </div>
          <button onClick={()=>bookAppointmentHandler(expert.id)} className="bg-[#246BFD]  text-white font-semibold  text-[2rem] py-4 px-40 rounded-full mt-20">Book Appointment</button>
        </div>
      </div>
    )
  );
};

export default ExpertDetails;
