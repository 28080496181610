import React, { useEffect, useState } from "react";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import { BiMinus, BiLink } from "react-icons/bi";

import classNames from "classnames";
import starIcon from "./star.png";
import {
  FacebookIcon,
  FacebookShareButton,
  TelegramIcon,
  TelegramShareButton,
  WhatsappIcon,
  WhatsappShareButton,
} from "react-share";
import { BsHeart, BsHeartFill, BsPlus } from "react-icons/bs";
import Cookies from "universal-cookie";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { t } from "i18next";

const ProductShowcase = (props) => {
  const {
    carouselImages,
    addtoCart,
    removeFromCart,
    placeHolderImage,
    productBrand,
    // setmainimage,
    selectedVariant,
    productdata,
    favorite,
    addToFavorite,
    removefromFavorite,
    setSelectedVariant,
    handleVariantChange,
    setselectedvariantImage,
    image_to_url,
    type
  } = props;
  const [selected, setSelected] = useState(0);
  const [disabled, setDisabled] = useState(false);
  const setting = useSelector((state) => state.setting);
  const [mainimage,setmainimage] = useState(carouselImages[0])
  const cookies = new Cookies() ;

  const onChangeHandler = (value) => {
    setSelected(value);
  };

  useEffect(() => {
    if (selectedVariant?.cart_count > 0 || Number(selectedVariant.stock) == 0) {
      setDisabled(true);
    } else {
      setDisabled(false);
    }

  }, [selectedVariant]);

  return (
    <div className="flex justify-center gap-x-52 items-start flex-wrap">
      <section className="flex items-center gap-x-40">
        <div>
          <Carousel
            onChange={onChangeHandler}
            setmainimage={setmainimage}
            placeHolderImage={placeHolderImage}
            carouselImages={carouselImages}
          />
        </div>
        <div className="flex flex-col items-center gap-y-20">
          <img
            onError={placeHolderImage}
            src={carouselImages[selected]}
            alt="main-product"
            className="col-12"
            style={{ width: "50rem" }}
          />
          <div className="flex items-center gap-x-20 mt-20">
            {
            productdata.variants.length > 1  && productdata.variants.map((product,index)=>(
                <div onClick={()=>{setSelectedVariant(product);setselectedvariantImage([image_to_url(product.variant_image),...product.images])}} className={classNames("w-40 h-40 flex items-center justify-center  rounded-lg ",selectedVariant.id == product.id && 'border border-gray-500')}>
                  <img src={image_to_url(product.variant_image)} className="w-28 object-cover" />
                </div>
              ))
            }
        </div>
        </div>
      </section>
      <section>
        <div className="flex gap-x-10 ">
          <h1 className="text-[5rem] text-black font-bold max-w-[60rem]">
            {selectedVariant?.variant_name}
          </h1>
         {
         selectedVariant?.stock !== null && Number(selectedVariant?.stock) > 0 ?  <div className="p-2 h-fit mt-3 bg-blue-300 text-blue-800 text-[2rem] font-bold w-fit px-3 rounded-lg">
          In Stock
        </div> :  <div className="p-2 h-fit mt-3 bg-red-300 text-red-800 text-[2rem] font-bold w-fit px-3 rounded-lg">
            Out of stock
          </div>
         }
        </div>
        <div className="flex items-center gap-x-5">
          <div>
            <Rating />
          </div>
          <p className="text-[2rem] mt-3">4 Review</p>
         {Number(productdata.is_unlimited_stock) === 0 && <div className="flex items-center mt-2 gap-x-5">
            <h3 className="font-bold text-[2rem]">$KU:</h3>
            <p className=" text-[2rem] mt-2">{selectedVariant.stock.split('.')[0]}</p>
          </div>}
        </div>
        <div className="flex items-center gap-x-5">
          {setting && selectedVariant && productdata && (
            <div
              id="price-section"
              className="d-flex flex-row gap-2 flex items-center  gap-x-4 text-[3rem] text-[#0199e3] font-bold align-items-center my-1"
            >
              {setting?.setting && setting?.setting?.currency}
              <p className="m-0 text-[#0199e3]">
                {selectedVariant
                  ? selectedVariant?.discounted_price == 0
                    ? parseFloat(selectedVariant?.price).toFixed(
                        setting?.setting && setting?.setting?.decimal_point
                      )
                    : parseFloat(selectedVariant?.discounted_price).toFixed(
                        setting?.setting && setting?.setting?.decimal_point
                      )
                  : productdata?.variants[0]?.discounted_price === 0
                  ? productdata?.variants[0]?.price?.toFixed(
                      setting?.setting && setting?.setting?.decimal_point
                    )
                  : productdata?.variants[0]?.discounted_price?.toFixed(
                      setting?.setting && setting?.setting?.decimal_point
                    )}
              </p>
<div className="flex items-center gap-x-2 mt-3 mx-4 line-through text-[2rem]">
  <p>
  {setting?.setting && setting?.setting?.currency}

  </p>
<p>{selectedVariant.price}</p>

  </div>            
            </div>
          )}
          {selectedVariant && (
            <div className="text-[1.6rem] font-semibold rounded-full px-3 py-2  h-fit bg-red-200 text-red-500 w-fit">
              {
                (
                  ((selectedVariant.price - selectedVariant.discounted_price) /
                    selectedVariant.price) *
                  100
                )
                  .toString()
                  .split(".")[0]
              }
              % Off
            </div>
          )}
        </div>
        <div className="w-[70rem] border border-gray-300 h-[1px] mt-4" />
        <div className="w-[70rem] flex items-center justify-between pt-4">
          {productBrand && (
            <div>
              <h1 className="text-[2rem] font-medium">Brand : </h1>
              {productBrand?.map((item, key) => (
                <img
                  src={item?.image_url}
                  width={50}
                  height={50}
                  className="object-cover"
                />
              ))}
            </div>
          )}
          <div className="flex items-center">
            <h1>Share item:</h1>
            <ul className="share-product flex items-center gap-x-4">
              <li className="share-product-icon">
                <WhatsappShareButton
                  url={`${
                    setting.setting && setting.setting.web_settings.website_url
                  }product/${productdata.slug}`}
                >
                  <WhatsappIcon size={32} round={true} />{" "}
                </WhatsappShareButton>
              </li>
              <li className="share-product-icon">
                <TelegramShareButton
                  url={`${
                    setting.setting && setting.setting.web_settings.website_url
                  }product/${productdata.slug}`}
                >
                  <TelegramIcon size={32} round={true} />{" "}
                </TelegramShareButton>
              </li>
              <li className="share-product-icon">
                <FacebookShareButton
                  url={`${
                    setting.setting && setting.setting.web_settings.website_url
                  }product/${productdata.slug}`}
                >
                  <FacebookIcon size={32} round={true} />{" "}
                </FacebookShareButton>
              </li>
              <li className="share-product-icon">
                <button
                  type="button"
                  onClick={() => {
                    navigator.clipboard.writeText(
                      `${
                        setting.setting &&
                        setting.setting.web_settings.website_url
                      }product/${productdata.slug}`
                    );
                    toast.success("Copied Succesfully!!");
                  }}
                >
                  {" "}
                  <BiLink size={30} />
                </button>
              </li>
            </ul>
          </div>
        </div>
        <p className="max-w-[60rem] text-[1.4rem] text-gray-500">
          Lorem ipsum dolor, sit amet consectetur adipisicing elit. Voluptates
          itaque magnam numquam quod, voluptatem inventore at, quibusdam nobis,
          voluptatum ipsa quas impedit saepe dolores dolor ad. Nam assumenda
          magnam debitis!
        </p>
        <div className="w-[70rem] border border-gray-300 h-[1px] mt-4" />
        <div className="my-5 flex items-center gap-x-5 justify-between">
          <CartSection
            removeFromCart={removeFromCart}
            setting={setting}
            product={productdata}
            variant={selectedVariant}
            addtoCart={addtoCart}
          />
          <button
            disabled={disabled}
            onClick={() => addtoCart(productdata.id, selectedVariant.id, 1)}
            className={classNames(
              "min-w-max h-24 rounded-full bg-[#0199e3] flex items-center gap-x-3 text-[2rem] px-[15rem] font-bold fill-white text-white",
              disabled && "bg-gray-500"
            )}
          >
           {
            selectedVariant?.cart_count > 0 ? "Added to cart" : " Add to Cart"
           }
          </button>
          {favorite.favorite &&
          favorite.favorite.data.some(
            (element) => element.id === productdata.id
          ) ? (
            <button
              type="button"
              className="wishlist-product h-20 w-20 flex items-center bg-blue-200 rounded-full justify-center"
              onClick={() => {
                if (cookies.get("jwt_token") !== undefined) {
                  removefromFavorite(productdata.id);
                } else {
                  toast.error(t("required_login_message_for_cart"));
                }
              }}
            >
              <BsHeartFill size={16} fill="#0199e3" />
            </button>
          ) : (
            <button
            
              key={productdata.id}
              type="button" 
              className="wishlist-product h-20 w-20 rounded-full flex items-center justify-center bg-blue-200"
              onClick={() => {
                if (cookies.get("jwt_token") !== undefined) {
                  addToFavorite(productdata.id);
                } else {
                  toast.error(t("required_login_message_for_cart"));
                }
              }}
            >
              <BsHeart size={16} fill="#0199e3" />
            </button>
          )}
        </div>
        <div className="w-[70rem] border border-gray-300 h-[1px] my-4" />
        <div>
            <div className="flex items-center gap-x-4">
                <h1 className="text-[2rem] font-bold">Category:</h1>
                <p className="text-[2rem] text-gray-500 mt-2">Vegetables</p>
            </div>
            <div  className="flex items-center gap-x-4">
                <h1 className="text-[2rem] font-bold">Tag:</h1>
                <p className="text-[2rem] text-gray-500 mt-2">Vegetables Health</p>
                <p  className="text-[2rem] text-gray-500 mt-2">Healthy</p>
                <p className="text-[2rem] underline mt-2">Chinese</p>
                <p className="text-[2rem] text-gray-500 mt-2">Cabbage Green Cabbage</p>

            </div>
        </div>
      </section>
    </div>
  );
};

const CartSection = ({
  addtoCart,
  product,
  variant,
  setting,
  removeFromCart,
}) => {
  return (
    <>
      {product && variant && (
        <div className="border border-gray-500 p-2 flex w-[16rem] items-center justify-between rounded-full gap-x-3">
          <button
            className="rounded-full bg-gray-200 p-4"
            type="button"
            onClick={() => {
              if (Number(variant.cart_count) > 1) {
                addtoCart(
                  product.id,
                  variant.id,
                  Number(variant.cart_count) - 1
                );
              } else {
                removeFromCart(product.id, variant.id);
              }
            }}
          >
            <BiMinus className="fill-gray-700" fontSize={"2rem"} />
          </button>
          <span className="text-gray-600 text-[1.8rem]">
            {variant.cart_count}
          </span>
          <button
            className="rounded-full bg-gray-200 p-4"
            type="button"
            onClick={() => {
              if (Number(variant.is_unlimited_stock) === 1) {
                if (
                  Number(variant.cart_count) <
                  Number(setting.setting.max_cart_items_count)
                ) {
                  addtoCart(
                    product.id,
                    variant.id,
                    Number(variant.cart_count) + 1
                  );
                } else {
                  toast.error(
                    "Apologies, maximum product quantity limit reached!"
                  );
                }
              } else {
                if (Number(variant.cart_count) >= Number(variant.stock)) {
                  toast.error(t("out_of_stock_message"));
                } else if (
                  Number(variant.cart_count) >=
                  Number(setting.setting.max_cart_items_count)
                ) {
                  toast.error(
                    "Apologies, maximum product quantity limit reached!"
                  );
                } else {
                  addtoCart(
                    product.id,
                    variant.id,
                    Number(variant.cart_count) + 1
                  );
                }
              }
            }}
          >
            <BsPlus className="fill-gray-700" fontSize={"2rem"} />
          </button>
        </div>
      )}
    </>
  );
};

export default ProductShowcase;

const Rating = () => {
  return (
    <div className="flex gap-x-2 -mt-2">
      {Array.from({ length: 5 }).map((item) => (
        <img src={starIcon} width={15} height={15} className="" />
      ))}
    </div>
  );
};

const Carousel = (props) => {
  const {
    carouselImages: arr,
    placeHolderImage,
    setmainimage,
    onChange,
  } = props;
  const [selected, setSelected] = useState(0);

  const back = () => {
    onChange(selected > 0 ? selected - 1 : arr.length - 1);
    setSelected((prev) => (prev > 0 ? prev - 1 : arr.length - 1));
    setmainimage(selected > 0 ? arr[selected - 1] : arr[arr.length - 1]);
  };
  const next = () => {
    setSelected((prev) => prev < arr.length - 1 && prev + 1);
    setmainimage(selected < arr.length - 1 ? arr[selected + 1] : arr[0]);
    onChange(selected < arr.length - 1 ? selected + 1 : 0);
  };
  return (
    <div className="flex flex-col gap-y-5 items-center justify-center ">
      <div
        onClick={back}
        className="w-40 h-40 flex items-center justify-center"
      >
        <IoIosArrowUp
          className="fill-gray-400 mb-16 cursor-pointer"
          size={25}
        />
      </div>
      {arr?.map((item, key) => (
        <div
          className={classNames(
            "text-[2rem] flex items-center justify-center text-center "
          )}
          key={key}
        >
          <img
            onError={placeHolderImage}
            src={item}
            style={{border:key == selected ? '2px solid black' : ''}}
            className={classNames(
              "w-56 h-56 p-4 object-cover bg-cover",
              key == selected && "border border-blue-950 rounded-lg"
            )}
            alt="product"
            defaultValue={placeHolderImage}
            onClick={() => {
              onChange(key);
              setSelected(key);
              setmainimage(item);
            }}
          />
        </div>
      ))}
      <div
        onClick={next}
        className="w-40 h-40 flex items-center justify-center"
      >
        <IoIosArrowDown
          className="fill-gray-400 mt-16 cursor-pointer"
          size={25}
        />
      </div>
    </div>
  );
};
