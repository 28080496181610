import React, { useState, useEffect } from "react";
import "./footer.css";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import api from "../../api/api";
import { useTranslation } from "react-i18next";
// import { Link, useLocation, useNavigate } from "react-router-dom";
import fb from "../../assets/facebook.png";
import tw from "../../assets/Twitter.png";
import yt from "../../assets/youtube.png";
import mc from "../../assets/Mastercard.png";
import ap from "../../assets/applepay.png";
import disc from "../../assets/discover.png";
import sp from "../../assets/payment.png";
import visa from "../../assets/visa.png";
import { setCategory } from "../../model/reducer/categoryReducer";
import { setFilterCategory } from "../../model/reducer/productFilterReducer";

export const Footer = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const setting = useSelector((state) => state.setting);

  const { t } = useTranslation();

  const fetchCategory = (id = 0) => {
    api
      .getCategory(id)
      .then((response) => response.json())
      .then((result) => {
        if (result.status === 1) {
          dispatch(setCategory({data:result.data}))
          // dispatch({ type: ActionTypes.SET_CATEGORY, payload: result.data });
          // dispatch({ type: ActionTypes.SET_FOOTER_CATEGORY, payload: result.data });
        }
      })
      .catch((error) => console.log("error ", error));
  };

  useEffect(() => {
    fetchCategory();
  }, []);

  const category = useSelector((state) => state.category);

  const selectCategory = (ctg) => {
    if (ctg.has_child) {
      fetchCategory(ctg.id);
      navigate("/products");
    } else {
      dispatch(setFilterCategory({data:ctg.id}))
      // dispatch({ type: ActionTypes.SET_FILTER_CATEGORY, payload: ctg.id });
      navigate("/products");
    }
  };

  return (
    <section id="footer">
      {/* footer */}
      <div className="d-flex justify-content-around align-items-center footer1">
        <div className="col">
          <h5>About Shopery</h5>
          <span>Morbi cursus porttitor enim lobortis </span>
          <span>molestie. Duis gravida turpis dui,</span>
          <span>eget bibendum magna congue nec.</span>
          <span className="mt-5">Proxy@gmail.com</span>
        </div>
        <div className="col">
          <h5> My Account </h5>
          <span>
            {" "}
            <Link
              to="/profile"
              className="d-flex align-items-center flex-row user-profile gap-1"
            >
              My Account
            </Link>
          </span>
          <span>
            <Link
              to="/profile/orders"
              className="d-flex align-items-center flex-row user-profile gap-1"
            >
              Order History
            </Link>
          </span>
          <span>
            <Link
              to="/cart"
              className="d-flex align-items-center flex-row user-profile gap-1"
            >
              Shopping cart
            </Link>
          </span>
          <span>
            <Link
              to="/wishlist"
              className="d-flex align-items-center flex-row user-profile gap-1"
            >
              whishlist
            </Link>
          </span>
        </div>
        <div className="col">
          <h5>Helps</h5>
          <span>
            <Link
              to="/contact"
              className="d-flex align-items-center flex-row user-profile gap-1"
            >
              Contact
            </Link>
          </span>
          <span>
            <Link
              to="/faq"
              className="d-flex align-items-center flex-row user-profile gap-1"
            >
              Faqs
            </Link>
          </span>
          <span>
            <Link
              to="/terms"
              className="d-flex align-items-center flex-row user-profile gap-1"
            >
              Terms and Conditions
            </Link>
          </span>
          <span><Link
              to="/"
              className="d-flex align-items-center flex-row user-profile gap-1"
            >Privacy Policy</Link></span>
        </div>
        <div className="col">
          <h5>Proxy</h5>
          <span>
            <Link
              to="/about"
              className="d-flex align-items-center flex-row user-profile gap-1"
            >
              About
            </Link>
          </span>
          <span>
            <Link
              to="/products"
              className="d-flex align-items-center flex-row user-profile gap-1"
            >
              shop
            </Link>
          </span>
          <span>
            <Link
              to="/products"
              className="d-flex align-items-center flex-row user-profile gap-1"
            >
              Product
            </Link>
          </span>
        </div>
        <div className="col">
          <h5>Instagram</h5>
        </div>
      </div>
      {/* contact footer with logos */}
      <div className="footer2 d-flex align-items-center justify-content-around">
        <div className="d-flex align-items-center justify-content-center">
          <img src={fb} className="!w-[60px]" width={60} alt="facebook" />
          <img src={tw} className="!w-[60px]" width={60} alt="twitter" />
          <img src={yt} className="!w-[60px]" width={60} alt="youtube" />
        </div>
        <p>Shopery eCommerce © 2021. All Rights Reserved</p>
        <div></div>
        <div className="d-flex align-items-center justify-content-center gap-3">
          <img src={ap} className="image py-2 px-2 !w-[60px]" width={60} alt="logo" />
          <img src={visa} className="image py-3 !w-[56px] px-2" width={60} alt="logo" />
          <img src={disc} className="image py-4 !w-[50px] px-1" width={50} alt="logo" />
          <img src={mc} className="image !w-[54px]" height={36} alt="logo" />
          <img src={sp} className="image !w-[60px] py-2 px-1" width={60} alt="logo" />
        </div>
      </div>
    </section>
  );
};
