// import { ActionTypes } from "../action-type";
import { createSlice } from "@reduxjs/toolkit";


const initialState = {
    currentStage:1,
    search:null,
    service_id: null,
    category_id: null,
    provider_id:null,
    schedule:null,
    consult_type:'expert', //free,expert
    comm_type:"any", //communication type call,VC,chat,any
    language:"English",
    Name:null,
    age:null,
    email:null,
    gender:null,
    phone:null,
    notes:null,
    promocode:""
};

export const newAppointmentReducer = createSlice({
    name: "appointmentFilter",
    initialState,
    reducers: {
        setPromoCode:(state,action)=>{
            state.promocode = action.payload
        }
        ,
        setLanguage:(state,action)=>{
            state.language = action.payload
        }
        ,
        setProviderId: (state,action) => {
                state.provider_id = action.payload
        },
        setCurrentStageReducer:(state,action)=>{
            state.currentStage=action.payload.data;
        }
        ,
        setCategoryId:(state,action)=>{
            state.category_id = action.payload
        },
        setServiceId:(state,action)=>{
            state.service_id = action.payload
        },
        setServiceProviderSearch:(state,action)=>{
            state.search=action.payload.data;
        },
        SetName: (state, action) => {
            const currentName = state.Name ? state.Name.split(' ') : ["", ""];
            const firstname = action.payload.firstname !== undefined ? action.payload.firstname : currentName[0];
            const lastname = action.payload.lastname !== undefined ? action.payload.lastname : currentName[1];
            state.Name = `${firstname} ${lastname}`;
        },
        SetAge:(state,action)=>{
            state.age=action.payload;
        },
        SetEmail:(state,action)=>{
            state.email=action.payload;
        },
        SetPhone:(state,action)=>{
            state.phone=action.payload;
        },
        SetGender:(state,action)=>{
            state.gender=action.payload;
        },
        SetNotes:(state,action)=>{
            state.notes = action.payload
        }
        ,
        setServiceConsultType:(state,action)=>{
            state.consult_type=action.payload.data;
        },
        setFilterserviceCategory: (state, action) => {
            state.category_id = action.payload.data;
            state.service_id = null;
            state.search=null;
            state.provider_id=null;
            state.schedule=null;
            state.comm_type="any"; 
            state.consult_type=null;
            state.language="English";
            state.Name=null;
            state.phone=null;
            state.age=null;
            state.gender=null;
            state.email=null;
        },
        setFilterService: (state, action) => {
            state.service_id = action.payload.data;
            state.search=null;
            state.provider_id=null;
            state.schedule=null;
            state.comm_type="any";
            state.language="English";
        },
        resetAppointment:(state)=>{
                state.currentStage=1
                state.search=null
                state.service_id= null
                state.category_id= null
                state.provider_id=null
                state.schedule=null
                state.consult_type='expert' //free,expert
                state.comm_type="any" //communication type call,VC,chat,any
                state.language="English"
                state.Name=null
                state.age=null
                state.email=null
                state.gender=null
                state.phone=null
                state.notes=null
                state.promocode=""
        },
        
        // mode of communication
        setServiceCommunication:(state,action)=>{
            state.comm_type=action.payload;
            state.search=null; 
        },
        
        // schedule includes both date and time
        setServiceSechedule:(state,action)=>{
            state.schedule=action.payload;
        },
    }
});

export const {
    setPromoCode,
    SetNotes,
    setLanguage,
    setProviderId,
    resetAppointment,
    setCategoryId,
    setServiceId,
    setServiceConsultType,
    setCurrentStageReducer,
    setFilterserviceCategory,
    setServiceProviderSearch,
    setFilterService,
    setServiceSechedule,
    setServiceCommunication,
    SetName,
    SetAge,
    SetGender,
    SetPhone,
    SetEmail,
} = newAppointmentReducer.actions;

export default newAppointmentReducer.reducer;