import React, { useEffect, useState } from "react";
import headphoneIcon from "./headphones 1.svg";
import bagIcon from "./shopping-bag.svg";
import { GoArrowRight } from "react-icons/go";
import { useDispatch, useSelector } from "react-redux";
import { setService, setServiceCategory } from "../../model/reducer/serviceReducer";
import Service from "../ServiceComponents/Service";
import api from "../../api/api";
import Cookies from "universal-cookie";
import { resetAppointment, setCategoryId, setServiceId } from "../../model/reducer/newAppointmentReducer";
import classNames from "classnames";

const dataArr = [
  {
    label: "Weight loss Solution",
    desc: "Instant access to Contact",
    icon: headphoneIcon,
  },
  {
    label: "Diabetes Solution",
    desc: "Instant access to Contact",
    icon: bagIcon,
  },
  {
    label: "Mental Wellbeing",
    desc: "Instant access to Contact",
    icon: headphoneIcon,
  },
  {
    label: "Hair, skin Solution",
    desc: "Instant access to Contact",
    icon: headphoneIcon,
  },
];

const ProductSlider = () => {
  const dispatch = useDispatch();
  const cookies = new Cookies();
  const token = cookies.get("jwt_token");
  const handleService = (data) => {
    dispatch(resetAppointment())
    dispatch(setService({ data }));
    dispatch(setCategoryId(data.id))

  };

  const { service ,servicecategory } = useSelector((state) => state.service);

  const fetchServiceCategories = async () => {
    let response = await api.getServiceCategory(token);
    let result = await response.json();
    if(result.status === 1 ){
      dispatch(setServiceCategory({data:result.data}))
    }
  };

  useEffect(() => {
    if (token) {
      fetchServiceCategories();
    }
  }, [token]);

  if(token) return (
    <>
      <div className="px-[10vw] flex items-center bg-[#FAFAFA] py-24 gap-x-20 justify-center">
      <div className="p-3 border border-gray-600 rounded-full">
          <GoArrowRight size={20} className="rotate-180" />
        </div>
        {servicecategory && servicecategory.map((data, i) => (
          <div onClick={() => handleService(data)}>
            <Box key={i} icon={bagIcon} label={data.name} desc={""} />
          </div>
        ))}
        <div className="p-3 border border-gray-600 rounded-full">
          <GoArrowRight size={20} className="" />
        </div>
      </div>
      {service && <Service service={service} />}
    </>
  );
};

export default ProductSlider;

const Box = ({ icon, label, desc }) => {
  const [hover,setHover] = useState(false)
  return (
    <div onMouseOver={()=>setHover(true)} onMouseLeave={()=>setHover(false )} className="w-[25.625rem] bg-white cursor-pointer flex flex-col justify-center items-center rounded-[15px] hover:!bg-[#0199E3] gap-3 h-[18.875rem] border border-[#C8C1C1]">
      <div className="p-3 border border-gray-600 rounded-full bg-white">
        <img src={icon} alt="" width={28} />
      </div>
      <div className="text-center">
        <h2 className={classNames("text-[2rem] font-bold",hover && 'text-white')}>{label}</h2>
        <p className={classNames("text-gray-600 text-[1.5rem]",hover && 'text-white')}>{desc}</p>
      </div>
    </div>
  );
};
