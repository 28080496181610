import React, { useEffect, useState } from "react";
import "./product.css";

// import { FaRupeeSign } from "react-icons/fa";
import { toast } from "react-toastify";
import api from "../../api/api";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, Link, useParams } from "react-router-dom";
import Cookies from "universal-cookie";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import { useTranslation } from "react-i18next";
import Loader from "../loader/Loader";
import ProductShowcase from "./ProductShowcase";
import ProductsInformation from "./ProductsInformation";
import NewsSubscription from "../common/NewsSubscription";
import RelatedProduct from "./RelatedProduct";
import {
  clearSelectedProduct,
  setSelectedProduct,
} from "../../model/reducer/selectedProduct";
import { setCart } from "../../model/reducer/cartReducer";
import { setFavourite } from "../../model/reducer/favouriteReducer";

const ProductDetails = () => {
  function image_to_url(image) {
    return process.env.REACT_APP_API_URL + "/storage/" + image;
  }
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const cookies = new Cookies();
  const { slug } = useParams();

  const product = useSelector((state) => state.selectedProduct);
  const cart = useSelector((state) => state.cart);
  const city = useSelector((state) => state.city);
  const setting = useSelector((state) => state.setting);
  const [productLoading, setProductLoading] = useState(false);

  useEffect(() => {
    return () => {
      dispatch(clearSelectedProduct());
      // dispatch({ type: ActionTypes.CLEAR_SELECTED_PRODUCT, payload: null });
      setproductcategory({});
      setproductbrand({});
    };
  }, []);

  const [mainimage, setmainimage] = useState("");
  const [selectedvariantImage, setselectedvariantImage] = useState([]);
  const [images, setimages] = useState([]);
  const [productdata, setproductdata] = useState({});
  const [productSize, setproductSize] = useState({});
  const [productcategory, setproductcategory] = useState({});
  const [productbrand, setproductbrand] = useState({});
  const [relatedProducts, setrelatedProducts] = useState(null);
  const [loading, setisLoading] = useState(false);
  const [quantity, setQuantity] = useState([]);
  const [selectedVariant, setSelectedVariant] = useState(null);
  const [singleImage, setSingleImage] = useState(null);

  const getCategoryDetails = (id) => {
    api
      .getCategory()
      .then((response) => response.json())
      .then((result) => {
        if (result.status === 1) {
          result.data.forEach((ctg) => {
            if (ctg.id === id) {
              setproductcategory(ctg);
            }
          });
        }
      })
      .catch((error) => console.log(error));
  };

  const getBrandDetails = (id) => {
    api
      .getBrands()
      .then((response) => response.json())
      .then((result) => {
        if (result.status === 1) {
          result.data.forEach((brnd) => {
            if (brnd.id === id) {
              setproductbrand(brnd);
            }
          });
        }
      })
      .catch((error) => console.log(error));
  };

  const getProductDatafromApi = (id) => {
    setProductLoading(true);

    api
      .getProductbyId(
        city.city?.latitude,
        city.city?.longitude,
        id?.id ? id.id : product.selectedProduct_id,
        cookies.get("jwt_token")
      )
      .then((response) => response.json())
      .then((result) => {
        if (result.status === 1) {
          setProductLoading(false);
          setproductdata(result.data);

          // !variant_index && setVariantIndex(result.data.variants[0]?.id);
          // variant_index
          //   ? setSelectedVariant(
          //       result.data.variants?.length > 0 &&
          //         result.data.variants.find(
          //           (element) => element.id == variant_index
          //         )
          //     )
          //   :
          setSelectedVariant(result.data.variants[0]);
          setselectedvariantImage([
            result.data.image_url,
            ...result.data.variants[0].images,
          ]);
          setSingleImage(result.data.variants.images);
          let image = result.data.image_url;
          if (result.data.variants[0]?.variant_image) {
            image = image_to_url(result.data.variants[0]?.variant_image);
          }
          setmainimage(image);
          setimages(result.data.images);
          getCategoryDetails(result.data.category_id);
          getBrandDetails(result.data.brand_id);
          // setSelectedVariant(result.data.variants.find(element => element.id === selectedVariant.id));
        }
      })
      .catch((error) => console.log(error));
  };

  const getProductData = () => {
    api
      .getProductbyFilter(
        city.city?.id,
        city.city?.latitude,
        city.city?.longitude,
        { slug: slug },
        cookies.get("jwt_token")
      )
      .then((response) => response.json())
      .then((result) => {
        if (result.status === 1) {
          dispatch(setSelectedProduct({ data: result.data[0].id }));
          //  dispatch({
          //    type: ActionTypes.SET_SELECTED_PRODUCT,
          //    payload: result.data[0].id,
          //  });
          getProductDatafromApi({ id: result.data[0].id });
        } else {
        }
      })
      .catch((error) => console.log(error));
  };

  useEffect(() => {
    getProductData();
  }, [slug]);

  useEffect(() => {
    if (Object.keys(productdata).length !== 0) {
      api
        .getProductbyFilter(
          city.city?.id,
          city.city?.latitude,
          city.city?.longitude,
          {
            category_id: productdata.category_id,
          },
          cookies.get("jwt_token")
        )
        .then((response) => response.json())
        .then((result) => {
          if (result.status === 1) {
            setproductSize(result.sizes);
            setrelatedProducts(result.data);
          }
        })
        .catch((error) => console.log(error));
    }
  }, [productdata, cart]);

  useEffect(() => {
    getProductDatafromApi();
  }, [cart, slug]);

  const settings = {
    infinite: false,
    slidesToShow: 4,
    initialSlide: 0,
    prevArrow: (
      <button type="button" className="slick-prev">
        <FaChevronLeft size={30} className="prev-arrow" />
      </button>
    ),
    nextArrow: (
      <button type="button" className="slick-next">
        <FaChevronRight color="#f7f7f7" size={30} className="next-arrow" />
      </button>
    ),
    responsive: [
      {
        breakpoint: 1199,
        settings: {
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 425,
        settings: {
          slidesToShow: 1.5,
          dots: true,
          arrows: false,
        },
      },
    ],
  };
  const settings_subImage = {
    infinite: false,
    slidesToShow: 3,
    initialSlide: 0,
    // centerMargin: "10px",
    margin: "20px",
    prevArrow: (
      <button
        type="button"
        className="slick-prev"
        onClick={(e) => {
          setmainimage(e.target.value);
        }}
      >
        <FaChevronLeft size={30} className="prev-arrow" />
      </button>
    ),
    nextArrow: (
      <button
        type="button"
        className="slick-next"
        onClick={(e) => {
          setmainimage(e.target.value);
        }}
      >
        <FaChevronRight color="#f7f7f7" size={30} className="next-arrow" />
      </button>
    ),
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const [selectedProduct, setselectedProduct] = useState({});
  const [productSizes, setproductSizes] = useState(null);
  const [offerConatiner, setOfferContainer] = useState(0);
  const [variant_index, setVariantIndex] = useState(null);
  const [realted_variant_index, setRelatedVariantIndex] = useState(0);

  //for product variants dropdown in product card

  useEffect(() => {
    if (productdata && selectedVariant === null && productdata.variants) {
      setSelectedVariant(productdata.variants[0]);
    }
  }, [productdata, cart, slug]);

  //Add to Cart
  const addtoCart = async (product_id, product_variant_id, qty) => {
    setisLoading(true);
    await api
      .addToCart(cookies.get("jwt_token"), product_id, product_variant_id, qty)
      .then((response) => response.json())
      .then(async (result) => {
        if (result.status === 1) {
          toast.success(result.message);
          await api
            .getCart(
              cookies.get("jwt_token"),
              city.city?.latitude,
              city.city?.longitude
            )
            .then((resp) => resp.json())
            .then((res) => {
              if (res.status === 1) dispatch(setCart({ data: res }));
              // dispatch({ type: ActionTypes.SET_CART, payload: res });
            });
        } else {
          toast.error(result.message);
        }
        setisLoading(false);
      });
  };

  //remove from Cart
  const removefromCart = async (product_id, product_variant_id) => {
    setisLoading(true);
    await api
      .removeFromCart(cookies.get("jwt_token"), product_id, product_variant_id)
      .then((response) => response.json())
      .then(async (result) => {
        if (result.status === 1) {
          toast.success(result.message);
          await api
            .getCart(
              cookies.get("jwt_token"),
              city.city?.latitude,
              city.city?.longitude
            )
            .then((resp) => resp.json())
            .then((res) => {
              if (res.status === 1) dispatch(setCart({ data: res }));
              // dispatch({ type: ActionTypes.SET_CART, payload: res });
              else dispatch(setCart({ data: null }));
              //  dispatch({ type: ActionTypes.SET_CART, payload: null });
            });
        } else {
          toast.error(result.message);
        }
        setisLoading(false);
      });
  };

  //Add to favorite
  const addToFavorite = async (product_id) => {
    setisLoading(true);
    await api
      .addToFavotite(cookies.get("jwt_token"), product_id)
      .then((response) => response.json())
      .then(async (result) => {
        if (result.status === 1) {
          toast.success(result.message);
          await api
            .getFavorite(
              cookies.get("jwt_token"),
              city.city?.latitude,
              city.city?.longitude
            )
            .then((resp) => resp.json())
            .then((res) => {
              if (res.status === 1) dispatch(setFavourite({ data: res }));
              // dispatch({ type: ActionTypes.SET_FAVORITE, payload: res });
            });
        } else {
          toast.error(result.message);
        }
        setisLoading(false);
      });
  };
  const favorite = useSelector((state) => state.favourite);
  const removefromFavorite = async (product_id) => {
    setisLoading(true);
    await api
      .removeFromFavorite(cookies.get("jwt_token"), product_id)
      .then((response) => response.json())
      .then(async (result) => {
        if (result.status === 1) {
          toast.success(result.message);
          await api
            .getFavorite(
              cookies.get("jwt_token"),
              city.city?.latitude,
              city.city?.longitude
            )
            .then((resp) => resp.json())
            .then((res) => {
              if (res.status === 1) dispatch(setFavourite({ data: res }));
              // dispatch({ type: ActionTypes.SET_FAVORITE, payload: res });
              else dispatch(setFavourite({ data: null }));
              //  dispatch({ type: ActionTypes.SET_FAVORITE, payload: null });
            });
        } else {
          toast.error(result.message);
        }
        setisLoading(false);
      });
  };

  useEffect(() => {
    if (productdata.length > 0) {
      setSelectedVariant(productdata.varaints[0]);
    }
  }, [productdata, cart, slug]);

  useEffect(() => {
    // window.scrollTo({ top: 0, behavior: 'smooth' });
  }, [productdata]);

  const handleVariantChange = (variant, index) => {
    setSelectedVariant(variant);
    console.log(variant, "variant");
    setselectedvariantImage([...variant.images]);
    setVariantIndex(index);
  };

  const { t } = useTranslation();
  const placeHolderImage = (e) => {
    e.target.src = setting.setting?.web_logo;
  };
  return (
    <div className="bg-white pt-20 px-2">
      {loading && <Loader screen="full" background="none" />}
      {productdata && selectedVariant && !productLoading ? (
        <ProductShowcase
          type="showcase"
          setSelectedVariant={setSelectedVariant}
          removefromFavorite={removefromFavorite}
          addToFavorite={addToFavorite}
          favorite={favorite}
          removeFromCart={removefromCart}
          addtoCart={addtoCart}
          productbrand={productbrand}
          setting={setting}
          productdata={productdata}
          selectedVariant={selectedVariant}
          setmainimage={setmainimage}
          placeHolderImage={placeHolderImage}
          setselectedvariantImage={setselectedvariantImage}
          image_to_url={image_to_url}
          carouselImages={[...selectedvariantImage]}
        />
      ) : (
        <div className="my-40">
          {" "}
          <Loader />
        </div>
      )}
      {productdata && selectedVariant && !productLoading ? (
        <ProductsInformation
          productdata={productdata}
          selectedVariant={selectedVariant}
        />
      ) : (
        <div className="my-40">
          {" "}
          <Loader />
        </div>
      )}
      {relatedProducts === null ? (
        <div className="d-flex justify-content-center">
          <div className="spinner-border" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      ) : (
        <RelatedProduct
          setmainimage={setmainimage}
          setting={setting}
          productbrand={productbrand}
          removefromFavorite={removefromFavorite}
          addToFavorite={addToFavorite}
          favorite={favorite}
          removefromCart={removefromCart}
          addtoCart={addtoCart}
          relatedProducts={relatedProducts}
        />
      )}
      <div className="my-20">
        <NewsSubscription />
      </div>
    </div>
  );
};

export default ProductDetails;
