import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import "./profile.css";
import { ActionTypes } from '../../model/action-type';

import coverImg from "../../utils/cover-img.jpg";
import { FaUserCircle, FaListAlt, FaHome, FaEdit } from "react-icons/fa";
import { GoChecklist } from "react-icons/go";
import { IoIosArrowForward, IoMdLogOut } from "react-icons/io";
import { AiFillDelete, AiOutlineCloseCircle } from "react-icons/ai";
import Cookies from "universal-cookie";
import { toast } from "react-toastify";
import Order from "../order/Order";
import Address from "../address/Address";
import Transaction from "../transaction/Transaction";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import {
  removelocalstorageOTP,
  gelocalstoragetOTP,
} from "../../utils/manageLocalStorage";
import api from "../../api/api";
import { useTranslation } from "react-i18next";
import FirebaseData from "../../utils/firebase/FirebaseData";
import { logoutAuth, setCurrentUser } from "../../model/reducer/authReducer";
import { setFilterBrands, setFilterCategory, setFilterSearch } from "../../model/reducer/productFilterReducer";
import { setCart } from "../../model/reducer/cartReducer";
import { setFavourite } from "../../model/reducer/favouriteReducer";

const ProfileDashboard = (props) => {
  //initialize Cookies
  const cookies = new Cookies();

  const urlParams = useParams();

  const { firebase } = FirebaseData();

  const setting = useSelector((state) => state.setting);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const closeCanvas = useRef(null);
  const user = useSelector((state) => state.user);
  const [profileClick, setprofileClick] = useState(true);
  const [orderClick, setorderClick] = useState(false);

  useEffect(() => {
    if (user.status === "loading") {
      navigate("/");
    }

    if (props.showOrders) {
      setprofileClick(false);
      setorderClick(true);
    } else if (props.showTransaction) {
      setprofileClick(false);
      settransactionClick(true);
    } else if (props.showAddress) {
      setprofileClick(false);
      setaddressClick(true);
    }
  }, [user]);

  const [addressClick, setaddressClick] = useState(false);
  const [transactionClick, settransactionClick] = useState(false);
  const [logoutClick, setLogoutClick] = useState(false);
  const [deleteaccountClick, setDeleteaccountClick] = useState(false);
  const [username, setusername] = useState(user.user && user.user.name);
  const [useremail, setuseremail] = useState(user.user && user.user.email);
  const [selectedFile, setselectedFile] = useState();
  const [isupdating, setisupdating] = useState(false);

  const getCurrentUser = (token) => {
    api
      .getUser(token)
      .then((response) => response.json())
      .then((result) => {
        if (result.status === 1) {
          dispatch(setCurrentUser({data:result.user}))
          // dispatch({
          //   type: ActionTypes.SET_CURRENT_USER,
          //   payload: result.user,
          // });
          setisupdating(false);
        }
      });
  };
  
  const deleteCookies=()=> {
    var cookies = document.cookie.split("; ");

    for (var c = 0; c < cookies.length; c++) {
        var cookieParts = cookies[c].split("=");
        var cookieName = cookieParts[0];
        document.cookie = encodeURIComponent(cookieName) + "=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/";
    }
}

  const handleUpdateUser = async (e) => {
    e.preventDefault();

    if (selectedFile) {
      if (
        selectedFile.type === "image/png" ||
        selectedFile.type === "image/jpg" ||
        selectedFile.type === "image/jpeg"
      ) {
        setisupdating(true);
        if (cookies.get("jwt_token") !== undefined) {
          await api
            .edit_profile(
              username,
              useremail,
              selectedFile,
              cookies.get("jwt_token")
            )
            .then((response) => response.json())
            .then((result) => {
              if (result.status === 1) {
                getCurrentUser(cookies.get("jwt_token"));
              } else {
                toast.error(result.message);
                setisupdating(false);
              }
            })
            .catch((error) => {
              toast.error(error);
            });
        }
      } else {
        toast.error("File Type Not Allowed");
      }
    } else {
      setisupdating(true);
      if (cookies.get("jwt_token") !== undefined) {
        await api
          .edit_profile(
            username,
            useremail,
            selectedFile,
            cookies.get("jwt_token")
          )
          .then((response) => response.json())
          .then((result) => {
            if (result.status === 1) {
              getCurrentUser(cookies.get("jwt_token"));
            } else {
              toast.error(result.message);
              setisupdating(false);
            }
          })
          .catch((error) => {
            toast.error(error);
          });
      }
    }
    // setuseremail("")
    // setselectedFile()
    // setusername("")
  };


  const handleLogout = () => {
    confirmAlert({
      title: t("logout_title"),
      message: t("logout_message"),
      buttons: [
        {
          label: t("Ok"),
          onClick: async () => {
            await api
              .logout(cookies.get("jwt_token"))
              .then((response) => response.json())
              .then((result) => {
                if (result.status === 1) {
                  cookies.remove("jwt_token");
                  localStorage.clear()
                  removelocalstorageOTP();
                  dispatch(setFilterBrands({data:[]}))
                  // dispatch({
                  //   type: ActionTypes.SET_FILTER_BRANDS,
                  //   payload: [],
                  // });
                  dispatch(setCart({data:[]})) 
                  // dispatch({
                  //   type: ActionTypes.EMPTY_CART,
                  //   payload: [],
                  // });
                  dispatch(setFavourite({data:[]}))
                  // dispatch({
                  //   type: ActionTypes.EMPTY_FAVORITE,
                  //   payload: [],
                  // });
                  dispatch(setFilterCategory({data:null}))
                  // dispatch({
                  //   type: ActionTypes.SET_FILTER_CATEGORY,
                  //   payload: null,
                  // });
                  dispatch(setFilterSearch({data:null}))
                  // dispatch({
                  //   type: ActionTypes.SET_FILTER_SEARCH,
                  //   payload: null,
                  // });
                  dispatch(setFilterSearch({data:null}))
                  // dispatch({
                  //   type: ActionTypes.SET_FILTER_SECTION,
                  //   payload: null,
                  // });
                  toast.success("You're Successfully Logged Out");
                  deleteCookies();
                  // dispatch({ type: ActionTypes.LOGOUT_AUTH, payload: null });
                  dispatch(logoutAuth())
                  dispatch({ type: ActionTypes.LOGOUT_AUTH, payload: null });
                  navigate("/");
                } else {
                  toast.info(result.message);
                }
              });
          },
        },
        {
          label: t("Cancel"),
          onClick: () => {
            setLogoutClick(false)
          },
        },
      ],
    });
  };

  const handleDeleteAcount = () => {
    confirmAlert({
      title: t("DeleteUserAccount"),
      message: t(`delete_user_message`),
      buttons: [
        {
          label: t("Ok"),
          onClick: async () => {
            await api
              .deleteAccount(cookies.get("jwt_token"), gelocalstoragetOTP())
              .then((response) => response.json())
              .then((result) => {
                if (result.status === 1) {
                  cookies.remove("jwt_token");
                  let user = firebase.auth().currentUser;
                  user.delete().then((response) => {});
                  dispatch(setFilterBrands({data:[]}))
                  // dispatch({
                  //   type: ActionTypes.SET_FILTER_BRANDS,
                  //   payload: [],
                  // });
                  dispatch(setFilterCategory({data:null}))
                  // dispatch({
                  //   type: ActionTypes.SET_FILTER_CATEGORY,
                  //   payload: null,
                  // });
                  dispatch(setFilterSearch({data:null}))
                  // dispatch({
                  //   type: ActionTypes.SET_FILTER_SEARCH,
                  //   payload: null,
                  // });
                  dispatch(setFilterSearch({data:null}))
                  // dispatch({
                  //   type: ActionTypes.SET_FILTER_SECTION,
                  //   payload: null,
                  // });
                  dispatch(logoutAuth())
                  // dispatch({ type: ActionTypes.LOGOUT_AUTH, payload: null });
                  toast.info("You're Account is Succesfully Deleted!!");
                  navigate("/");
                } else {
                  toast.info(result.message);
                }
              });
          },
        },
        {
          label: t("Cancel"),
          onClick: () => {
            setDeleteaccountClick(false)
          },
        },
      ],
    });
  };

  const { t } = useTranslation();

  const profileNav = () => {
    const placeHolderImage = (e) => {
      e.target.src = setting.setting?.web_logo;
    };
    return (
      <>
        {isupdating ? (
          <div className="d-flex justify-content-center">
            <div className="spinner-border" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          </div>
        ) : (
          <div className="basicInfo-container">
            <div className="image-container">
              <img
                onError={placeHolderImage}
                src={user.user && user.user.profile}
                alt="logo"
              ></img>
              <div className="button-container-badge">
                <label htmlFor="file">
                  <span className="badge-img">
                    <FaEdit size={25} fill="var(--secondary-color)" />
                  </span>
                </label>
              </div>
            </div>
            <p>{user.user.name.split(" ")[0]}</p>
            <span>{user.user.email}</span>
          </div>
        )}

        <div className="navigation-container">
          <button
            type="button"
            className={`navigation-container-button ${
              profileClick ? 'selected' : ''
            }`}
            onClick={() => {
              setprofileClick(true);
              navigate("/profile");
              setaddressClick(false);
              setLogoutClick(false);
              setDeleteaccountClick(false)
              setorderClick(false);
              settransactionClick(false);
              closeCanvas.current.click();
            }}
          >
            <span>
              <FaUserCircle
                size={35}
                className="mx-3"
                fill={"var(--secondary-color)"}
              />
              {t("profile")}
            </span>
            <IoIosArrowForward className="profile-navigate-arrow" />
          </button>
          <button
            type="button"
            className={`navigation-container-button ${
              orderClick ? 'selected' : ''
            }`}
            onClick={() => {
              setprofileClick(false);
              setaddressClick(false);
              setLogoutClick(false);
              setDeleteaccountClick(false)
              setorderClick(true);
              settransactionClick(false);
              setisupdating(false);
              navigate("/profile/orders");
              closeCanvas.current.click();
            }}
          >
            <span>
              <FaListAlt
                size={35}
                className="mx-3"
                fill={"var(--secondary-color)"}
              />
              {t("all_orders")}
            </span>
            <IoIosArrowForward className="profile-navigate-arrow" />
          </button>
          <button
            type="button"
            className={`navigation-container-button ${
              addressClick ? 'selected' : ''
            }`}
            onClick={() => {
              setprofileClick(false);
              navigate("/profile/address");
              setaddressClick(true);
              setLogoutClick(false);
              setDeleteaccountClick(false)
              setorderClick(false);
              settransactionClick(false);
              setisupdating(false);
              closeCanvas.current.click();
            }}
          >
            <span>
              <FaHome
                size={35}
                className="mx-3"
                fill={"var(--secondary-color)"}
              />
              {t("manage_address")}
            </span>
            <IoIosArrowForward className="profile-navigate-arrow" />
          </button>
          <button
            type="button"
            className={`navigation-container-button ${
              transactionClick ? "selected" : ""
            }`}
            onClick={() => {
              navigate("/profile/transactions");
              setprofileClick(false);
              setaddressClick(false);
              setorderClick(false);
              setLogoutClick(false);
              setDeleteaccountClick(false)
              settransactionClick(true);
              setisupdating(false);
              closeCanvas.current.click();
              // navigate('/profile/address')

              if (window.innerWidth < 768)
                document
                  .getElementsByClassName("sidebar")[0]
                  .classList.toggle("selected");
            }}
          >
            <span>
              <GoChecklist
                size={35}
                className="mx-3"
                fill={"var(--secondary-color)"}
              />
              {t("transaction_history")}
            </span>
            <IoIosArrowForward className="profile-navigate-arrow" />
          </button>
          <button
            type="button"
            className={`navigation-container-button ${
               logoutClick? 'selected' : ''
            }`}
            onClick={()=>{
              setprofileClick(false);
              setaddressClick(false);
              setorderClick(false);
              setLogoutClick(true);
              setDeleteaccountClick(false)
              settransactionClick(false);
              handleLogout()}}
          >
            <span>
              <IoMdLogOut
                size={35}
                className="mx-3"
                fill={"var(--secondary-color)"}
              />
              {t("logout")}
            </span>
            <IoIosArrowForward className="profile-navigate-arrow" />
          </button>
          <button
            type="button"
            className={`navigation-container-button ${
              deleteaccountClick ? 'selected' : ''
            }`}
            onClick={()=>{
              setprofileClick(false);
              setaddressClick(false);
              setorderClick(false);
              setLogoutClick(false);
              setDeleteaccountClick(true)
              settransactionClick(false);
              handleDeleteAcount()}}
          >
            <span>
              <AiFillDelete
                size={35}
                className="mx-3"
                fill={"var(--secondary-color)"}
              />
              {t("delete_account")}
            </span>
            <IoIosArrowForward className="profile-navigate-arrow" />
          </button>
        </div>
      </>
    );
  };
  return (
    <>
      {user.status === "loading" ? (
        <div className="d-flex justify-content-center">
          <div className="spinner-border" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      ) : (
        <section id="profile">
          <div
            className="hide-desktop offcanvas offcanvas-start"
            tabIndex="-1"
            id="profilenavoffcanvasExample"
            aria-labelledby="profilenavoffcanvasExampleLabel"
            data-bs-backdrop="false"
          >
            <div className="canvas-header">
              <div className="site-brand">
                <img
                  src={setting.setting && setting.setting.web_settings.web_logo}
                  height="50px"
                  alt="logo"
                ></img>
              </div>

              <button
                type="button"
                ref={closeCanvas}
                className="close-canvas"
                data-bs-dismiss="offcanvas"
                aria-label="Close"
                onClick={() => {
                  document
                    .getElementsByClassName("profile-account")[0]
                    ?.classList.remove("selected");
                }}
              >
                <AiOutlineCloseCircle />
              </button>
            </div>
            <div className="sidebar">{profileNav()}</div>
          </div>

          <div className="cover">
            <img src={coverImg} className="img-fluid" alt="cover"></img>
          </div>
          <div className="container py-5">
            <div className="content-container row">
              <div className="sidebar hide-mobile-screen col-3">
                {profileNav()}
              </div>
              <div className="table-content col-md-9   ">
                <h4>{t("profile_info")}</h4>

                {profileClick ? (
                  // <ProfileContent isupdating={isupdating} setisupdating={setisupdating} />
                  <>
                    <div className="d-flex flex-column">
                      <div className="heading">{t("profile")}</div>
                      <div className="actual-content my-5">
                        {user.status === "loading" ? (
                          <div className="d-flex justify-content-center">
                            <div className="spinner-border" role="status">
                              <span className="visually-hidden">
                                Loading...
                              </span>
                            </div>
                          </div>
                        ) : (
                          <form onSubmit={handleUpdateUser}>
                            <div className="inputs-container">
                              <input
                                type="text"
                                placeholder="user name"
                                value={username}
                                onChange={(e) => {
                                  setusername(e.target.value);
                                }}
                                required
                              />
                              <input
                                type="email"
                                placeholder="email address"
                                value={useremail}
                                onChange={(e) => {
                                  setuseremail(e.target.value);
                                }}
                                required
                              />
                              <input
                                type="tel"
                                placeholder="mobile number"
                                value={user.user.mobile}
                                readOnly
                                style={{ color: "var(--sub-text-color)" }}
                              />
                              {/* accept={'image/*'} */}
                              <input
                                type="file"
                                id="file"
                                name="file"
                                onChange={(e) => {
                                  setselectedFile(e.target.files[0]);
                                }}
                                accept="image/png, image/jpeg, image/jpg"
                              />
                            </div>
                            <button
                              whileTap={{ scale: 0.8 }}
                              type="submit"
                              disabled={isupdating}
                            >
                              {t("update_profile")}
                            </button>
                          </form>
                        )}
                      </div>
                    </div>
                  </>
                ) : null}

                {orderClick ? <Order /> : null}

                {transactionClick ? <Transaction /> : null}

                {addressClick ? <Address /> : null}
              </div>
            </div>
          </div>
        </section>
      )}
    </>
  );
};

export default ProfileDashboard;
