import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import api from "../../api/api";
import Pagination from "react-js-pagination";
import { toast } from "react-toastify";
import Cookies from "universal-cookie";
import { MdOutlineKeyboardArrowRight } from "react-icons/md";
import { MdKeyboardArrowLeft } from "react-icons/md";
import NewsSubscription from "../common/NewsSubscription";
import filterSvg from "./Filter 24px.svg";
import Loader from "../loader/Loader";
import No_Orders from "../../utils/zero-state-screens/No_Orders.svg";
import { useTranslation } from "react-i18next";
import Filter from "./Filter";
import { Product } from "./RelatedProduct";
import classNames from "classnames";
import { setCategory } from "../../model/reducer/categoryReducer";
import {
  setFilterBrands,
  setFilterCategory,
  setFilterMinMaxPrice,
  setFilterSearch,
  setFilterSort,
} from "../../model/reducer/productFilterReducer";
import { setCart } from "../../model/reducer/cartReducer";
import { setFavourite } from "../../model/reducer/favouriteReducer";

const ProductList = () => {
  const total_products_per_page = 12;

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const cookies = new Cookies();

  const closeCanvas = useRef();
  const [productbrand, setproductbrand] = useState({});

  // const navigate = useNavigate();
  // let query = new URLSearchParams(useLocation().search)

  const category = useSelector((state) => state.category);
  const city = useSelector((state) => state.city);
  const filter = useSelector((state) => state.productFilter);
  const favorite = useSelector((state) => state.favourite);
  const setting = useSelector((state) => state.setting);
  const cart = useSelector((state) => state.cart);
  const share_parent_url = `${
    setting.setting && setting.setting.web_settings.website_url
  }product/`;

  const [brands, setbrands] = useState(null);
  const [productresult, setproductresult] = useState([]);
  const [selectedProduct, setselectedProduct] = useState({});
  const [minmaxTotalPrice, setminmaxTotalPrice] = useState({
    total_min_price: null,
    total_max_price: null,
    min_price: 0,
    max_price: null,
  });
  const [offset, setoffset] = useState(0);
  const [totalProducts, settotalProducts] = useState(0);
  const [currPage, setcurrPage] = useState(1);
  // const [minPrice, setminPrice] = useState(null)
  // const [maxPrice, setmaxPrice] = useState(null)
  const [isLoader, setisLoader] = useState(false);
  const [selectedVariant, setSelectedVariant] = useState({});

  const getBrandsApi = () => {
    api
      .getBrands()
      .then((response) => response.json())
      .then((result) => {
        if (result.status === 1) {
          // result.data.forEach(brand => {

          //     api.getProductbyFilter(city.city.id, city.city.latitude, city.city.longitude, { brand_ids: brand.id }, cookies.get('jwt_token'))
          //         .then(resp => resp.json())
          //         .then(res => {
          //             if (res.status === 1) {
          //                 setBrandproductcountmap(new Map(brandproductcountmap.set(`brand${brand.id}`, res.total)))
          //             }
          //             else {
          //             }

          //         })
          //         .catch(error => console.log("error ", error))
          // });
          setbrands(result.data);
        } else {
        }
      })
      .catch((error) => console.log("error ", error));
  };

  const fetchCategory = (id = 0) => {
    setisLoader(true);
    api
      .getCategory(id)
      .then((response) => response.json())
      .then((result) => {
        if (result.status === 1) {
          dispatch(setCategory({ data: result.data }));
          // dispatch({ type: ActionTypes.SET_CATEGORY, payload: result.data });
          // dispatch({ type: ActionTypes.SET_FOOTER_CATEGORY, payload: result.data });
        }
        setisLoader(false);
      })
      .catch((error) => {
        setisLoader(false);
        console.log("error ", error);
      });
  };

  const getProductfromApi = () => {
    setproductresult(null);
    api
      .getProductbyFilter(
        city.city.id,
        city.city.latitude,
        city.city.longitude,
        {
          sort: filter.sort_filter,
          limit: total_products_per_page,
          offset: offset,
        },
        cookies.get("jwt_token")
      )
      .then((response) => response.json())
      .then((result) => {
        if (result.status === 1) {
          setminmaxTotalPrice({
            total_min_price: result.total_min_price,
            total_max_price: result.total_max_price,
            min_price: result.min_price,
            max_price: result.max_price,
          });
          setproductresult(result.data);
          settotalProducts(result.total);
        } else {
          setproductresult([]);
          settotalProducts(0);
        }
      });
  };
  useEffect(() => {
    if (city.city !== null && !selectedProduct.id) {
      getBrandsApi();

      if (
        filter.category_id === null &&
        filter.brand_ids.length === 0 &&
        filter.price_filter === null &&
        filter.search === null &&
        filter.section_id === null
      ) {
        getProductfromApi();
      } else {
        filter.price_filter?.min_price !== null &&
        filter.price_filter?.max_price !== null
          ? filterProductsFromApi({
              category_id: filter.category_id,
              brand_ids: filter.brand_ids.toString(),
              min_price: filter.price_filter?.min_price,
              max_price: filter.price_filter?.max_price,
              sort: filter.sort_filter,
              search: filter.search,
              limit: total_products_per_page,
              offset: offset,
            })
          : filterProductsFromApi({
              category_id: filter.category_id,
              brand_ids: filter.brand_ids.toString(),
              sort: filter.sort_filter,
              search: filter.search,
              limit: total_products_per_page,
              offset: offset,
            });
      }

      // else if (filter.brand_ids.length !== 0 && filter.category_id !== null && filter.price_filter !== null && filter.search !== null && filter.section_id !== null) {

      //     filterProductsFromApi({
      //         category_id: filter.category_id,
      //         brand_ids: filter.brand_ids.toString(),
      //         min_price: filter.price_filter.min_price,
      //         max_price: filter.price_filter.max_price,
      //         sort: filter.sort_filter,
      //         search: filter.search,
      //         limit: total_products_per_page,
      //         offset: offset,
      //     })
      // }
      // else if (filter.section_id) {
      //     filter.price_filter ?
      //         filterProductsFromApi({
      //             min_price: filter.price_filter.min_price,
      //             max_price: filter.price_filter.max_price,
      //             category_id: filter.category_id,
      //             sort: filter.sort_filter,
      //             limit: total_products_per_page,
      //             offset: offset,
      //             section_id: filter.section_id
      //         })
      //         :
      //         filterProductsFromApi({
      //             category_id: filter.category_id,
      //             sort: filter.sort_filter,
      //             limit: total_products_per_page,
      //             offset: offset,
      //             section_id: filter.section_id
      //         })

      // }
      // else if (filter.category_id !== null) {
      //     // filter.section_id = null;
      //     // dispatch({type:ActionTypes.SET_FILTER_SECTION, payload:null})
      //     filter.price_filter ?
      //         filterProductsFromApi({
      //             min_price: filter.price_filter.min_price,
      //             max_price: filter.price_filter.max_price,
      //             category_id: filter.category_id,
      //             sort: filter.sort_filter,
      //             limit: total_products_per_page,
      //             offset: offset,
      //         })
      //         :
      //         filterProductsFromApi({
      //             category_id: filter.category_id,
      //             sort: filter.sort_filter,
      //             limit: total_products_per_page,
      //             offset: offset,
      //         })
      // }
      // else if (filter.brand_ids.length !== 0) {
      //     // filter.section_id = null;
      //     // dispatch({type:ActionTypes.SET_FILTER_SECTION, payload:null})
      //     filter.price_filter ?
      //         filterProductsFromApi({
      //             min_price: filter.price_filter.min_price,
      //             max_price: filter.price_filter.max_price,
      //             brand_ids: filter.brand_ids.toString(),
      //             sort: filter.sort_filter,
      //             limit: total_products_per_page,
      //             offset: offset,
      //         })
      //         :
      //         filterProductsFromApi({
      //             brand_ids: filter.brand_ids.toString(),
      //             sort: filter.sort_filter,
      //             limit: total_products_per_page,
      //             offset: offset,
      //         })

      // }

      // else if (filter.search !== null) {
      //     // dispatch({type:ActionTypes.SET_FILTER_SECTION, payload:null})
      //     // filter.section_id = null;
      //     filterProductsFromApi({
      //         search: filter.search,
      //         sort: filter.sort_filter,
      //         limit: total_products_per_page,
      //         offset: offset,
      //     })
      // }
      // else {
      //     // filter.section_id = null;
      //     // dispatch({type:ActionTypes.SET_FILTER_SECTION, payload:null})
      //     filterProductsFromApi({
      //         min_price: filter.price_filter.min_price,
      //         max_price: filter.price_filter.max_price,
      //         sort: filter.sort_filter,
      //         limit: total_products_per_page,
      //         offset: offset,
      //     })
      // }
    } else {
      getBrandsApi();
      // setminmaxTotalPrice({
      //     total_min_price: 0,
      //     total_max_price: 0,
      //     min_price: 0,
      //     max_price: 0,
      // })
    }
  }, [filter, city, cart, offset]);

  //brands and their product count map

  //filters
  const filterProductsFromApi = async (filter) => {
    setproductresult(null);
    await api
      .getProductbyFilter(
        city.city.id,
        city.city.latitude,
        city.city.longitude,
        filter,
        cookies.get("jwt_token")
      )
      .then((response) => response.json())
      .then((result) => {
        if (result.status === 1) {
          setminmaxTotalPrice({
            total_min_price: result.total_min_price,
            total_max_price: result.total_max_price,
            min_price: result.min_price,
            max_price: result.max_price,
          });
          setproductresult(result.data);
          settotalProducts(result.total);
        } else {
          // setminmaxTotalPrice({
          //     total_min_price: 0,
          //     total_max_price: 0,
          //     min_price: 0,
          //     max_price: 0,
          // })
          setproductresult([]);
          settotalProducts(0);
        }
        setisLoader(false);
      })
      .catch((error) => console.log("error ", error));
  };

  //filter by brands
  const sort_unique_brand_ids = (int_brand_ids) => {
    if (int_brand_ids.length === 0) return int_brand_ids;
    int_brand_ids = int_brand_ids.sort(function (a, b) {
      return a * 1 - b * 1;
    });
    var ret = [int_brand_ids[0]];
    for (var i = 1; i < int_brand_ids.length; i++) {
      //Start loop at 1: arr[0] can never be a duplicate
      if (int_brand_ids[i - 1] !== int_brand_ids[i]) {
        ret.push(int_brand_ids[i]);
      }
    }
    return ret;
  };

  //onClick event of brands
  const filterbyBrands = (brand) => {
    setcurrPage(1);
    setoffset(0);
    // setSelectedBrands((prev) => [...prev, ...brand.id])
    var brand_ids = [...filter.brand_ids];

    if (brand_ids.includes(brand.id)) {
      brand_ids.splice(brand_ids.indexOf(brand.id), 1);
    } else {
      brand_ids.push(parseInt(brand.id));
    }

    const sorted_brand_ids = sort_unique_brand_ids(brand_ids);
    dispatch(setFilterBrands({ data: sorted_brand_ids }));
    // dispatch({
    //   type: ActionTypes.SET_FILTER_BRANDS,
    //   payload: sorted_brand_ids,
    // });
    api
      .getProductbyFilter(
        city.city.id,
        city.city.latitude,
        city.city.longitude,
        {
          sort: filter.sort_filter,
          brand_ids: sorted_brand_ids.toString(),
          limit: total_products_per_page,
          offset: offset,
        },
        cookies.get("jwt_token")
      )
      .then((response) => response.json())
      .then((result) => {
        if (result.status === 1) {
          setminmaxTotalPrice({
            total_min_price: result.total_min_price,
            total_max_price: result.total_max_price,
            min_price: result.min_price,
            max_price: result.max_price,
          });
          dispatch(
            setFilterMinMaxPrice({
              data: {
                min_price: result.min_price,
                max_price: result.max_price,
              },
            })
          );
          // dispatch({
          //   type: ActionTypes.SET_FILTER_MIN_MAX_PRICE,
          //   payload: {
          //     min_price: result.min_price,
          //     max_price: result.max_price,
          //   },
          // });

          setproductresult(result.data);
          settotalProducts(result.total);
        } else {
          setproductresult([]);
          settotalProducts(0);
        }
      });
  };

  //filter by category

  //onLClick event of category
  const filterbyCategory = (category) => {
    // if (category.has_child) {
    //   fetchCategory(category.id);
    // } else {
    setcurrPage(1);
    setoffset(0);
    if (filter.category_id === category.id) {
      dispatch(setFilterCategory({ data: null }));
      // dispatch({ type: ActionTypes.SET_FILTER_CATEGORY, payload: null });
    } else {
      dispatch(setFilterCategory({ data: category.id }));
      // dispatch({
      //   type: ActionTypes.SET_FILTER_CATEGORY,
      //   payload: category.id,
      // });
      // }
    }
  };

  const { t } = useTranslation();
  const placeHolderImage = (e) => {
    e.target.src = setting.setting?.web_logo;
  };

  const [modal, setModal] = useState(false);

  //page change
  const handlePageChange = (pageNum) => {
    setcurrPage(pageNum);
    setoffset(pageNum * total_products_per_page - total_products_per_page);
  };

  //Add to Cart
  const addtoCart = async (product_id, product_variant_id, qty) => {
    setisLoader(true);
    await api
      .addToCart(cookies.get("jwt_token"), product_id, product_variant_id, qty)
      .then((response) => response.json())
      .then(async (result) => {
        if (result.status === 1) {
          toast.success(result.message);
          await api
            .getCart(
              cookies.get("jwt_token"),
              city.city.latitude,
              city.city.longitude
            )
            .then((resp) => resp.json())
            .then((res) => {
              if (res.status === 1) dispatch(setCart({ data: res }));
              // dispatch({ type: ActionTypes.SET_CART, payload: res });
            });
        } else {
          toast.error(result.message);
        }
        setisLoader(false);
      });
  };

  //remove from Cart
  const removefromCart = async (product_id, product_variant_id) => {
    setisLoader(true);
    await api
      .removeFromCart(cookies.get("jwt_token"), product_id, product_variant_id)
      .then((response) => response.json())
      .then(async (result) => {
        if (result.status === 1) {
          toast.success(result.message);
          await api
            .getCart(
              cookies.get("jwt_token"),
              city.city.latitude,
              city.city.longitude
            )
            .then((resp) => resp.json())
            .then((res) => {
              if (res.status === 1) dispatch(setCart({ data: res }));
              // dispatch({ type: ActionTypes.SET_CART, payload: res });
              else dispatch(setCart({ data: null }));
              //  dispatch({ type: ActionTypes.SET_CART, payload: null });
            });
        } else {
          toast.error(result.message);
        }
        setisLoader(false);
      });
  };

  //Add to favorite
  const addToFavorite = async (product_id) => {
    setisLoader(true);
    await api
      .addToFavotite(cookies.get("jwt_token"), product_id)
      .then((response) => response.json())
      .then(async (result) => {
        if (result.status === 1) {
          toast.success(result.message);
          await api
            .getFavorite(
              cookies.get("jwt_token"),
              city.city.latitude,
              city.city.longitude
            )
            .then((resp) => resp.json())
            .then((res) => {
              setisLoader(false);
              if (res.status === 1) dispatch(setFavourite({ data: res }));
              // dispatch({ type: ActionTypes.SET_FAVORITE, payload: res });
            });
        } else {
          toast.error(result.message);
        }
        setisLoader(false);
      });
  };
  const removefromFavorite = async (product_id) => {
    await api
      .removeFromFavorite(cookies.get("jwt_token"), product_id)
      .then((response) => response.json())
      .then(async (result) => {
        if (result.status === 1) {
          toast.success(result.message);
          await api
            .getFavorite(
              cookies.get("jwt_token"),
              city.city.latitude,
              city.city.longitude
            )
            .then((resp) => resp.json())
            .then((res) => {
              if (res.status === 1) dispatch(setFavourite({ data: res }));
              // dispatch({ type: ActionTypes.SET_FAVORITE, payload: res });
              else dispatch(setFavourite({ data: null }));
              // dispatch({ type: ActionTypes.SET_FAVORITE, payload: null });
            });
        } else {
          toast.error(result.message);
        }
      });
  };

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, [productresult]);
  return (
    <>
      <section
        id="productlist"
        className="px-[15vw]"
        onContextMenu={() => {
          return false;
        }}
      >
        <div className="flex items-start justify-center" id="products">
          {/* filter section */}
          <div>
            <div className="flex items-center gap-x-10 max-w-[13vw]  justify-between">
              <div
                className={classNames(
                  "bg-[#0199E3] cursor-pointer mb-20  w-fit gap-x-4 px-4 py-3 rounded-full flex justify-center items-center"
                )}
              >
                <span className="text-[1.8rem] text-white font-bold">
                  Filter
                </span>{" "}
                <img className="w-10" src={filterSvg} alt="" />
              </div>
              <button
                onClick={() => {
                  dispatch(setFilterBrands({data:[]}))
                  // dispatch({
                  //   type: ActionTypes.SET_FILTER_BRANDS,
                  //   payload: [],
                  // });
                  dispatch(setFilterCategory({data:null}))
                  // dispatch({
                  //   type: ActionTypes.SET_FILTER_CATEGORY,
                  //   payload: null,
                  // });
                  dispatch(setFilterSearch({data:null}))
                  // dispatch({
                  //   type: ActionTypes.SET_FILTER_SEARCH,
                  //   payload: null,
                  // });
                  dispatch(setFilterSearch({data:null}))
                  // dispatch({
                  //   type: ActionTypes.SET_FILTER_SECTION,
                  //   payload: null,
                  // });
                  // fetchCategory();
                  getProductfromApi();
                }}
                className="-mt-20 text-[1.5rem] font-bold "
              >
                Clear filters
              </button>
            </div>

            <Filter
              placeHolderImage={placeHolderImage}
              filter={filter}
              filterbyBrands={filterbyBrands}
              brands={brands}
              minmaxTotalPrice={minmaxTotalPrice}
              filterbyCategory={filterbyCategory}
              setcurrPage={setcurrPage}
              category={category}
              setoffset={setoffset}
            />
          </div>

          {/* products according to applied filter */}
          <div
            className="d-flex flex-column col-md-9 col-12 h-100 productList_container"
            style={{ gap: "20px" }}
          >
            <div className="row">
              <div className="d-flex col-12 flex-row justify-content-between align-items-center filter-view">
                <div className="select flex items-center min-w-max gap-x-4">
                  <div className="w-40">Sort by:</div>
                  {totalProducts ? (
                    <select
                      className="form-select py-3 text-[2rem]"
                      aria-label="Default select example"
                      onChange={(e) => {
                        dispatch(setFilterSort({data:e.target.value}))
                        // dispatch({
                        //   type: ActionTypes.SET_FILTER_SORT,
                        //   payload: e.target.value,
                        // });
                      }}
                    >
                      <option value="new">Latest</option>
                      <option value="old">{t("oldest_first")}</option>
                      <option value="high">{t("high_to_low")}</option>
                      <option value="low">{t("low_to_high")}</option>
                      <option value="discount">
                        {t("discount_high_to_low")}
                      </option>
                      <option value="popular">{t("popularity")}</option>
                    </select>
                  ) : (
                    <></>
                  )}
                </div>
                <div className="d-flex gap-3">
                  {/* 3rd Phase feature - List View */}
                  {/* <div className={`icon ${!filter.grid_view ? 'active' : null}`} onClick={() => {
                                        dispatch({ type: ActionTypes.SET_FILTER_VIEW, payload: false });
                                    }}>
                                        <BsListUl fontSize={"2rem"} />
                                    </div>
                                    <div className={`icon ${filter.grid_view ? 'active' : null}`} onClick={() => {
                                        dispatch({ type: ActionTypes.SET_FILTER_VIEW, payload: true });
                                    }}>
                                        <BsGrid fontSize={"2rem"} />
                                    </div> */}
                  {totalProducts ? (
                    <span className="total_product_count flex items-center gap-x-3">
                      <div className="font-bold">{totalProducts} </div>{" "}
                      {t("products_found")}
                    </span>
                  ) : (
                    <></>
                  )}
                </div>
              </div>

              {productresult === null || isLoader ? (
                <Loader width="100%" height="200%" background="none" />
              ) : (
                <>
                  {productresult.length > 0 ? (
                    <div className="h-100 ">
                      <div className="flex flex-wrap gap-20 justify-between my-20 mb-40">
                        {productresult.map((product, index) => (
                          <Product
                            product={product}
                            addtoCart={addtoCart}
                            removefromCart={removefromCart}
                            removefromFavorite={removefromFavorite}
                            addToFavorite={addToFavorite}
                            favorite={favorite}
                            productbrand={productbrand}
                            setting={setting}
                          />
                        ))}
                      </div>

                      <div>
                        <Pagination
                          activePage={currPage}
                          itemsCountPerPage={total_products_per_page}
                          totalItemsCount={totalProducts}
                          pageRangeDisplayed={5}
                          onChange={handlePageChange.bind(this)}
                          firstPageText={""}
                          lastPageText={""}
                          prevPageText={
                            <MdKeyboardArrowLeft
                              size={35}
                              className=" p-2 bg-gray-200 rounded-full mx-20 fill-gray-400"
                            />
                          }
                          nextPageText={
                            <MdOutlineKeyboardArrowRight
                              size={35}
                              className=" p-2 bg-gray-200 rounded-full mx-20 fill-gray-400"
                            />
                          }
                        />
                      </div>
                    </div>
                  ) : (
                    <div className="no-product">
                      <img
                        onError={placeHolderImage}
                        src={No_Orders}
                        style={{ width: "40%" }}
                        alt="no-product"
                        className="img-fluid"
                      ></img>
                      <p>No Products Found</p>
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      </section>
      <div className="mt-40">
        <NewsSubscription />
      </div>
    </>
  );
};

export default ProductList;
