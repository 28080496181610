export const ActionTypes = {

    //CSS Mode
    SET_CSSMODE: "SET_CSSMODE",

    //language
    SET_LANGUAGE: "SET_LANGUAGE",
    SET_LANGUAGE_LIST:"SET_LANGUAGE_LIST",

    //Shop
    SET_SHOP: "SET_SHOP",

    //Sliders
    SET_SLIDERS: "SET_SLIDERS",

    //Location
    SET_CITY: "SET_CITY",

    //Categories
    SET_CATEGORY: "SET_CATEGORY",
    SET_FOOTER_CATEGORY: "SET_FOOTER_CATEGORY",

    //Authentication
    SET_CURRENT_USER: "SET_CURRENT_USER",
    LOGOUT_AUTH: "LOGOUT_AUTH",

    //product sizes
    SET_PRODUCT_SIZES: "SET_PRODUCT_SIZES",

    //product filters
    SET_FILTER_SEARCH: "SET_FILTER_SEARCH",
    SET_FILTER_SECTION: "SET_FILTER_SECTION",
    SET_FILTER_BRANDS: "SET_FILTER_BRANDS",
    SET_FILTER_CATEGORY: "SET_FILTER_CATEGORY",
    SET_FILTER_MIN_MAX_PRICE: "SET_FILTER_MIN_MAX_PRICE",
    SET_FILTER_VIEW: "SET_FILTER_VIEW",
    SET_FILTER_SORT: "SET_FILTER_SORT",
    SET_FILTER_PRODUCTS: "SET_FILTER_PRODUCTS",

    //select product
    SET_SELECTED_PRODUCT: "SET_SELECTED_PRODUCT",
    CLEAR_SELECTED_PRODUCT: "CLEAR_SELECTED_PRODUCT",


    //cart
    SET_CART: "SET_CART",
    EMPTY_CART: "EMPTY_CART",
    SET_CART_CHECKOUT: "SET_CART_CHECKOUT",
    SET_CART_PROMO: "SET_CART_PROMO",

    //favorites
    SET_FAVORITE: "SET_FAVORITE",
    EMPTY_FAVORITE: "EMPTY_FAVORITE",

    //notification
    SET_NOTIFICATION: "SET_NOTIFICATION",

    //setting
    SET_SETTING: "SET_SETTING",
    SET_PAYMENT_SETTING :"SET_PAYMENT_SETTING",

    //Address
    SET_ADDRESS:"SET_ADDRESS",
    SET_SELECTED_ADDRESS:"SET_SELECTED_ADDRESS",

    // new appointment
    SET_FILTER_SERVICE_CATEGORY: "SET_FILTER_SERVICE_CATEGORY",
    SET_FILTER_SERVICE: "SET_FILTER_SERVICE",
    SET_SERVICE_COMMUNICATION: "SET_SERVICE_COMMUNICATION",
    SET_SERVICE_SCHEDULE: "SET_SERVICE_SCHEDULE",
    SET_SERVICE_PROVIDER_SEARCH:"SET_SERVICE_PROVIDER_SEARCH",
    SET_NAME:"SET_NAME",
    SET_AGE:"SET_AGE",
    SET_EMAIL:"SET_EMAIL",
    SET_GENDER:"SET_GENDER",
    SET_PHONE:"SET_PHONE",
    SET_CONSULT_TYPE:"SET_CONSULT_TYPE",

    // service
    SET_SERVICE:"SET_SERVICE",
    SET_SERVICE_PROVIDER:"SET_SERVICE_PROVIDER",
    SET_SERVICE_CATEGORY:"SET_SERVICE_CATEGORY",


    // service provider
    SET_PROVIDER_SERACH:"SET_PROVIDER_SERACH",
    SET_PROVIDER_SORT:"SET_PROVIDER_SORT",
    SET_PROVIDER_VIEW:"SET_PROVIDER_VIEW",
    SET_PROVIDER_LANGUAGE:"SET_PROVIDER_LANGUAGE",
    SET_PROVIDER_BY_COUNTRY:"SET_PROVIDER_BY_COUNTRY",
}