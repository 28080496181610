import React, { useEffect, useRef, useState } from 'react';
import './order.css';
import api from '../../api/api';
import Cookies from 'universal-cookie';
import { AiOutlineCloseCircle } from 'react-icons/ai';
import Loader from '../loader/Loader';
import Pagination from 'react-js-pagination';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { ProgressBar, Tab, Tabs } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import OrderTracker from './OrderTracker';


const Order = () => {
    const { t } = useTranslation();
    const [NoOrders, setNoOrders] = useState(false);

    const [element, setElement] = useState({});
    const [totalActiveOrders, setTotalActiveOrders] = useState(null)
    const [totalPrevOrders, setTotalPrevOrders] = useState(null)
    const [ActiveOrders, setActiveOrders] = useState([])
    const [PrevOrders, setPrevOrders] = useState([])
    const [offset, setoffset] = useState(0)
    const [currPage, setcurrPage] = useState(1)
    const [isLoader, setisLoader] = useState(false)
    const [showTracker, setShowTracker] = useState(false)



    //initialize Cookies
    const cookies = new Cookies();
    const componentRef = useRef();
    const total_orders_per_page = 10;
    const navigate = useNavigate()

    const setting = useSelector(state => state.setting)
    const [orderId, setOrderId] = useState(null)
    function order_status(active_status) {
        if (active_status === "6") {
            return(t("delivered"))
        } else if (active_status === "5") {
            return(t("out_for_delivery"))
        } else if (active_status === "4") {
            return(t("shipped"))
        } else if (active_status === "3") {
            return(t("processed"))
        }
        else if (active_status === "7") {
            return(t("cancelled"))
        }
        else if (active_status === "8") {
            return(t("returned"))
        } else if (active_status === "1") {
            return(t("Payment pending"))
        } else if (active_status === "2") {
            return(t("Recevied"))
        }
    };

    const fetchOrders = async () => {
        await api.getOrders(cookies.get('jwt_token'), total_orders_per_page, offset)
            .then(response => response.json())
            .then(result => {
                if (result.status === 1) {
                    setisLoader(false);
                    setActiveOrders(result.data);
                    setTotalActiveOrders(result.total);
                }
                else if (result.message === "No orders found") {
                    setisLoader(false);
                    setNoOrders(true);
                }
            })
            await api.getOrders(cookies.get('jwt_token'), total_orders_per_page, offset, 0)
            .then(response => response.json())
            .then(result => {
                if (result.status === 1) {
                    setisLoader(false);
                    setPrevOrders(result.data);
                    setTotalPrevOrders(result.total);
                }
                else if (result.message === "No orders found") {
                    setisLoader(false);
                    setNoOrders(true);
                }
            })
        }
        
        useEffect(() => {
            setisLoader(true)
            fetchOrders()
        }, [offset])
        
        //page change
        const handlePageChange = (pageNum) => {
            setcurrPage(pageNum);
            setoffset(pageNum * total_orders_per_page - total_orders_per_page)
        }
        
        
        const getInvoice = async (Oid) => {
            setisLoader(true)
            let postData = new FormData()
            postData.append('order_id', Oid)
            axios({
                url: `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_SUBURL}/invoice_download`,
                method: 'post',
                responseType: 'blob',
                /*responseType: 'application/pdf',*/
                data: postData,
                headers: {
                    Authorization: `Bearer ${cookies.get('jwt_token')}`
                }
            }).then(response => {
                var fileURL = window.URL.createObjectURL(new Blob([response.data]));
                var fileLink = document.createElement('a');
                fileLink.href = fileURL;
                fileLink.setAttribute('download', 'Invoice-No:' + Oid + '.pdf');
                document.body.appendChild(fileLink);
                fileLink.click();
                setisLoader(false)
                
            }).catch(error => {
                if (error.request.statusText) {
                    toast.error(error.request.statusText);
                } else if (error.message) {
                    toast.error(error.message);
                } else {
                    toast.error("Something went wrong!");
                }
            });
        }
        
        const closeModalRef = useRef();
        const getOrderStatus = (pid) => {
            for (let i = 0; i < ActiveOrders.length; i++) {
                const element = ActiveOrders[i];
                // if (element.id === pid) {
                    //     let html = `
                    
                    //                         `;
                    //     document.getElementById('mainContentTrack').innerHTML = html;
                    
                    // }
                    // console.log(element)
                    closeModalRef.current.click()
                }
            }
            
            
    const setHtml = (ID, status = 0) => {
        console.log(status)
        if (!status) {

            ActiveOrders.map((obj, index) => {
                if (obj.id === Number(ID)) {
                    setElement(obj)

                }
            })
        } else {
            PrevOrders?.map((obj, index) => {
                if (obj.id === Number(ID)) {
                    setElement(obj)

                }
            });
        }
    };
    const handlePrint = () => {
        if (closeModalRef.current) {
            closeModalRef.current.click();
            toast.success('Invoice Downloaded Successfully');
        }
    };
    console.log(element)
    return (
        <div className='order-list'>
            <div className='heading'>
                {t("Order History")}
            </div>
            {isLoader ?
                <div className='my-5'><Loader width='100%' height='350px' /></div>
                : <>
                    <table className='order-list-table'>
                        <thead>
                            <tr>
                                <th>{t("Order Id")}</th>
                                <th>{t("date")}</th>
                                <th>{t("total")}</th>
                                <th>{t("Status")}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {ActiveOrders && ActiveOrders.map((order, index) => (
                                <tr key={index} className={index === ActiveOrders.length - 1 ? 'last-column' : ''}>
                                    <th>{`#${order.order_id} `}</th>
                                    <th>
                                        {order.created_at.substring(0, 10)}
                                    </th>
                                    <th className='total'>
                                        {`₹ ${order.final_total} (${order.items.length} Products) `}
                                    </th>
                                    <th className='button-container'>
                                        <div>{order_status(order?.active_status)}</div>
                                        <button onClick={() => {
                                            navigate(`${order.order_id}`)
                                        }} className='Invoice'>{t('view_details')}</button>
                                    </th>
                                </tr>
                            ))}
                        </tbody>
                    </table>

                    <Pagination
                        activePage={currPage}
                        itemsCountPerPage={total_orders_per_page}
                        totalItemsCount={totalActiveOrders}
                        pageRangeDisplayed={5}
                        onChange={handlePageChange.bind(this)}
                    />
                </>
            }
        </div>
    );
};

export default Order;
