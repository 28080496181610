import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import coverImg from "../../utils/cover-img.jpg";
import "../cart/cart.css";
import "./favorite.css";
import EmptyCart from "../../utils/zero-state-screens/Empty_Cart.svg";
import { useNavigate, Link } from "react-router-dom";
import { BsPlus } from "react-icons/bs";
import { BiMinus } from "react-icons/bi";
import api from "../../api/api";
import { toast } from "react-toastify";
import Cookies from "universal-cookie";
import Loader from "../loader/Loader";
import QuickViewModal from "../product/QuickViewModal";
import { useTranslation } from "react-i18next";
import { GoHome } from "react-icons/go";
import { MdKeyboardArrowRight } from "react-icons/md";
import NewsSubscription from "../common/NewsSubscription";
import faIcon from "../../assets/facebook.png";
import { FaInstagram, FaPinterestP, FaTwitter } from "react-icons/fa";
import { setFavourite } from "../../model/reducer/favouriteReducer";
import { setProductSizes } from "../../model/reducer/productSizesReducer";
import { setCart } from "../../model/reducer/cartReducer";

const Wishlist = () => {
  const closeCanvas = useRef();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const cookies = new Cookies();

  const favorite = useSelector((state) => state.favourite);
  const city = useSelector((state) => state.city);
  const sizes = useSelector((state) => state.productSizes);
  const cart = useSelector((state) => state.cart);
  const setting = useSelector((state) => state.setting);

  const [productSizes, setproductSizes] = useState(null);
  const [isfavoriteEmpty, setisfavoriteEmpty] = useState(false);
  const [isLoader, setisLoader] = useState(false);
  const [selectedProduct, setselectedProduct] = useState({});

  useEffect(() => {
    if (sizes.sizes === null || sizes.status === "loading") {
      if (city.city !== null && favorite.favorite !== null) {
        api
          .getProductbyFilter(
            city.city.id,
            city.city.latitude,
            city.city.longitude
          )
          .then((response) => response.json())
          .then((result) => {
            if (result.status === 1) {
              setproductSizes(result.sizes);
              dispatch(setProductSizes({ data: result.sizes }));
              // dispatch({
              //   type: ActionTypes.SET_PRODUCT_SIZES,
              //   payload: result.sizes,
              // });
            }
          });
      }
    } else {
      setproductSizes(sizes.sizes);
    }

    if (favorite.favorite === null && favorite.status === "fulfill") {
      setisfavoriteEmpty(true);
    } else {
      setisfavoriteEmpty(false);
    }
  }, [favorite]);

  useEffect(() => {
    api
      .getFavorite(
        cookies.get("jwt_token"),
        city.city.latitude,
        city.city.longitude
      )
      .then((response) => response.json())
      .then((result) => {
        dispatch(setFavourite({ data: result }));
        // dispatch({ type: ActionTypes.SET_FAVORITE, payload: result });
      });
  }, [cart]);

  //Add to Cart
  const addtoCart = async (product_id, product_variant_id, qty) => {
    setisLoader(true);
    await api
      .addToCart(cookies.get("jwt_token"), product_id, product_variant_id, qty)
      .then((response) => response.json())
      .then(async (result) => {
        if (result.status === 1) {
          toast.success(result.message);
          await api
            .getCart(
              cookies.get("jwt_token"),
              city.city.latitude,
              city.city.longitude
            )
            .then((resp) => resp.json())
            .then((res) => {
              setisLoader(false);

              if (res.status === 1) dispatch(setCart({ data: res }));
              // dispatch({ type: ActionTypes.SET_CART, payload: res });
            });
        } else {
          setisLoader(false);
          toast.error(result.message);
        }
      });
  };

  //remove from Cart
  const removefromCart = async (product_id, product_variant_id) => {
    setisLoader(true);
    await api
      .removeFromCart(cookies.get("jwt_token"), product_id, product_variant_id)
      .then((response) => response.json())
      .then(async (result) => {
        if (result.status === 1) {
          toast.success(result.message);
          await api
            .getCart(
              cookies.get("jwt_token"),
              city.city.latitude,
              city.city.longitude
            )
            .then((resp) => resp.json())
            .then((res) => {
              setisLoader(false);
              if (res.status === 1) dispatch(setCart({ data: res }));
              // dispatch({ type: ActionTypes.SET_CART, payload: res });
              else dispatch(setCart({ data: null }));
              // dispatch({ type: ActionTypes.SET_CART, payload: null });
            })
            .catch((error) => console.log(error));
        } else {
          setisLoader(false);
          toast.error(result.message);
        }
      })
      .catch((error) => console.log(error));
  };

  //remove from favorite
  const removefromFavorite = async (product_id) => {
    setisLoader(true);
    await api
      .removeFromFavorite(cookies.get("jwt_token"), product_id)
      .then((response) => response.json())
      .then(async (result) => {
        if (result.status === 1) {
          toast.success(result.message);
          await api
            .getFavorite(
              cookies.get("jwt_token"),
              city.city.latitude,
              city.city.longitude
            )
            .then((resp) => resp.json())
            .then((res) => {
              setisLoader(false);
              dispatch(setFavourite({data:res}))
              // dispatch({ type: ActionTypes.SET_FAVORITE, payload: res });
            });
        } else {
          setisLoader(false);
          toast.error(result.message);
        }
      });
  };
  const { t } = useTranslation();
  const placeHolderImage = (e) => {
    e.target.src = setting.setting?.web_logo;
  };
  return (
    <section id="wishlist" className="wishlist bg-white">
      <div className="cover ">
        <img
          src={coverImg}
          onError={placeHolderImage}
          className="img-fluid"
          alt="cover"
        ></img>
        <div className="shopping-cart flex items-center">
          <span>
            <Link to="/" className="text-light text-decoration-none">
              <GoHome className="home-icon" size={25} />
            </Link>
          </span>
          <span>
            <MdKeyboardArrowRight className="home-icon" size={20} />
          </span>
          <span className="shopping-cart-active">{t("Wishlist")}</span>
        </div>
      </div>
      <div className="-mt-32 w-full flex justify-center">
        <h1 className="text-[4rem] font-bold">My Wishlist</h1>
      </div>

      <div className="view-cart-container container">
        {favorite.favorite && favorite.favorite.status === 0 ? (
          <div className="empty-cart">
            <img src={EmptyCart} className="no-data-img" alt="empty-cart"></img>
            <p>{t("enter_wishlist_message")}</p>
            <span>{t("enter_wishlist_description")}</span>
            <button
              type="button"
              className="close-canvas"
              data-bs-dismiss="offcanvas"
              aria-label="Close"
              onClick={() => {
                navigate("/products");
              }}
            >
              {t("empty_cart_list_button_name")}
            </button>
          </div>
        ) : (
          <>
            {favorite.favorite === null || productSizes === null ? (
              <Loader screen="full" />
            ) : (
              <>
                {isLoader ? <Loader screen="full" background="none" /> : null}
                <div className=" border border-gray-400 overflow-x-hidden pt-3 px-4 rounded-lg">
                  <div className=""></div>

                  <table className="w-full">
                    <thead>
                      <tr className="border-b border-gray-300">
                        <th className="text-gray-400 text-[2rem] pb-3 pl-5">
                          PRODUCT
                        </th>
                        <th className="text-gray-400 text-[2rem] pb-3 pl-10">
                          PRICE
                        </th>
                        <th className="text-gray-400 text-[2rem] pb-3 pl-10">
                          STOCK STATUS
                        </th>

                        <th className="text-gray-400 text-[2rem] pb-3"></th>
                        <th className="text-gray-400 text-[2rem] pb-3"></th>
                      </tr>
                    </thead>

                    <tbody>
                      {favorite.favorite.status !== 0 && favorite.favorite.data &&
                        favorite.favorite.data.map((product, index) => (
                          <tr key={index} className="border-b border-gray-300">
                            <td className="flex items-center  space-x-10 py-10">
                              <div className="">
                                <img
                                  className="h-40 w-40 p-2 "
                                  onError={placeHolderImage}
                                  src={product.image_url}
                                  alt="product"
                                ></img>
                              </div>

                              <div className="">
                                <span className="text-gray-600 text-[2rem] font-bold">
                                  {product.name}
                                </span>
                              </div>
                            </td>

                            <td className="text-gray-600 text-[2rem] font-bold pl-10">
                              {setting.setting &&
                                setting.setting.currency + " "}
                              .
                              <span id={`price-wishlist${index}`}>
                                {product.variants.length > 0
                                  ? product.variants[0]?.price.toFixed(
                                      setting.setting &&
                                        setting.setting.decimal_point
                                    )
                                  : 0}
                              </span>
                            </td>

                            <td className="quantity pl-10">
                              {product?.variants[0].is_unlimited_stock ||
                              Number(product?.product_stock) ? (
                                <>
                                  <div className="text-[2rem] px-3 py-1.5 bg-green-200 rounded-md text-green-800 w-fit ">
                                    In stock
                                  </div>
                                </>
                              ) : (
                                <>
                                  <div className="text-[2rem] px-3 py-1.5 bg-orange-200 rounded-md text-red-500 w-fit ">
                                    Out of Stock
                                  </div>
                                </>
                              )}
                            </td>

                            <td className="remove last-column">
                              {product.variants[0].cart_count > 0 ? (
                                <>
                                  <div className="border border-gray-500 p-2 flex w-[16rem] items-center justify-between rounded-full gap-x-3">
                                    <button
                                      className="rounded-full bg-gray-200 p-4"
                                      type="button"
                                      onClick={() => {
                                        if (
                                          Number(
                                            product.variants[0].cart_count
                                          ) > 1
                                        ) {
                                          addtoCart(
                                            product.id,
                                            product.variants[0].id,
                                            Number(
                                              product.variants[0].cart_count
                                            ) - 1
                                          );
                                        } else {
                                          removefromCart(
                                            product.id,
                                            product.variants[0].id
                                          );
                                        }
                                      }}
                                    >
                                      <BiMinus
                                        className="fill-gray-700"
                                        fontSize={"2rem"}
                                      />
                                    </button>
                                    <span className="text-gray-600 text-[1.8rem]">
                                      {product.variants[0].cart_count}
                                    </span>
                                    <button
                                      className="rounded-full bg-gray-200 p-4"
                                      type="button"
                                      onClick={() => {
                                        if (
                                          Number(
                                            product.variants[0]
                                              .is_unlimited_stock
                                          ) === 1
                                        ) {
                                          if (
                                            Number(
                                              product.variants[0].cart_count
                                            ) <
                                            Number(
                                              setting.setting
                                                .max_cart_items_count
                                            )
                                          ) {
                                            addtoCart(
                                              product.id,
                                              product.variants[0].id,
                                              Number(
                                                product.variants[0].cart_count
                                              ) + 1
                                            );
                                          } else {
                                            toast.error(
                                              "Apologies, maximum product quantity limit reached!"
                                            );
                                          }
                                        } else {
                                          if (
                                            Number(
                                              product.variants[0].cart_count
                                            ) >=
                                            Number(product.variants[0].stock)
                                          ) {
                                            toast.error(
                                              t("out_of_stock_message")
                                            );
                                          } else if (
                                            Number(
                                              product.variants[0].cart_count
                                            ) >=
                                            Number(
                                              setting.setting
                                                .max_cart_items_count
                                            )
                                          ) {
                                            toast.error(
                                              "Apologies, maximum product quantity limit reached!"
                                            );
                                          } else {
                                            addtoCart(
                                              product.id,
                                              product.variants[0].id,
                                              Number(
                                                product.variants[0].cart_count
                                              ) + 1
                                            );
                                          }
                                        }
                                      }}
                                    >
                                      <BsPlus
                                        className="fill-gray-700"
                                        fontSize={"2rem"}
                                      />
                                    </button>
                                  </div>
                                </>
                              ) : (
                                <button
                                  onClick={() =>
                                    addtoCart(
                                      product.id,
                                      product.variants[0].id,
                                      1
                                    )
                                  }
                                  className="bg-[#0199e3] font-semibold text-[2rem] text-white px-16 py-4 rounded-full"
                                >
                                  Add to Cart
                                </button>
                              )}
                            </td>

                            <td className="remove last-column">
                              <button
                                onClick={() => removefromFavorite(product.id)}
                                className=" text-gray-600 border text-[2rem] border-gray-500 rounded-full w-16 h-16 text-center"
                                whileTap={{ scale: 0.8 }}
                                type="button"
                              >
                                x
                              </button>
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                  <div className="py-10 w-full flex items-center !border-t gap-x-5 !border-gray-300">
                    <p className="text-[2rem] text-gray-800">Share:</p>
                    <img src={faIcon} width={60} alt="" />
                    <FaTwitter size={30} />
                    <FaPinterestP size={30} />
                    <FaInstagram size={30} />
                  </div>
                </div>
              </>
            )}
          </>
        )}
      </div>
      <QuickViewModal
        selectedProduct={selectedProduct}
        setselectedProduct={setselectedProduct}
      />
      <NewsSubscription />
    </section>
  );
};

export default Wishlist;
