import { useState } from "react";
import Cookies from "universal-cookie";
import { useNavigate } from "react-router-dom";
import { IoBagOutline, IoEyeOutline } from "react-icons/io5";
import { BsHeart, BsHeartFill } from "react-icons/bs";
import starIcon from '../product/star.png'
import classNames from 'classnames'
import ProductModal from "./ProductModal";
import { toast } from "react-toastify";
import { t } from "i18next";

export const Product = (props) => {
    const {
      product,
      addtoCart,
      removefromCart,
      removefromFavorite,
      addToFavorite,
      favorite,
      productbrand,
      setting,
      setmainimage,
    } = props;
    const [hover, setHover] = useState(false);
    const [modal, setModal] = useState(false);
    const cookies = new Cookies();
    const navigate = useNavigate();
    console.log(product,'product')
    console.log(favorite)
    return (
      <>
        <div
          onMouseOver={() => setHover(true)}
          onMouseLeave={() => setHover(false)}
          className="w-[26rem]  relative h-[32rem] border flex bg-white flex-col items-center border-gray-300  hover:border-2 hover:border-blue-600 "
        >
          <img
            onClick={() => {
              navigate(`/product/${product.slug}`);
            }}
            className="h-[18rem] object-cover  mt-[5rem] w-[18rem]"
            src={product.image_url}
            alt=""
          />
  
          <div className="flex items-center w-full px-3 mt-[1.9rem] justify-between">
            <div>
              <h1
                onClick={() => {
                  navigate(`/product/${product.slug}`);
                  window.scrollTo({ top: 0, behavior: "smooth" });
                }}
                className="text-[1.3rem] hover:underline cursor-pointer font-medium"
              >
                {product.name.length > 20
                  ? product.name.substring(0, 10)
                  : product.name}
              </h1>
              <h1 className="text-[1.5rem] font-bold">
                Rs. {product.variants[0].price}
              </h1>
              <div className="flex items-center gap-x-1">
                {Array.from({ length: 5 }).map((item) => (
                  <img src={starIcon} className="w-3" />
                ))}
              </div>
            </div>
            <button
              onClick={() =>
               Number(product.variants[0].cart_count) > 0
                  ? removefromCart(product.id, product.variants[0].id)
                  : addtoCart(product.id, product.variants[0].id, 1)
              }
              className={classNames(
                "w-14 h-14 flex items-center justify-center bg-gray-300 rounded-full",
                product.variants[0].cart_count > 0 && "!bg-[#0199e3]"
              )}
            >
              <IoBagOutline size={15} />
            </button>
          </div>
          <div className="absolute top-4 flex flex-col items-center gap-y-5 right-4">
            {(hover || favorite.favorite &&
              favorite.favorite.data.some(
                (element) => element.id === product.id
              )) && (
              <button
                type="button"
                className={`wishlist-product h-14 w-14 rounded-full flex items-center justify-center shadow-2xl ${
                  hover && "hover-class"
                }`}
                onClick={() => {
                  if (cookies.get("jwt_token") !== undefined) {
                    if (
                      favorite.favorite.data.some(
                        (element) => element.id === product.id
                      )
                    ) {
                      removefromFavorite(product.id);
                    } else {
                      addToFavorite(product.id);
                    }
                  } else {
                    toast.error(t("required_login_message_for_cart"));
                  }
                }}
              >
                {favorite.favorite.data.some(
                  (element) => element.id === product.id
                ) ? (
                  <BsHeartFill size={16} fill="black" />
                ) : (
                  <BsHeart size={16} fill="black" />
                )}
              </button>
            )}
            {hover && (
              <div
                onClick={() => setModal(true)}
                className="w-14 h-14 rounded-full shadow-2xl flex items-center justify-center cursor-pointer"
              >
                <IoEyeOutline size={16} />
              </div>
            )}
          </div>
        </div>
        <ProductModal
          setmainimage={setmainimage}
          setting={setting}
          productbrand={productbrand}
          addtoCart={addtoCart}
          favorite={favorite}
          removefromFavorite={removefromFavorite}
          removefromCart={removefromCart}
          addToFavorite={addToFavorite}
          product={product}
          setModal={setModal}
          modal={modal}
        />
      </>
    );
  };
  