import React, { useState } from "react";
import classNames from "classnames";
import { MdOutlineDiscount } from "react-icons/md";

const ProductsInformation = (props) => {
  const { productdata ,selectedVariant} = props;
  const discountPrice = productdata && selectedVariant ? (
    ((selectedVariant.price - selectedVariant.discounted_price) /
      selectedVariant.price) *
    100
  )
    .toString()
    .split(".")[0] : 0
  const list = ["Descriptions", "Additional Information", "Customer feedback"];
  const [selected, setSelected] = useState(list[0]);
  return (
    <div className="mt-40 min-h-72">
      <div
        style={{ borderBottom: "1px solid #dee2e6" }}
        className="flex items-center justify-center w-screen pb-5 !border-b border-gray-500 gap-x-10"
      >
        {list.map((item, i) => (
          <h1
            onClick={() => setSelected(list[i])}
            className={classNames(
              "text-[2rem] cursor-pointer",
              list[i] === selected && "font-bold"
            )}
          >
            {item}
          </h1>
        ))}
      </div>
      <div>
        {selected == list[0] && (
        <div className="flex flex-wrap items-start justify-center mt-20 gap-x-40">
              <div
            style={{ color: "#dee2e6" }}
            className="text-[2rem] !text-gray-500"
            dangerouslySetInnerHTML={{ __html: productdata.description }}
          ></div>
          <div className="w-[60rem] h-[10rem] border flex items-center justify-center gap-x-5 border-gray-500 rounded-md">
            <div className="flex items-center gap-x-3">
            <MdOutlineDiscount fill="#0199e3" size={30} />
            <div>
                <h2 className="font-bold text-[1.5rem]">{
               discountPrice
              }
              % Discount</h2>
              <p className="text-[1.5rem] text-gray-500 font-semibold">Save your {discountPrice}% money with us</p>
            </div>
            </div>
            <div className="flex items-center gap-x-3">
            <MdOutlineDiscount fill="#0199e3" size={30} />
            <div>
                <h2 className="font-bold text-[1.5rem]">100
              % Organic</h2>
              <p className="text-[1.5rem] text-gray-500 font-semibold">100% Organic</p>
            </div>
            </div>
          </div>
        </div>
        )}
        {selected == list[1] && <div className="text-[2rem] flex items-center justify-center flex-col mt-4">
                <p>Made in : {productdata.made_in || "N/A"} </p>
                <p>Manufacturer : {productdata.manufacturer  || "N/A"}</p>
                <p>Product Height : {selectedVariant.variant_height  || "N/A"}</p>
                <p>Product Length : {selectedVariant.variant_length  || "N/A"}</p>
                <p>Product Width : {selectedVariant.variant_width  || "N/A"}</p>
                <p>Seller Name : {productdata.seller_name  || "N/A"}</p>




            </div>}
      </div>
    </div>
  );
};

export default ProductsInformation;
