import React, { useState } from "react";
import personImg from "./service-assets/Photo by M. Brauer.png";
import { useDispatch, useSelector } from "react-redux";
import api from "../../api/api";
import useToken from "../../hooks/useToken";
import { setAppointment } from "../../model/reducer/appointmentReducer";
import { toast } from "react-toastify";
import Tooltip from '@mui/material/Tooltip';
import { Typography } from "@mui/material";
import { setPromoCode } from "../../model/reducer/newAppointmentReducer";

const Summary = () => {
  const {name,scheduled_for,total,final_total,promo_discount} = useSelector(state=>state.appointment.appointment)
  return (
    <div className="p-20">
      <SummaryHeader name={name} dateTime={scheduled_for} />
      <div className="flex items-center justify-center p-10 py-16 gap-x-20">
        <CouponComp />
        <PaymentComp total={total} final_total={final_total} promo_discount={promo_discount} />
      </div>
    </div>
  );
};

export default Summary;

const PaymentComp = ({total,final_total,promo_discount}) => {
  const {notes, service_id,schedule:scheduled_for,Name:name,gender,age,promocode:promoCode} = useSelector((state) => state.newAppointment);
  const {user} = useSelector(state=>state.user)
  const token = useToken()
  const createNewAppointment = async () => {
    const payload = {
      token,
      service_id,
      transaction_id: 12121,
      customer_id:user.id,
      scheduled_for:scheduled_for.replace(/\//g, "-"),
      name,
      gender,
      age,
      problem:"nothing",
      additional_info:JSON.stringify({"ad":"ds"}),
      reason:notes,
      promo_code:promoCode.length > 0 ? promoCode : null
    };

    let response = await api.createAppointment(payload);
    let {status,message} = await response.json()

    if(status === 1){
      toast.success(message)
    }

    if(status === 0){
      toast.error(message)
    }



  };
  return (
    <div
      style={{ borderLeft: "1px solid #DFDFDF" }}
      className="w-[25vw] relative pl-10 h-[40rem]"
    >
      <div className="w-full">
        <h2 className="font-medium">Billing details</h2>
        <div className="flex w-full mt-10 justify-between items-center">
          <h2 className="font-bold">Consultation Charges</h2>
          <h2 className="font-bold">Rs. {total}</h2>
        </div>
      </div>
      <div
        className="absolute bottom-0 w-full"
      >
        <div 
        style={{ borderBottom: "1px solid #DFDFDF" }}
        className="flex w-full justify-between  pb-2  items-center">
          <h2 className="font-bold">Subtotal</h2>
          <h2 className="font-bold">Rs. {total}</h2>
        </div>
        <div className="flex w-full justify-between items-center mt-10">
            <div className="flex items-center gap-x-5">
            <h2 className="font-bold italic">Total amount payable</h2>
           <Tooltip title={<Typography fontSize={20}>{`Promo Discount : ${promo_discount} Rs`}</Typography>}>
           <div className="w-10 h-10 rounded-full cursor-pointer flex items-center justify-center bg-gray-300 ">
            <h2 className="font-bold mt-2.5">i</h2>

            </div>
           </Tooltip>
            </div>
            <h2 className="text-[#096E44] italic font-bold">Rs. {final_total}</h2>
        </div>
        <button onClick={()=>createNewAppointment()} className="w-full bg-[#0199E3] text-white font-bold text-[2rem] py-4 rounded-xl mt-20 ">Make Payment</button>
      </div>

    </div>
  );
};

const CouponComp = () => {

  const {notes, service_id,schedule:scheduled_for,Name:name,gender,age,promocode:promoCode} = useSelector((state) => state.newAppointment);
  const {user} = useSelector(state=>state.user)
  const token = useToken()
  const dispatch = useDispatch()
  const bookAppointment = async () => {
    const payload = {
      token,
      service_id,
      transaction_id: 12121,
      customer_id:user.id,
      scheduled_for:scheduled_for.replace(/\//g, "-"),
      name,
      gender,
      age,
      problem:"nothing",
      additional_info:JSON.stringify({"ad":"ds"}),
      reason:notes,
      promo_code:promoCode.length > 0 ? promoCode : null
    };

    let response = await api.appointmentSummary(payload);
    let {status,data,message} = await response.json()

    if(message && status === 0){
      toast.error(message)
    }

    if(status === 1 &&  promoCode !== ""){
      dispatch(setAppointment({data}))
      toast.success("Promo code applied successfully")
    }
    if(promoCode === ""){
      dispatch(setAppointment({data}))
      toast.success('Promo code removed')
    }
  };

  return (
    <div className="flex flex-col gap-y-14 items-start justify-evenly mt-20">
      <div className="flex w-full">
        <input
        value={promoCode}
        onChange={(e)=>dispatch(setPromoCode(e.target.value))}
          type="text"
          placeholder="Enter CODE"
          className="text-[2rem] w-full h-20 border px-20 placeholder:-translate-x-[25rem] rounded-l-xl border-gray-500"
        />
        <button onClick={()=>bookAppointment()} className="text-[2rem] font-bold text-white bg-[#0199E3] w-48 rounded-r-xl h-20 ">
          Apply
        </button>
      </div>
      <div className="flex items-center px-5 gap-x-10 w-full border rounded-xl border-gray-500 py-4 ">
        <input type="radio" className="text-[2rem] !w-10 !h-10" />
        <img src={personImg} className="w-28 h-28" alt="" />
        <div className="mt-4">
          <div className="flex items-center">
            <h1 className="font-extrabold text-[2rem]">CODE:</h1>
            <h1 className="font-extrabold text-[2rem] text-[#0199E3] ">
              MYHEALTH
            </h1>
          </div>
          <p className="text-gray-500 text-[1.8rem]">
            Get Flat 50% upto Rs. 150 off on using MYHEALTH coupon
          </p>
        </div>
        <p className="bg-[#0199E3] text-white text-[1.4rem] min-w-max ml-14 px-2 -mt-12">
          Flat 50% OFF
        </p>
      </div>
      <p className="text-[1.6rem]">Terms and Conditions apply</p>
    </div>
  );
};

const SummaryHeader = ({name,dateTime}) => {

  function formatDateTime(inputDateTime) {
    const options = {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      hour12: true,
    };
  
    const startDate = new Date(inputDateTime);
    const endDate = new Date(startDate.getTime() + 30 * 60000); // Add 30 minutes (30 * 60,000 milliseconds)
  
    const formattedStartDate = startDate.toLocaleString('en-US', options);
    const formattedEndDate = endDate.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric' });
  
    return `${formattedStartDate} - ${formattedEndDate}`;
  }

  return (
    <div
      style={{ borderBottom: "1px solid #DFDFDF" }}
      className="flex justify-between items-center pb-20 "
    >
      <div className="flex items-center">
        <div style={{ borderRight: "1px solid #DFDFDF" }} className="pr-10">
          <p className="text-[2rem] font-semibold">Patient</p>
          <p className="text-[2rem] font-semibold"> Details</p>
        </div>
        <div className="pl-10">
          <p className="text-[2rem] font-extrabold">{name}</p>
          <p className="text-[2rem] text-gray-500">zolatteappa@gmail.com</p>
        </div>
      </div>
      <div className="flex items-center">
        <div style={{ borderRight: "1px solid #DFDFDF" }} className="pr-10">
          <p className="text-[2rem] font-semibold">Consultant</p>
          <p className="text-[2rem] font-semibold"> Details</p>
        </div>

        <div className="pl-10 flex items-center gap-x-10">
          <img src={personImg} className="w-36 h-36" alt="" />
          <div>
            <p className="text-[2rem] font-extrabold">Dr. Ajit Gupta</p>
            <p className="text-[2rem] text-gray-500">Sr Gynaecologist</p>
          </div>
        </div>
      </div>
      <div className="flex items-center">
        <div style={{ borderRight: "1px solid #DFDFDF" }} className="pr-10">
          <p className="text-[2rem] font-semibold">Service</p>
          <p className="text-[2rem] font-extrabold">Gynecologist</p>
        </div>
        <div className="pl-10">
          <p className="text-[2rem] ">Date and Time</p>
          <p className="text-[2rem] font-extrabold">
           {formatDateTime(dateTime)}
          </p>
        </div>
      </div>
    </div>
  );
};
