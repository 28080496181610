import React, { useEffect, useRef, useState } from "react";
import expertImage from "./service-assets/Photo by mahdi chaghari.png";
import { CiSearch } from "react-icons/ci";
import { IoFilterSharp } from "react-icons/io5";
import ExpertDetails from "./ExpertDetails";
import api from "../../api/api";
import { useSelector } from "react-redux";
import Cookies from "universal-cookie";
import { CircularProgress } from "@mui/material";
import filterIcon from "./service-assets/Primary Icons.svg";
import arrowIcon from "./service-assets/Arrows.svg";

const Person = ({ setCurrentStage }) => {
  const [expertModal, setExpertModal] = useState(false);
  const [experts, setExperts] = useState([]);
  const [expertId, setExpertId] = useState(null);

  const { service_id } = useSelector((state) => state.newAppointment);
  const cookies = new Cookies();
  const token = cookies.get("jwt_token");
  const getExperts = async () => {
    let response = await api.getServiceProvidersbyFilter(
      service_id,
      1000,
      token
    );
    let result = await response.json();

    if (result.status === 1) {
      setExperts(result.data);
    }
  };

  const selectExpert = (id) => {
    setExpertId(id);
    setExpertModal(true);
  };

  useEffect(() => {
    if (token) {
      getExperts();
    }
  }, []);

  return (
    <div className="w-full h-full pb-20">
      <div className="px-10 mt-10 flex justify-between gap-x-10">
        <Search />
        <Filter />
      </div>
      <div className="flex items-center justify-evenly mt-40 ju">
        {experts.length === 0 ? (
          <CircularProgress />
        ) : (
          experts.map((expert, i) => (
            <div className="flex flex-col w-[292px] h-[320px] items-center px-20 p-4 justify-center rounded-[15px] border border-gray-600 bg-gray-100/25 ">
              <img
                src={expert.image_url}
                alt=""
                className="w-40 h-40 rounded-full object-cover"
              />
              <h1 className="font-bold mt-3 capitalize">{expert.sp_name}</h1>
              <p className="text-[2rem] text-gray-400 font-semibold">
                {expert.name}
              </p>
              <p className="text-[2rem] text-gray-400 font-semibold">
                Experience : {expert.years_of_experience} years
              </p>
              <p className="text-[2rem] text-gray-400 font-semibold">
                {expert.cost} consultation fee
              </p>

              <p
                onClick={() => selectExpert(expert.service_provider_id)}
                className="text-[2rem] text-[#0199E3] flex
                 items-center gap-x-5 font-semibold cursor-pointer"
              >
                More Info <img src={arrowIcon} />
              </p>
            </div>
          ))
        )}
      </div>

      <ExpertDetails
        expertId={expertId}
        setCurrentStage={setCurrentStage}
        modal={expertModal}
        setModal={setExpertModal}
      />
    </div>
  );
};

export default Person;

const experts = [
  {
    image: expertImage,
    occupation: "Nutritionist/Dietician",
    name: "Jasmin",
    experience: 3,
    fees: 1500,
  },
  {
    image: expertImage,
    occupation: "Nutritionist/Dietician",
    name: "James",
    experience: 3,
    fees: 1500,
  },
  {
    image: expertImage,
    occupation: "Nutritionist/Dietician",
    name: "Jasmin",
    experience: 3,
    fees: 1500,
  },
  {
    image: expertImage,
    occupation: "Nutritionist/Dietician",
    name: "Jasmin",
    experience: 3,
    fees: 1500,
  },
];

const Search = () => {
  const ref = useRef(null);
  const handleFocus = () => [ref.current.focus()];
  return (
    <div
      onClick={handleFocus}
      className="flex items-center  border border-gray-500 rounded-2xl gap-x-5"
    >
      <CiSearch size={20} className="ml-5" />
      <input
        ref={ref}
        type="text"
        placeholder="Search"
        className="text-[2rem]  mt-2 placeholder:-translate-x-[8rem]"
      />
      <button className="bg-[#0199E3] h-full text-[2rem] rounded-r-2xl px-5 font-semibold text-white">
        Search
      </button>
    </div>
  );
};

const Filter = () => {
  return (
    <div className="flex cursor-pointer items-center gap-x-4 border border-gray-500 p-3 rounded-2xl px-4">
      <img src={filterIcon} />
      <h1 className="mt-2 text-[2rem]">Filters</h1>
    </div>
  );
};
