import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { FaXmark } from "react-icons/fa6"
import ProductShowcase from "../product/ProductShowcase";

const ProductModal = (props) => {
  const {
    modal,
    setModal,
    product:productdata,
    addToFavorite,
    removefromCart,
    removefromFavorite,
    favorite,
    addtoCart,
    productbrand,
  } = props;
  const placeHolderImage = (e) => {
    e.target.src = setting ? setting.setting?.web_logo : "";
  };

  const setting = useSelector((state) => state.setting);
  function image_to_url(image) {
    return process.env.REACT_APP_API_URL + "/storage/" + image;
  }
  const [selectedVariant,setSelectedVariant] = useState(productdata.variants[0])
  const [selectedvariantImage,setselectedvariantImage] = useState([productdata.image_url])
  

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (modal && event.target.closest(".modal-content-box") === null) {
        setModal(false); 
      }
    };

    if (modal) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [modal, setModal]);

  return (
    modal && <div className="!w-screen fixed !z-[1000] !h-screen bg-black/80 flex items-center justify-center top-0 bottom-0 left-0 right-0">
        <div
    className=" !bg-white p-20  modal-content-box relative rounded-xl "

  >
    {productdata && setting && (
      <ProductShowcase
        removefromFavorite={removefromFavorite}
        addToFavorite={addToFavorite}
        favorite={favorite}
        removeFromCart={removefromCart}
        addtoCart={addtoCart}
        productbrand={productbrand}
        setting={setting}
        productdata={productdata}
        setSelectedVariant={setSelectedVariant}
        selectedVariant={selectedVariant}
        setmainimage={productdata.image_url}
        placeHolderImage={placeHolderImage}
        setselectedvariantImage={setselectedvariantImage}
        carouselImages={[...selectedvariantImage]}
        image_to_url={image_to_url}
      />
    )}
    <div onClick={()=>setModal(false)} className="text-white absolute -top-24 right-5 text-[4rem]">
    <FaXmark fill="white" />
    </div>
    {console.log(productdata,'data')}
  </div>
    </div>
  );
};

export default ProductModal;
