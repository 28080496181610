import React from 'react'
import { Link, useNavigate } from 'react-router-dom'
import coverImg from "../../utils/cover-img.jpg";
import './notfound.css'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { LuHome } from "react-icons/lu";
import { MdChevronRight } from "react-icons/md";
import NewsSubscription from '../common/NewsSubscription';
import illustrationSvg from './Illustration.svg'



const NotFound = (props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const setting = useSelector(state => state.setting);
  const placeHolderImage = (e) => {
    e.target.src = setting.setting?.web_logo;
  };
  return (
    <section >
      <div className="relative pb-80">
        <img
          onError={placeHolderImage}
          src={coverImg}
          className="h-40 absolute z-0 object-cover w-full"
          alt="cover"
        ></img>
        <div className="absolute z-[1] flex items-center ml-[12vw] mt-12 gap-x-3">
          <Link to={"/"}>
            <LuHome className="stroke-gray-400 cursor-pointer" size={25} />
          </Link>
          <MdChevronRight className="fill-gray-400" size={20} />
          <span className="text-[#0199E4] text-[2rem]">404 Error Page</span>
        </div>
      </div>
      <div className='w-full h-[60vh] flex flex-col items-center justify-center'>
        <img src={illustrationSvg} className='w-[30vw]' />
        <h1 className='text-[4rem] font-bold'>Oops! page not found</h1>
        <p className='text-gray-500 text-[2rem] w-[100rem] text-center'>Lorem ipsum dolor sit amet consectetur addipisci sequi quae assumenda temporibus doloremque, eos quidem aut. Fuga tempore ad quas consequatur provident!</p>
        <Link to={'/'}>
        <button className="bg-[#0199E3] mb-40 py-4 px-10 text-white text-[2rem] font-bold rounded-full mt-5">Back to Home</button>
        </Link>

      </div>
      <NewsSubscription/>
    </section>
  );
};

export default NotFound;
