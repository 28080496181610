import React, { useState } from "react";
import ModalLabels from "./ModalLabels";
import headPhoneIcon from "../HomePage/headphones 1.svg";
import bagIcon from "../HomePage/shopping-bag.svg";
import classNames from "classnames";
import Box from "./Box";

const ServiceFirstModal = ({selected,setSelected,label,setCurrentStage}) => {

 

  return (
   
      <div>
      <div className="w-full h-[50vh] flex items-center justify-center gap-x-20">
        <div onClick={()=>setCurrentStage(2)}>
          <Box
            setSelected={setSelected}
            selected={selected}
            type="expert"
            icon={headPhoneIcon}
            label="Expert Consultation"
            desc="Talk to An Expert"
          />
        </div>
        <div onClick={()=>setCurrentStage(6)}>
          <Box
            selected={selected}
            setSelected={setSelected}
            type="free"
            icon={bagIcon}
            label="Free Consultation"
            desc="Get a Free Consultation with a professional"
          />
        </div>
      </div>
      </div>
  );
};

export default ServiceFirstModal


