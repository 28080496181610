// import { ActionTypes } from "../action-type";
import { createSlice } from "@reduxjs/toolkit";


const initialState = {
    search: null,
    grid_view: true,
    price_filter: null,
    sort_filter: 'new',  //new,old,high,low,discount,popular
    country_id: "",
    language:"English"
};

export const serviceProviderFilterReducer = createSlice({
    name: "serviceProviderFilter",
    initialState,
    reducers: {
        setProviderSearch: (state, action) => {
            state.search = action.payload.data;
        },
        setProviderView: (state, action) => {
            state.grid_view = action.payload.data;
        },
        setProviderSort: (state, action) => {
            state.sort_filter = action.payload.data;
        },
        setProviderMinMaxPrice: (state, action) => {
            state.price_filter = action.payload.data;
        },
        setProviderLanguage:(state, action) => {
            state.price_filter = action.payload.data;
        },
        setProviderByCountry: (state, action) => {
            state.country_id = action.payload.data;
            state.language="English"; 
        }

    }
});

export const {
    setProviderSearch,
    setProviderSort,
    setProviderView,
    setProviderLanguage,
    setProviderByCountry,
} = serviceProviderFilterReducer.actions;

export default serviceProviderFilterReducer.reducer;