import React, { useRef, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import HomeContainer from "./homecontainer/HomeContainer";
import Loader from "./loader/Loader";
import ProductContainer from "./product/ProductContainer";
import { AiOutlineClose } from "react-icons/ai";
import { Modal } from "react-bootstrap";
import ProductSlider from "./HomePage/ProductSlider";
import firstImage from "../assets/z7t9wt5u 1.png";
import secondImage from "../assets/image 17.png";
import thirdImage from "../assets/image 18.png";
import ShopDeals from "./HomePage/ShopDeals";
import FeaturedProducts from "./product/FeaturedProducts";
import Testimony from "./HomePage/Testimony";
import NewsSubscription from "./common/NewsSubscription";
import LatestNews from "./HomePage/LatestNews";

const MainContainer = () => {

  const modalRef = useRef();

  const setting = useSelector((state) => state.setting);

  useEffect(() => {
    if (modalRef.current && setting.setting !== null) {
      modalRef.current.click();
    }
  }, [setting]);

  const [showPop, setShowPop] = useState(true);

  const placeHolderImage = (e) => {
    e.target.src = setting.setting?.web_logo;
  };
  return (
    <>
      {setting.setting === null ? (
        <Loader screen="full" />
      ) : (
        <>
          <div
            className="home-page content"
            style={{ paddingBottom: "5px", minHeight: "75vh" }}
          >
            <HomeContainer />
            <ProductSlider />

            <ProductContainer />
            <ProductBoxContainer />
            <ShopDeals />
            <FeaturedProducts />
            <Testimony />
            <LatestNews />
            <NewsSubscription />
          </div>

          {parseInt(setting.setting.popup_enabled) === 1 ? (
            <>
              <Modal
                className="popup"
                centered
                show={showPop}
                onBackdropClick={() => setShowPop(false)}
                backdrop={"static"}
              >
                <Modal.Header
                  onClick={() => {
                    setShowPop(false);
                  }}
                >
                  <AiOutlineClose size={32} fill="#fff" />
                </Modal.Header>
                <Modal.Body>
                  <img
                    src={setting.setting.popup_image}
                    alt="image"
                    onClick={() => {
                      if (setting.setting?.popup_type === "popup_url") {
                        window.location = setting.setting.popup_url;
                      } else if (setting.setting?.popup_type === "category") {
                      }
                    }}
                    style={{ width: "100%", height: "100%" }}
                    onError={placeHolderImage}
                  ></img>
                </Modal.Body>
              </Modal>
            </>
          ) : null}
        </>
      )}
    </>
  );
};

export default MainContainer;

const ProductBoxContainer = () => {
  return (
    <div className="flex items-center justify-center gap-x-14 py-28">
      <FirstBox />
      <SecondBox />
      <ThirdBox />
    </div>
  );
};

const FirstBox = () => {
  return (
    <div className="w-[48.5rem] h-[29.063rem] relative border border-gray-700 bg-[#02A4AB] rounded-lg">
      <div className="ml-10 mt-10 absolute z-[1]">
        <h1 className="text-white text-[3rem] font-bold">Kikibix</h1>
        <h1 className="text-white text-[3rem] font-bold">100% whole grain</h1>
        <h1 className="text-white text-[3rem] font-bold">cookie</h1>
        <p className="text-white text-[2rem] font-light">Starting at Rs. 299</p>
        <button className="text-[2rem] text-[#0199E3] py-4 ml-5 mt-3 w-fit h-fit px-20 bg-white rounded-full font-bold">
          Shop now
        </button>
      </div>
      <div className="absolute bottom-0 right-0 z-0">
        <img src={firstImage} className="w-[30rem]" />
      </div>
    </div>
  );
};

const SecondBox = () => {
  return (
    <div className="w-[48.5rem] h-[29.063rem] relative bg-[#F5C777] rounded-lg">
      <div className="ml-10 mt-10 absolute z-[1]">
        <h1 className="text-white text-[3rem] font-bold">Better Seed</h1>
        <h1 className="text-white text-[3rem] font-bold">Mix Spiced Jaggery</h1>
        <h1 className="text-white text-[3rem] font-bold">100gm</h1>
        <p className="text-white text-[2rem] font-light">Starting at Rs. 135</p>
      </div>
      <div className="absolute bottom-3 right-3 z-0">
        <img src={secondImage} className="w-[28rem]" />
      </div>
    </div>
  );
};

const ThirdBox = () => {
  return (
    <div className="w-[48.5rem] h-[29.063rem]  bg-[#F5C777] rounded-lg">
      <img src={thirdImage} className="object-cover" />
    </div>
  );
};
