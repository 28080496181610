import React, { useEffect, useState } from "react";
import ServiceFirstModal from "./ServiceFirstModal";
import { useDispatch, useSelector } from "react-redux";
import ModalLabels from "./ModalLabels";
import Speciality from "./Speciality";
import Person from "./Person";
import DateTIme from "./DateTIme";
import ModeOfComm from "./ModeOfComm";
import Details from "./Details";
import Summary from "./Summary";
import BasicDetailLabel from "./BasicDetailLabel";
import {
  setCurrentStageReducer,
  setServiceConsultType,
} from "../../model/reducer/newAppointmentReducer";

const Service = ({ service }) => {
  const { name: label, id } = service;
  const dispatch = useDispatch();
  const { currentStage, consult_type: selected } = useSelector(
    (state) => state.newAppointment
  );
  const setCurrentStage = (num) => {
    dispatch(setCurrentStageReducer({ data: num }));
  };
  const setSelected = (consultType) => {
    dispatch(setServiceConsultType({ data: consultType }));
  };


  useEffect(()=>{
      if(selected === 'free'){
        setCurrentStage(6)
      }
  },[selected])

  return (
    <div className="w-full bg-[#EAF8FF]">
      <h1 className="w-full text-center capitalize font-bold text-[3.5rem] py-20">
        {label}
      </h1>
      { (
        <ModalLabels
          currentStage={currentStage}
          setCurrentStage={setCurrentStage}
          label={label}
        >
          {currentStage === 1 && (
            <ServiceFirstModal
              setCurrentStage={setCurrentStage}
              selected={selected}
              setSelected={setSelected}
            />
          )}
          {currentStage === 2 && (
            <Speciality cid={id} setCurrentStage={setCurrentStage} />
          )}
          {currentStage === 3 && <Person setCurrentStage={setCurrentStage} />}
          {currentStage === 4 && <DateTIme />}
          {currentStage === 5 && (
            <ModeOfComm setCurrentStage={setCurrentStage} />
          )}
          {currentStage === 6 && <Details buttonLabel={"Book Appointment"} />}
          {currentStage === 7 && <Summary />}
        </ModalLabels>
      )}
     
    </div>
  );
};

export default Service;
