import React, { useState } from 'react'
import headphoneIcon from "../HomePage/headphones 1.svg";
import bagIcon from "../HomePage/shopping-bag.svg";
import Box from './Box';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { setLanguage, setServiceCommunication } from '../../model/reducer/newAppointmentReducer';

const ModeOfComm = ({setCurrentStage}) => {
    const [selected,setSelected] = useState("Chat")
    const [langIndex,setLangIndex] = useState(0)
    const dispatch = useDispatch()

    const communicationModeSelector = (mode) => {
      setSelected(mode.label)
      dispatch(setServiceCommunication(mode.type))
    }

  return (
    <div className='w-full h-[50vh] relative'>
        <div className="w-full mt-40 flex items-center gap-x-5 justify-center">
    {data.map((item, i) => (
      <div onClick={()=>communicationModeSelector(item)} key={i}>
        <Box selected={selected} setSelected={setSelected} type={item.label} desc={item.desc} label={item.label} icon={item.icon} />
      </div>
    ))}
    
  </div>
    <div className=' w-full absolute bottom-0 right-0 left-0'>
        <div className='bg-[#EAF8FF] w-full h-28 flex flex-col justify-center'>
            <h1 className='text-[#0199E3] ml-20 font-bold'>Preferred Language</h1>
            
        </div>
        <div className='my-10 flex items-center ml-20 gap-x-10'>
                {
                    langs.map((lang,i)=>(
                       <div onClick={()=>dispatch(setLanguage(lang))}>
                         <LangButton key={i} lang={lang} index={i} langIndex={langIndex} setLangIndex={setLangIndex} />
                       </div>
                    ))
                }
        </div>
    </div>
    </div>
  )
}

export default ModeOfComm

const langs = ["English","Hindi"]

const LangButton = ({ lang,index,langIndex,setLangIndex }) => {
    const [hover,setHover] = useState(false)
    
    const onClickHandler = () => {
        setLangIndex(index)
    }

    return (
        <button onMouseOver={()=>setHover(true)} onClick={onClickHandler} onMouseLeave={()=>setHover(false)} className={classNames('h-20 w-60 text-[2rem] font-bold border border-gray-500 rounded-full text-gray-500 ',hover && 'bg-[#0199E4] text-white',index === langIndex && 'bg-[#0199E4] text-white')} >
            {lang}
        </button>
    )
  };

const data = [
    {
      icon: headphoneIcon,
      label: "Chat",
      desc: "Talk to An Expert",
      type:'chat'
    },
    {
      icon: bagIcon,
      label: "Audio Call",
      desc: "Get a Free consultation with a professional",
      type:'call'

    },
    {
      icon: bagIcon,
      label: "Video Call",
      desc: "Get a Free consultation with a professional",
      type:'VC'

    },
    {
      icon: bagIcon,
      label: "Any",
      desc: "Get a Free consultation with a professional",
      type:'any'

    },
  ];
  