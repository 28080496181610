import React, { useEffect, useState } from 'react'
import firstImage from '../../assets/image 20.png'
import { GoArrowRight } from "react-icons/go";
import girlImage from '../../assets/image 21.png'


const ShopDeals = () => {
    function getTargetDate() {
        const today = new Date();
        const targetDate = new Date(today.getTime() + (2 * 24 * 60 * 60 * 1000));
      
        // Format the target date as YYYY-MM-DD
        const year = targetDate.getFullYear().toString().padStart(4, '0');
        const month = (targetDate.getMonth() + 1).toString().padStart(2, '0'); // Months are zero-indexed
        const day = targetDate.getDate().toString().padStart(2, '0');
      
        const formattedTargetDate = `${year}-${month}-${day}`;
      
        return formattedTargetDate;
      }
    const targetDate = new Date(getTargetDate());
  return (
    <div className='w-full py-20 bg-[#EAF8FF]  flex items-center gap-x-20 justify-center'>
        <img src={firstImage} alt="" className='w-[45rem]' />
      <div className='flex flex-col justify-center items-center gap-y-5'>
        <h4 className='text-[#0199E3] font-bold'>BEST DEALS</h4>
        <h1 className='text-[3.5rem] font-bold w-[35rem] text-center'>Our Special Products Deal of the Month</h1>
        <DaysLeftComponent targetDate={targetDate}/>
        <button className='bg-[#0199E3] flex items-center mt-5 text-[2rem] text-white font-bold gap-x-8 py-4 px-14 rounded-full'>Shop now <GoArrowRight className='fill-white' size={25}/> </button>
      </div>
      <img className=' w-[50rem] right-0 align-bottom' src={girlImage} alt="" />
    </div>
  )
}

export default ShopDeals


function DaysLeftComponent({ targetDate }) {
    const [days, setDays] = useState(0);
    const [hours, setHours] = useState(0);
    const [minutes, setMinutes] = useState(0);
    const [seconds, setSeconds] = useState(0);
  
    useEffect(() => {
      const intervalId = setInterval(() => {
        const now = new Date().getTime();
        const difference = targetDate - now;
  
        // Calculate days, hours, minutes, and seconds
        setDays(Math.floor(difference / (1000 * 60 * 60 * 24)));
        setHours(Math.floor((difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)));
        setMinutes(Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60)));
        setSeconds(Math.floor((difference % (1000 * 60)) / 1000));
  
        if (difference < 0) {
          // Stop the timer if the target date has been reached
          clearInterval(intervalId);
          setDays(0);
          setHours(0);
          setMinutes(0);
          setSeconds(0);
        }
      }, 1000);
  
      return () => clearInterval(intervalId);
    }, [targetDate]);
  
    return (
        <div className=" flex justify-between items-center gap-x-5 ">
        <div className="flex flex-col gap-y-0 justify-center rounded-xl bg-white w-[10rem]  h-[10rem] items-center">
          <span className="text-[4rem] m-0 p-0 mt-2  text-[#0199E3]">{days.toString().padStart(2, '0')}</span>
          <span className='text-gray-400 m-0 p-0 text-[2rem] '>DAYS</span>
        </div>
        <p className='text-[3.5rem] text-gray-500'>:</p>
        <div className="flex flex-col gap-y-0 justify-center bg-white rounded-xl w-[10rem]  h-[10rem] items-center">
          <span className="text-[4rem] m-0 p-0 mt-2  text-[#0199E3]">{hours.toString().padStart(2, '0')}</span>
          <span className='text-gray-400 m-0 p-0 text-[2rem] '>HOURS</span>
        </div>
        <p className='text-[3.5rem] text-gray-500'>:</p>
        <div className="flex flex-col gap-y-0 justify-center bg-white w-[10rem] rounded-xl h-[10rem] items-center">
          <span className="text-[4rem] m-0 p-0 mt-2  text-[#0199E3]">{minutes.toString().padStart(2, '0')}</span>
          <span className='text-gray-400 m-0 p-0 text-[2rem] '>MINS</span>
        </div>
        <p className='text-[3.5rem] text-gray-500'>:</p>
        <div className="flex flex-col gap-y-0 justify-center bg-white w-[10rem]  h-[10rem] rounded-xl items-center">
          <span className="text-[4rem] m-0 p-0 mt-2  text-[#0199E3]">{seconds.toString().padStart(2, '0')}</span>
          <span className='text-gray-400 m-0 p-0 text-[2rem] '>SECS</span>
        </div>
      </div>
    );
  }